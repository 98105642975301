import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import AddPageButton from "../../../constants/AddPageButton";
import TableHeading from "../../../constants/TableHeading";
import DataSpinner from "../../../constants/DataSpinner";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdModeEditOutline } from "react-icons/md";
import { reloadPage } from "../../../redux/actions/load.action";
import axios from "axios";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import AddCustomerModal from "./AddCustomerModal";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { customerDeletePost } from "../../../constants/api";
import ViewCustomer from "./ViewCustomer";
import { useNavigate } from "react-router-dom";
import GlobalPagination from "../../../constants/GlobalPagination";

const ListCustomer = () => {
  const navigate = useNavigate();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyid = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org;
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [itemId, setItemid] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesFromAPI, setTotalPagesFromAPI] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [pageSize, setPageSize] = useState(10);
  const TableHeadings = [
    "Customer ID",
    "Customer Name",
    "Customer Type",
    "City",
    "DOB",
    "Phone",
    "Email",
    "Action",
  ];

  const handleShow = (item) => {
    const { id, customerid } = item;
    setShow(true);
    setItemid(id);
    setSelectedItemNo(customerid);
  };
  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(

          "https://erp.venturesathi.co.in/api/v1/customer/getallcustomerpage",

          {
            page: currentPage,
            pageSize: pageSize,
            userid: userID,
            branchid: branchID,
            companyid,
            keyword: searchKeyword || "₹ΘθϖΠ",
          }
        );
        const initialData =
        res.data && res.data.data && res.data.data.length > 0
          ? res.data.data
          : [];

        setData(initialData);
        setTotalPagesFromAPI({
          totalPages: res.data.totalPages ? res.data.totalPages : 0,
          totalRecords: res.data.totalItems ? res.data.totalItems : 0,
        });
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loadData,pageSize,currentPage,searchKeyword]);

  // const filteredData = !data
  //   ? []
  //   : data.filter((item) =>
  //       searchKeyword
  //         ? Object.values(item).some((value) =>
  //             value
  //               ?.toString()
  //               ?.toLowerCase()
  //               ?.includes(searchKeyword.toLowerCase().trim())
  //           )
  //         : true
  //     );

  const handleDelete = () => {
    const deleteData = async () => {
      try {
        const res = await axios.post(customerDeletePost, {
          id: itemId,
          customerid: selectedItemNo,
          companyid: companyid,
          userid: userID,
          org: org,
        });

        if (res.data.message == "Customer deleted successfully") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyError("Error Deleting Customer");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    deleteData();
  };

  const handleEdit = (id) => {
    navigate(`/sales/customer/editCustomer/${id}`);
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

        userid: userID,
        submodule: "Customer",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Customers</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {/*} <AddPageButton
              text="Customer"
              link={`/sales/customer/addCustomer`}
  /> */}
            {crud && crud.includes("c") && <AddCustomerModal />}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading data={TableHeadings} />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="8" />
            ) : data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.customerid}</td>
                  <td>
                    {item.customertype == "Retail"
                      ? item.fullname
                      : item.bdcompany}
                  </td>
                  <td>{item.customertype}</td>
                  <td>{item.bacity}</td>
                  <td>
                    {item.customertype == "Retail"
                      ? item.dob
                        ? indianFormatDate(item.dob)
                        : "--"
                      : "--"}
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.customeremail}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <ViewCustomer item={item} />
                      {crud && crud.includes("u") && (
                        <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(item.id)}
                        />
                      )}
                      {crud && crud.includes("d") && (
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={() => setShow(false)}
                          handleDelete={handleDelete}
                          name="Customer"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="8" name="Customers" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListCustomer;
