import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { BiSolidFileImport } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import GlobalPagination from "../../../../constants/GlobalPagination";
import ListTableName from "../../../../constants/ListTableName";
import SearchField from "../../../../constants/SearchField";
import AddPaymentForm from "./AddPaymentForm";
import {
  deletePayment,
  paymentPagiData,
} from "../../../../redux/actions/payment.actions";
import DataSpinner from "../../../../constants/DataSpinner";
import { indianFormatDate } from "../../../../constants/indianFormatDate";
import NoRecordFoud from "../../../../constants/NoRecordFoud";
import { notifyWarning } from "../../../../constants/toastAlert";
import * as XLSX from "xlsx";
import DeleteConfirmation from "../../../../constants/DeleteConfirmation";
import EditPaymentForm from "./EditPaymentForm";
import MonthNumber from "../../../../constants/MonthNumber";
import axios from "axios";

function ListTablePayment() {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");
  const firstFinalcialYear = finalYear[0].replace('"', "");
  const secondFinalcialYear = finalYear[1].replace('"', "");

  const finalcialMonthYear = [
    `April ${firstFinalcialYear}`,
    `May ${firstFinalcialYear}`,
    `June ${firstFinalcialYear}`,
    `July ${firstFinalcialYear}`,
    `August ${firstFinalcialYear}`,
    `September ${firstFinalcialYear}`,
    `October ${firstFinalcialYear}`,
    `November ${firstFinalcialYear}`,
    `December ${firstFinalcialYear}`,
    `January ${secondFinalcialYear}`,
    `February ${secondFinalcialYear}`,
    `March ${secondFinalcialYear}`,
  ];

  //state
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);
  const selectedMonth = month.split(" ");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.paymentData.paymentPagiData) || [];
  const filteredData = !data.data ? [] : data.data;

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    month: selectedMonth[0],
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(paymentPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, month]);

  const handleEditModal = (item) => {
    setShowEditModal(true);
    setEditItem(item);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditItem(null);
    // dispatch(reloadPage(true));
  };

  //Delete
  const handleShow = (item) => {
    const { id, paymentid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(paymentid);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItemId(null);
  };

  const handleDelete = () => {
    dispatch(
      deletePayment(
        {
          id: selectedItemId,
          paymentid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  function handleExport() {
    if (filteredData.length == 0) {
      return notifyWarning("No Payment Record to Export");
    } else {
      const payment = filteredData.map((item, ind) => {
        const {
          date,
          paymentid,
          prefno,
          paymentmode,
          paidthrough,
          paidto,
          amount,
        } = item;

        return {
          DATE: date,
          "PAYMENT NO.": paymentid,
          "REFERENCE NO./CHEQUE NO.": prefno,
          "PAYMENT MODE": paymentmode,
          "PAID THROUGH": paidthrough,
          "PAID TO": paidto,
          AMOUNT: amount,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(payment);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Payment");
      XLSX.writeFile(workbook, "Payment.xlsx");
    }
  }

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);


  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Transaction",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);


  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={5}>
            <ListTableName name="Payment" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Month</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="">All</option>
                {finalcialMonthYear.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>{crud && crud.includes("c") && <AddPaymentForm />}</Col>
          <Col lg={1}>
            <Button
              className="commonBtn shadow-none cancelBtn mt-2"
              onClick={handleExport}
            >
              <BiSolidFileImport className="mx-1" />
              Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Payment No.</th>
              <th>Reference No./Cheque No.</th>
              <th>Payment Mode</th>
              <th>Paid Through</th>
              <th>Paid To</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="8" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  date,
                  paymentid,
                  prefno,
                  paymentmode,
                  paidthrough,
                  paidto,
                  amount,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!date ? "--" : indianFormatDate(date)}</td>
                    <td>{!paymentid ? "--" : paymentid}</td>
                    <td>{!prefno ? "--" : prefno}</td>
                    <td>{!paymentmode ? "--" : paymentmode}</td>
                    <td>
                      {paymentmode != "Cash"
                        ? `${paidthrough.split(",")[0]},${
                            paidthrough.split(",")[1]
                          }`
                        : paidthrough}
                    </td>
                    <td>{!paidto ? "--" : paidto}</td>
                    <td>{!amount ? "--" : amount}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <span>
                        {crud && crud.includes("u") && (
                          <EditPaymentForm
                            showEditModal={showEditModal}
                            handleEditModal={() => handleEditModal(item)}
                            handleCloseEditModal={handleCloseEditModal}
                            editItem={editItem}
                          />
                        )}
                      </span>
                      <span>
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="8" name="Payment" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
}

export default ListTablePayment;
