import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyWarning } from "../../../constants/toastAlert";
import {
  validateCIN,
  validateEmail,
  validateGSTNumber,
  validatePINCode,
  validatePanNumber,
  validatePhoneNumber,
} from "../../../constants/validations";
import {
  addCompany,
  updateCompany,
} from "../../../redux/actions/company.actions";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";

const states = State.getStatesOfCountry("IN");

const EditFirm = () => {
  const URL = extractAndFormatURLPart("admin");
  const { id } = useParams();
  const { companyid, userid } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const [data, setData] = useState({
    companyname: "",
    companyid: "",
    ownername: "",
    hoaddress: "",
    emailid: "",
    status: "",
    phoneno: "",
    registrationno: "",
    msmeno: "",
    userid: userid,
    state: "",
    city: "",
    pincode: "",
    type: "",
    cinno: "",
    gstno: "",
    pan: "",
    id: id,
  });
  const [showEmptyError, setShowEmptyError] = useState(false);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "state") {
      if (value) {
        setData((prev) => ({
          ...prev,
          [name]: value,
          city: "",
        }));
      } else {
        // setSelectedState("");
        setData((prev) => ({
          ...prev,
          state: "",
          city: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    let validateErrCount = 0;

    if (
      !data.companyname | !data.emailid ||
      !data.city ||
      !data.hoaddress ||
      !data.pincode ||
      !data.ownername ||
      !data.phoneno ||
      !data.state ||
      !data.type
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    //---for validation start---
    const validateAndNotify = (field, validator) => {
      if (data[field]) {
        const validationMsg = validator(data[field]);
        if (validationMsg !== "Done") {
          validateErrCount++;
          return notifyError(validationMsg);
        }
      }
    };

    

    validateAndNotify("phoneno", validatePhoneNumber);
    validateAndNotify("emailid", validateEmail);
    validateAndNotify("pincode", validatePINCode);

    if (validateErrCount > 0) {
      return;
    }

    if (data.pan) {
      validateAndNotify("pan", validatePanNumber);
    }
    if (data.cinno) {
      validateAndNotify("cinno", validateCIN);
    }
    if (data.gstno) {
      validateAndNotify("gstno", validateGSTNumber);
    }

    if (validateErrCount > 0) {
      return;
    }

    //---for validation end---
    dispatch(updateCompany(data, navigate));
  };

  useEffect(() => {
    if (data.state) {
      const selectedState = states.find((state) => state.name === data.state);
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedState.isoCode
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setCities(cityNames);
    } else {
      setCities([]);
    }
  }, [data.state]);

  useEffect(() => {
    axios
      .post("https://erp.venturesathi.co.in/api/v1/master/companybyid", { id })

      .then((res) => {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      })
      .catch((err) => {
        console.log(err);
        notifyWarning(err);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/admin/companyManagement/")}
              />
              Edit <span className="form-title main"> Company</span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Company Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          name="companyname"
                          value={data.companyname}
                          onChange={handleChange}
                          isInvalid={!data.companyname && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Company Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Email ID<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="emailid"
                          value={data.emailid}
                          onChange={handleChange}
                          isInvalid={!data.emailid && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Email ID
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Owner Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          name="ownername"
                          value={data.ownername}
                          onChange={handleChange}
                          isInvalid={!data.ownername && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Owner Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Phone Number<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone No."
                          name="phoneno"
                          value={data.phoneno}
                          onChange={handleChange}
                          isInvalid={!data.phoneno && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Phone Number
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Type <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="type"
                          value={data.type}
                          onChange={handleChange}
                          isInvalid={!data.type && showEmptyError}
                        >
                          <option value="">Select</option>
                          <option value="Franchise">Franchise</option>
                          <option value="Other Company">Other Company</option>
                          <option value="Sub-Dealer">Sub-Dealer</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>PAN</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter PAN No."
                              name="pan"
                              value={data.pan}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>GSTIN</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter GSTIN No."
                              name="gstno"
                              value={data.gstno}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>CIN No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter CIN No. "
                          name="cinno"
                          value={data.cinno}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>MSME Registration Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Reg No."
                          name="msmeno"
                          value={data.msmeno}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Head Office Adress<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          name="hoaddress"
                          value={data.hoaddress}
                          onChange={handleChange}
                          isInvalid={!data.hoaddress && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Address
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              State <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="state"
                              value={data.state}
                              onChange={handleChange}
                              isInvalid={!data.state && showEmptyError}
                            >
                              <option value="">Select</option>
                              {states.map((state) => {
                                return (
                                  <option
                                    value={state.name}
                                    key={state.isoCode}
                                  >
                                    {state.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              City <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="city"
                              value={data.city}
                              onChange={handleChange}
                              isInvalid={!data.city && showEmptyError}
                            >
                              <option value="">Select</option>
                              {cities.map((city, ind) => {
                                return (
                                  <option value={city} key={ind}>
                                    {city}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Pincode <span className="star">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name="pincode"
                              value={data.pincode}
                              onChange={handleChange}
                              isInvalid={!data.pincode && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Update"
              )}
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/admin/companyManagement/")}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default EditFirm;
