import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import { salesInvoicePagiData } from "../../../../redux/actions/salesInvoice.actions";
import { notifyError, notifySuccess } from "../../../../constants/toastAlert";

function Ewaybill() {
  const URL = extractAndFormatURLPart("ewaybill");
  const navigate = useNavigate();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];

  let financialyear = localStorage.financialyear;
  // const financialyear = financialyearString;
  const [loading, setLoading] = useState(false);
  const loadData = useSelector((state) => state.loadReducer);
  const location = useLocation();

  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const {
    companyname: lscompany,
    state: lsstate,
    city: lscity,
    pincode: lspincode,
    gstno: lsgstno,
    pan: lspan,
    hoaddress: lshoaddress,
  } = useSelector((state) => state.authManager.userData.companyaddress) || {};
  const salesInvoiceMaster =
    useSelector((state) => state.salesInvoiceData.salesInvoicePagiData.data) ||
    [];
  // const invoiceno = location.state.invoiceno;
  //   States

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const [data, setData] = useState({
    supplyType: "O",
    subSupplyType: "",
    docNo: "",
    docDate: getTodayDate(), //
    fromGstin: lsgstno || "",
    fromTrdName: name || "",
    fromAddr1: lshoaddress || "",
    fromAddr2: "",
    fromPlace: lscity || "",
    fromPincode: lspincode || "",
    fromStateCode: lsgstno ? `${lsgstno[0]}${lsgstno[1]}` : "",
    toGstin: "",
    toTrdName: "",
    toAddr1: "",
    toAddr2: "",
    toPlace: "",
    toPincode: 0,
    toStateCode: "",
    transactionType: 0,
    dispatchFromGSTIN: lsgstno || "",
    dispatchFromTradeName: companyname || "",
    shipToGSTIN: "",
    shipToTradeName: "",
    totalValue: 0,
    cgstValue: 0,
    sgstValue: 0,
    igstValue: 0,
    cessValue: 0,
    cessNonAdvolValue: 0,
    totInvValue: 0,
    transMode: "",
    transDistance: "",
    transporterName: "",
    transporterId: "",
    transDocNo: "",
    vehicleNo: "",
    vehicleType: "",
    itemList: [],
    companyid,
    financialyear,
  });
  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name == "docNo") {
      if (value) {
        const findSaleInvoice = salesInvoiceMaster.find(
          (si) => si.invoiceno == value
        );
        const { bdgstno, bdowner, bdcompany } =
          findSaleInvoice.commonnamealldata[0];

        const itemList =
          findSaleInvoice.itemarray.length > 0
            ? findSaleInvoice.itemarray.map((item) => ({
                productName: item.itemname,
                productDesc: item.itemdescription,
                hsnCode: item.hsncode,
                quantity: +item.qty,
                qtyUnit: item.unit,
                taxableAmount: +item.amount,
                sgstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                cgstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                igstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? 0
                    : (Number(item.amount) * Number(item.gst)) / 100
                ).toFixed(2),
                cessRate: 0,
              }))
            : [];

        setData((prev) => ({
          ...prev,
          toGstin: bdgstno,
          toTrdName: findSaleInvoice.commonname,
          shipToTradeName: bdcompany,
          shipToGSTIN: bdgstno,
          toAddr1: findSaleInvoice.staddress,
          toPlace: findSaleInvoice.stcity,
          toPincode: findSaleInvoice.stpin,
          toStateCode: bdgstno ? `${bdgstno[0]}${bdgstno[1]}` : "",
          itemList: itemList,
          cgstValue: Number(findSaleInvoice.cgst),
          sgstValue: Number(findSaleInvoice.sgst),
          igstValue: Number(findSaleInvoice.igst),
          totalValue: Number(findSaleInvoice.subtotal),
          totInvValue: Number(findSaleInvoice.grandtotal),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          toGstin: "",
          toTrdName: "",
          shipToGSTIN: "",
          shipToTradeName: "",
          toAddr1: "",
          toPlace: "",
          toPincode: "",
          toStateCode: "",
          itemList: [],
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          totalValue: 0,
          totInvValue: 0,
        }));
      }
    }
  };

  // const addItem = () => {
  //   // Create a new item object with default values
  //   const newItem = {
  //     productName: "",
  //     productDesc: "",
  //     hsnCode: 0,
  //     quantity: 0,
  //     qtyUnit: "",
  //     taxableAmount: 0,
  //     sgstRate: 0,
  //     cgstRate: 0,
  //     igstRate: 0,
  //     cessRate: 0,
  //   };

  //   // Append the new item to the itemList array
  //   setData((prevData) => ({
  //     ...prevData,
  //     itemList: [...prevData.itemList, newItem],
  //   }));
  // };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;

    // Determine if the input is a numeric field
    const isNumericField = [
      "hsnCode",
      "quantity",
      "taxableAmount",
      "sgstRate",
      "cgstRate",
      "igstRate",
      "cessRate",
    ].includes(name);

    // Convert the value to a number if it is a numeric field
    const newValue = isNumericField ? parseFloat(value) : value;

    // Update the state with a new array
    setData((prevState) => {
      // Copy the previous state
      const updatedItemList = [...prevState.itemList];

      // Update the specific field of the item at the given index
      updatedItemList[index] = {
        ...updatedItemList[index],
        [name]: newValue,
      };

      // Return the new state with updated itemList
      return {
        ...prevState,
        itemList: updatedItemList,
      };
    });
  };

  const handleSubmit = () => {
    if (
      !data.supplyType ||
      !data.subSupplyType ||
      !data.docNo ||
      !data.docDate ||
      !data.transactionType ||
      !data.transMode ||
      !data.transDistance ||
      !data.vehicleType ||
      !data.vehicleNo 
      // !data.transporterId ||
      // !data.transDocNo
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    } else {
      axios
        .post(`https://erp.venturesathi.co.in/api/v1/ewaybill/create`, data)
        .then((res) => {
          if(res.data.status_cd == "1"){
            notifySuccess("EWB Created Successful")
            navigate("/ewaybill")
          }else{
            notifyError(res.data.error.message)
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleLeftArrow = () => {
    navigate("/ewaybill");
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      salesInvoicePagiData(
        { userid, branchid, companyid, keyword: org },
        setLoading
      )
    );
  }, [dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Add <span className="form-title main"> E-waybill</span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="supplyType">
                          <Form.Label>
                            Supply Type <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            // as="select"
                            name="supplyType"
                            value={data.supplyType}
                            onChange={handleChange}
                            isInvalid={!data.supplyType && showEmptyError}
                          >
                            <option value="">Select Supply Type</option>
                            <option value="I">Inward</option>
                            <option value="O">Outward</option>
                            {/* Add more options as needed */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="subSupplyType">
                          <Form.Label>
                            Sub Supply Type <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            // as="select"
                            name="subSupplyType"
                            value={data.subSupplyType}
                            onChange={handleChange}
                            isInvalid={!data.subSupplyType && showEmptyError}
                          >
                            <option value="">Select Sub Supply Type</option>
                            <option value="1">Supply</option>
                            <option value="2">Import</option>
                            <option value="3">Export</option>
                            <option value="4">Job Work</option>
                            <option value="5">For Own Use</option>
                            <option value="6">Job work Returns</option>
                            <option value="7">Sales Return</option>
                            <option value="8">Others</option>
                            <option value="9">SKD/CKD/Lots</option>
                            <option value="10">Line Sales</option>
                            <option value="11">Recipient Not Known</option>
                            <option value="12">Exhibition or Fairs</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="docNo">
                          <Form.Label>
                            Document Number <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            name="docNo"
                            value={data.docNo}
                            onChange={handleChange}
                            isInvalid={!data.docNo && showEmptyError}
                          >
                            <option value="">Select Document No</option>
                            {salesInvoiceMaster?.map((si) => (
                              <option value={si.invoiceno} key={si.id}>
                                {si.invoiceno}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="docDate">
                          <Form.Label>
                            Document Date <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="docDate"
                            value={`${data.docDate.split("/")[2]}-${
                              data.docDate.split("/")[1]
                            }-${data.docDate.split("/")[0]}`}
                            // onChange={handleChange}
                            disabled
                            isInvalid={!data.docDate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="transactionType">
                          <Form.Label>
                            Transaction Type <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            // as="select"
                            name="transactionType"
                            value={data.transactionType}
                            onChange={handleChange}
                            isInvalid={!data.transactionType && showEmptyError}
                          >
                            <option value="">Select Transaction Type</option>
                            <option value={1}>Regular</option>
                            <option value={2}>Bill To - Ship To</option>
                            <option value={3}>Bill From - Dispatch From</option>
                            <option value={4}>Combination of 2 and 3</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {/* <Col md={6}>
                        <Form.Group controlId="transporterName">
                          <Form.Label>Transporter </Form.Label>
                          <Form.Control
                            type="text"
                            name="transporterName"
                            value={data.transporterName}
                            onChange={handleChange}
                            placeholder="Enter Transporter name"
                            // isInvalid={!data.transporterName && showEmptyError}
                          />
                        </Form.Group>
                      </Col> */}
          
                    </Row>
                  </Col>
                </Row>
                <br />
                <hr />
                <Row className="my-2">
                  <Col md={6}>
                    <h3>Supplier Details</h3>
                  </Col>
                  <Col md={6}>
                    <h3>Customer Details</h3>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <h4 className="font-bold">From</h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="font-bold">To</h4>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Name</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromTrdName ? data.fromTrdName : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Name</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toTrdName ? data.toTrdName : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">GSTIN</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromGstin ? data.fromGstin : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">GSTIN</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toGstin ? data.toGstin : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <h4 className="font-bold">Dispatch From</h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="font-bold">Ship To</h4>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Address</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromAddr1}</h5>
                        <h5>
                          {lsstate}
                          {lsstate ? "," : ""}
                          {lscity}
                          {lscity ? "," : ""}
                          {lspincode}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Address</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toAddr1}</h5>
                        {/* <h5>
                          {lsstate}
                          {lsstate ? "," : ""}
                          {lscity}
                          {lscity ? "," : ""}
                          {lspincode}
                        </h5> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Location</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromPlace ? data.fromPlace : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Location</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toPlace ? data.toPlace : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Pin Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromPincode ? data.fromPincode : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Pin Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toPincode ? data.toPincode : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">State Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromStateCode}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">State Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toStateCode}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />

                <hr></hr>
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="vehicleType">
                          <Form.Label>
                            Vehicle Type <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="vehicleType"
                            value={data.vehicleType}
                            onChange={handleChange}
                            isInvalid={!data.vehicleType && showEmptyError}
                          >
                            <option value="">Select Vehicle Type</option>
                            <option value="R">Regular</option>
                            <option value="O">
                              ODC(Over Dimentional Cargo)
                            </option>
                            {/* Add more options as needed */}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="vehicleNo">
                          <Form.Label>
                            Vehicle <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="vehicleNo"
                            value={data.vehicleNo}
                            onChange={handleChange}
                            placeholder="Enter Vehicle Number"
                            isInvalid={!data.vehicleNo && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
         
                    </Row>
                  </Col>
                  <Col>
                  <Row>
                <Col md={6}>
                        <Form.Group controlId="transDistance">
                          <Form.Label>
                            Distance (in Km) <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="transDistance"
                            value={data.transDistance}
                            onChange={handleChange}
                            // placeholder="Enter Transport Distance"
                            isInvalid={!data.transDistance && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="transMode">
                          <Form.Label>
                            {" "}
                            Mode Of Transportation{" "}
                            <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            // as="select"
                            name="transMode"
                            value={data.transMode}
                            onChange={handleChange}
                            isInvalid={!data.transMode && showEmptyError}
                          >
                            <option value="">Select Transport Mode</option>
                            <option value="1">Road</option>
                            <option value="2">Rail</option>
                            <option value="3">Air</option>
                            <option value="4">Ship</option>
                            <option value="5">inTransit</option>

                            {/* Add more options as needed */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      </Row>
                </Col>

                  {/* <Col> */}
                    {/* <Row>
                      <Col md={6}>
                        <Form.Group controlId="transporterId">
                          <Form.Label>
                            Transporter ID <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="transporterId"
                            value={data.transporterId}
                            onChange={handleChange}
                            placeholder="Enter Transporter ID"
                            isInvalid={!data.transporterId && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="transDocNo">
                          <Form.Label>
                            Transport Document Number{" "}
                            <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="transDocNo"
                            value={data.transDocNo}
                            onChange={handleChange}
                            placeholder="Enter Transport Document Number"
                            isInvalid={!data.transDocNo && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row> */}
                  {/* </Col> */}
                </Row>
                <br />
                <hr />
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="cgstValue">
                          <Form.Label>CGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="cgstValue"
                            value={data.cgstValue}
                            onChange={handleChange}
                            // placeholder="Enter CGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="sgstValue">
                          <Form.Label>SGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="sgstValue"
                            value={data.sgstValue}
                            onChange={handleChange}
                            // placeholder="Enter SGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="igstValue">
                          <Form.Label>IGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="igstValue"
                            value={data.igstValue}
                            onChange={handleChange}
                            // placeholder="Enter IGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="cessValue">
                          <Form.Label>Cess Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="cessValue"
                            value={data.cessValue}
                            onChange={handleChange}
                            // placeholder="Enter Cess Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="cessNonAdvolValue">
                          <Form.Label>Cess Non-Advol Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="cessNonAdvolValue"
                            value={data.cessNonAdvolValue}
                            onChange={handleChange}
                            // placeholder="Enter Cess Non-Advol Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="totalValue">
                          <Form.Label>Total Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="totalValue"
                            value={data.totalValue}
                            onChange={handleChange}
                            // placeholder="Enter Total Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="totInvValue">
                      <Form.Label>Total Invoice Value</Form.Label>
                      <Form.Control
                        type="number"
                        name="totInvValue"
                        value={data.totInvValue}
                        onChange={handleChange}
                        // placeholder="Enter Total Invoice Value"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
              <hr></hr>
              <Container fluid>
                {/* <Row>
                  <Col>
                    <Button variant="primary" onClick={addItem}>
                      Add Item <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row> */}
                {data.itemList.map((item, index) => (
                  <Row key={index}>
                    <Col>
                      <Form.Group controlId={`productName`}>
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          name={`productName`}
                          value={item.productName}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Product Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`productDesc`}>
                        <Form.Label>Product Desc</Form.Label>
                        <Form.Control
                          type="text"
                          name={`productDesc`}
                          value={item.productDesc}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Product Description"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`hsnCode`}>
                        <Form.Label>HSN Code</Form.Label>
                        <Form.Control
                          type="number"
                          name={`hsnCode`}
                          value={item.hsnCode}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter HSN Code"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`quantity`}>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          name={`quantity`}
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Quantity"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`qtyUnit`}>
                        <Form.Label>Qty Unit</Form.Label>
                        <Form.Control
                          type="text"
                          name={`qtyUnit`}
                          value={item.qtyUnit}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Qty Unit"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`taxableAmount`}>
                        <Form.Label>Taxable Amnt</Form.Label>
                        <Form.Control
                          type="number"
                          name={`taxableAmount`}
                          value={item.taxableAmount}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Taxable Amount"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`sgstRate`}>
                        <Form.Label>SGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`sgstRate`}
                          value={item.sgstRate}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter SGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`cgstRate`}>
                        <Form.Label>CGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`cgstRate`}
                          value={item.cgstRate}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter CGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`igstRate`}>
                        <Form.Label>IGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`igstRate`}
                          value={item.igstRate}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter IGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`cessRate`}>
                        <Form.Label>Cess Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`cessRate`}
                          value={item.cessRate}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Cess Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ))}
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSubmit}>
            Create
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleLeftArrow}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
}

export default Ewaybill;
