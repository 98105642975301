import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import TableHeading from "../../../constants/TableHeading";
import { initialData } from "../../../constants/resData";
import axios from "axios";
import {
  branchPagiDataPost,
  companyPagiDataPost,
  customerGetPost,
  getPurchaseOrderPagiApi,
  getPurchasebillApi,
  getVendorPagiApi,
  idgenerateIssueGood,
  issuegoodAdd,
  itemGetItemPost,
  salesInvoicePagiDataPost,
  salesOrderPagiDataPost,
} from "../../../constants/api";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdDelete } from "react-icons/md";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import AvailableStock from "./AvailableStock";
import SuggestedItems from "./SuggestedItems";
import { useDispatch, useSelector } from "react-redux";
import { invoiceOrChallanUpload } from "../../../redux/actions/mtout.action";
import RoomRackDetails from "./RoomRackDetails";

const Issue = () => {
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let userName = localData.name;
  let org = localData.org
  const invoicorChallan = useSelector(
    (state) => state.issueGoodData.invoiceChallan
  );

  const URL = extractAndFormatURLPart("inventory");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [issueIDData, setIssueIDData] = useState([]);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showbtn, setShowBtn] = useState(true);
  const [vendorData, setVendorData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [franchiseData, setFranchiseData] = useState([]);
  const [subDealerData, setSubDealerData] = useState([]);
  const [pbData, setPBData] = useState([]);
  const [pbArray, setPBArray] = useState([]);
  const [siData, setSIData] = useState([]);
  const [siArray, setSIArray] = useState([]);
  const [errors, setErrors] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [suggesteditems, setSuggestedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedItem, setClickedItem] = useState("");
  const [data, setData] = useState({
    issueto: "",
    issuetoname: "",
    issuetoid: "",
    psorder: "",
    gino: "",
    gidate: "",
    vehicletype: "",
    vehicleno: "",
    issuedby: "",
    checkedby: "",
    remarks: "",
    ewaybill: "",
    uploads: [],
    itemarray: [],
    subtotal: "",
    branchid: branchID,
    companyid: companyID,
    userid: userID,
    org: org,
    month: "",
    financialyear:finalcialYear,
    salesperson: "",
    transportername: "",
    dispatchdate:""
  });

  const ReqBody = {
    keyword: "" || org,
    branchid: branchID,
    userid: userID,
    companyid: companyID,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyRes, branchRes, vendorRes, customerRes, poRes, soRes] =
          await Promise.all([
            axios.post(companyPagiDataPost, ReqBody),
            axios.post(branchPagiDataPost, ReqBody),
            axios.post(getVendorPagiApi, ReqBody),
            axios.post(customerGetPost, ReqBody),
            axios.post(getPurchasebillApi, ReqBody),
            axios.post(salesOrderPagiDataPost, ReqBody),
          ]);
        setCompanyData(
          initialData(companyRes).filter((ele) => ele.type == "Other Company")
        );
        setBranchData(initialData(branchRes));
        setSubDealerData(
          initialData(companyRes).filter((ele) => ele.type == "Sub-Dealer")
        );
        setFranchiseData(
          initialData(companyRes).filter((ele) => ele.type == "Franchise")
        );
        setVendorData(initialData(vendorRes));
        setCustomersData(initialData(customerRes));
        setPBData(
          initialData(poRes).filter((ele) => ele.vendor == data.issuetoname)
        );

        let x =
          pbData.length > 0
            ? pbData.filter((ele) => ele.purchasebillno == data.psorder)
            : [];

        setPBArray(
          x[0].itemarray && x[0].itemarray.length > 0 ? x[0].itemarray : []
        );

        setErrors(Array(pbArray.length).fill(""));
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [data.issuetoname, data.psorder, data.issueto]);

  //items
  useEffect(() => {
    axios
      .post(itemGetItemPost, ReqBody)
      .then((res) => {
        let items =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setItemData(items);
      })
      .catch((err) => console.log(err));
  }, []);

  // sales Invoices
  useEffect(() => {
    axios
      .post(salesInvoicePagiDataPost, { ...ReqBody, status: "" })
      .then((res) => {
        let orders =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setSIData(orders.filter((ele) => ele.commonname == data.issuetoname));
        let y =
          siData.length > 0
            ? siData.filter((ele) => ele.invoiceno == data.psorder)
            : [];

        setSIArray(
          y[0].itemarray && y[0].itemarray.length > 0 ? y[0].itemarray : []
        );
      })
      .catch((err) => console.log(err));
  }, [data.issuetoname, data.issueto, data.psorder]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      uploads: invoicorChallan || [],
    }));
  }, [invoicorChallan]);

  const handleLeftArrow = () => {
    navigate("/inventory/manageStock");
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "issueto") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        issuetoname: "",
        issuetoid: "",
        psorder: "",
      }));
    } else if (name === "issuetoname") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (data.issueto === "Customer") {
        const selectedCustomer = customersData.find(
          (customer) =>
            customer.fullname === value || customer.bdcompany === value
        );
        if (selectedCustomer) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: selectedCustomer.customerid,
          }));
        }
      } else if (data.issueto === "Other Company") {
        const selectedCompany = companyData.find(
          (company) => company.companyname === value
        );
        if (selectedCompany) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: `OC-${selectedCompany.id}`,
          }));
        }
      } else if (data.issueto === "Branch") {
        const selectedBranch = branchData.find(
          (branch) => branch.branchname === value
        );
        if (selectedBranch) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: selectedBranch.branchid,
          }));
        }
      } else if (data.issueto === "Sub-Dealer") {
        const selectedSubDealer = subDealerData.find(
          (subDealer) => subDealer.companyname === value
        );
        if (selectedSubDealer) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: `SD-${selectedSubDealer.id}`,
          }));
        }
      } else if (data.issueto === "Franchise") {
        const selectedFranchise = franchiseData.find(
          (franchise) => franchise.companyname === value
        );
        if (selectedFranchise) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: `FC-${selectedFranchise.id}`,
          }));
        }
      } else if (data.issueto === "Return To Vendor") {
        const selectedVendor = vendorData.find(
          (vendor) => vendor.fullname === value
        );
        if (selectedVendor) {
          setData((prevData) => ({
            ...prevData,
            issuetoid: `VD-${selectedVendor.id}`, // Assuming there's a property called `vendorid` in vendor data
          }));
        }
      }
    } else if (name === "psorder") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      if (data.issueto === "Customer") {
        const selectedSaleinvoice = siData.find(
          (sale) => sale.saleorderno === value
        );
        if (selectedSaleinvoice) {
          setData((prevData) => ({
            ...prevData,
            salesperson: selectedSaleinvoice.salesperson,
          }));
        }
      }
    }
    if (name == "gidate") {
      const monthName = new Date(value).toLocaleDateString("en-US", {
        month: "long",
      });
      setData((prevData) => ({
        [name]: value,
        ...prevData,
        month: monthName,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name == "vehicletype") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        transportername: "",
      }));
    }
    if (name === "documents") {
      dispatch(invoiceOrChallanUpload(files));
    }

    setShowEmptyError(false);
  };

  // const handleMouseEnter = () => {
  //   setIsModalOpen(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsModalOpen(false);
  // };

  const handleModalToggle = () => {
    setIsModalOpen(false);
    setClickedItem("");
  };

  const handleTableRowChange = (e, index) => {
    const { name, value } = e.target;

    const updatedPBArray = [...pbArray];

    // Update the value in the array
    updatedPBArray[index][name] = value;
    if (parseInt(updatedPBArray[index].dispatchqty) <= 0) {
      return;
    }

    const selectedItem = itemData.find(
      (item) => item.itemid == updatedPBArray[index].itemid
    );

    // Check if dispatch quantity is greater than order quantity
    if (
      name === "dispatchqty" &&
      parseFloat(value) > parseFloat(updatedPBArray[index].qty)
    ) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] =
          "Dispatch quantity cannot be greater than order quantity";
        return newErrors;
      });
    } else if (
      name === "dispatchqty" &&
      parseFloat(value) > parseFloat(selectedItem.openingqty)
    ) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] =
          "Dispatch quantity entered is currently not available in the inventory.";
        return newErrors;
      });
    } else {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }

    // Update the state with the modified array
    setPBArray(updatedPBArray);
    setPBArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
        subtotal: updatedTableData.reduce((total, row) => {
          return total + (Number(row.dispatchqty) * Number(row.rate) || 0);
        }, 0),
      }));

      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (Number(row.dispatchqty) * Number(row.rate) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        subtotal: newTotalQty,
      }));
      return updatedTableData;
    });
  };
  const handleTableRowChange2 = (e, index) => {
    const { name, value } = e.target;

    const updatedItems = [...suggesteditems];

    // Update the value in the array
    updatedItems[index][name] = value;
    if (parseInt(updatedItems[index].dispatchqty) <= 0) {
      return;
    }

    const selectedItem = itemData.find(
      (item) => item.itemid == updatedItems[index].itemid
    );

    // Check if dispatch quantity is greater than order quantity
    if (
      name === "dispatchqty" &&
      parseFloat(value) > parseFloat(selectedItem.inhandquantity)
    ) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] =
          "Dispatch quantity entered is currently not available in the inventory.";
        return newErrors;
      });
    } else {
      setErrors([]);
    }

    setSuggestedItems(updatedItems);
    setSuggestedItems((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
        subtotal: updatedTableData.reduce((total, row) => {
          return total + (Number(row.dispatchqty) * Number(row.rate) || 0);
        }, 0),
      }));

      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (Number(row.dispatchqty) * Number(row.rateperunit) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        subtotal: newTotalQty,
      }));
      return updatedTableData;
    });
  };
  const handleTableRowChange3 = (e, index) => {
    const { name, value } = e.target;
    const updatedSIArray = [...siArray];
    // Update the value in the array
    updatedSIArray[index][name] = value;
    if (parseInt(updatedSIArray[index].dispatchqty) <= 0) {
      return;
    }

    const selectedItem = itemData.find(
      (item) => item.itemid == updatedSIArray[index].itemid
    );

    if (
      name === "dispatchqty" &&
      parseFloat(value) > parseFloat(updatedSIArray[index].qty)
    ) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] =
          "Dispatch quantity cannot be greater than order quantity";
        return newErrors;
      });
    } else if (
      name === "dispatchqty" &&
      parseFloat(value) > parseFloat(selectedItem.openingqty)
    ) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] =
          "Dispatch quantity entered is currently not available in the inventory.";
        return newErrors;
      });
    } else {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "";
        return newErrors;
      });
    }

    // Update the state with the modified array
    setSIArray(updatedSIArray);
    setSIArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
        subtotal: updatedTableData.reduce((total, row) => {
          return total + (Number(row.dispatchqty) * Number(row.rate) || 0);
        }, 0),
      }));

      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (Number(row.dispatchqty) * Number(row.rate) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        subtotal: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleTableRowDelete = (rowIndex) => {
    setData((prevState) => {
      const updatedItemArray = prevState.itemarray.filter(
        (item, i) => i !== rowIndex
      );

      const newtotal = updatedItemArray.reduce((total, row) => {
        return total + (parseInt(row.dispatchqty) * parseInt(row.rate) || 0);
      }, 0);

      return {
        ...prevState,
        itemarray: updatedItemArray,
        subtotal: newtotal,
      };
    });
  };

  const handleSubmit = async () => {
    if (data.issueto == "" || data.issuetoname == "" || data.gidate == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    if (
      (data.issueto == "Customer" && data.psorder == "") ||
      (data.issueto == "Return To Vendor" && data.psorder == "") ||
      data.issuetoname == "" ||
      data.gidate == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    const res = await axios.post(issuegoodAdd, data);
    if (res.statusText == "OK") {
      notifySuccess(res.data.message);
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  //dynamic GI generate
  // axios
  // .post(idgenerateIssueGood, ReqBody)
  // .then((res) => {
  //   let count = +res.data.count + 1;
  //   let paddedCount = count.toString().padStart(3, "0");
  //   let result = `GI\\SAANVI\\${paddedCount}`;
  //   setData((prevData) => ({
  //     ...prevData,
  //     gino: result,
  //   }));
  // })
  // .catch((err) => console.log(err));
  useEffect(() => {
    axios

      .post("https://erp.venturesathi.co.in/api/v1/issuegood/getpage", {

        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setIssueIDData(initialData);
      })
      .catch((err) => console.log(err));
  }, []);

  const generateCategoryID = () => {
    if (issueIDData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = issueIDData[0].gino.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      gino: `GI/SAANVI/${paddedCount}`,
    }));
  }, [issueIDData]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Issue <span className="form-title main"> Goods</span>
          </h1>
          <hr />
          <Container fluid>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Issue To <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="issueto"
                    value={data.issueto}
                    onChange={handleChange}
                    isInvalid={data.issueto === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    <option value="Customer">Customer</option>
                    <option value="Other Company">Other Company</option>
                    <option value="Branch">Branch</option>
                    <option value="Sub-Dealer">Sub-Dealer</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Return To Vendor">Return To Vendor</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose Issue To
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    {data.issueto == "Customer"
                      ? "Customer"
                      : data.issueto == "Other Company"
                      ? "Other Company"
                      : data.issueto == "Branch"
                      ? "Branch"
                      : data.issueto == "Sub-Dealer"
                      ? "Sub-Dealer"
                      : data.issueto == "Franchise"
                      ? "Franchise"
                      : "Vendor"}
                    <span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    name="issuetoname"
                    value={data.issuetoname}
                    onChange={handleChange}
                    isInvalid={data.issuetoname === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {data.issueto == "Customer" ? (
                      customersData.length > 0 &&
                      customersData.map((item, index) =>
                        item.customertype == "Retail" ? (
                          <option key={index} value={item.fullname}>
                            {item.fullname}
                          </option>
                        ) : (
                          <option key={index} value={item.bdcompany}>
                            {item.bdcompany}
                          </option>
                        )
                      )
                    ) : data.issueto == "Other Company" ? (
                      companyData.length > 0 &&
                      companyData.map((item, index) => (
                        <option key={index} value={item.companyname}>
                          {item.companyname}
                        </option>
                      ))
                    ) : data.issueto == "Branch" ? (
                      branchData.length > 0 &&
                      branchData.map((item, index) => (
                        <option key={index} value={item.branchname}>
                          {item.branchname}
                        </option>
                      ))
                    ) : data.issueto == "Sub-Dealer" ? (
                      subDealerData.length > 0 &&
                      subDealerData.map((item, index) => (
                        <option key={index} value={item.companyname}>
                          {item.companyname}
                        </option>
                      ))
                    ) : data.issueto == "Franchise" ? (
                      franchiseData.length > 0 &&
                      franchiseData.map((item, index) => (
                        <option key={index} value={item.companyname}>
                          {item.companyname}
                        </option>
                      ))
                    ) : data.issueto == "Return To Vendor" ? (
                      vendorData.length > 0 &&
                      vendorData.map((item, index) => (
                        <option key={index} value={item.fullname}>
                          {item.fullname}
                        </option>
                      ))
                    ) : (
                      <option value="">--Select--</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    {data.issueto == "Customer"
                      ? "Customer ID"
                      : data.issueto == "Other Company"
                      ? "Company ID"
                      : data.issueto == "Branch"
                      ? "Branch ID"
                      : data.issueto == "Sub-Dealer"
                      ? "Sub-Dealer ID"
                      : data.issueto == "Franchise"
                      ? "Franchise ID"
                      : "Vendor ID"}
                  </Form.Label>
                  <Form.Control
                    disabled
                    readOnly
                    type="text"
                    name="issuetoid"
                    value={data.issuetoid}
                  />
                </Form.Group>
              </Col>
              <Col>
                {data.issueto &&
                data.issueto != "Other Company" &&
                data.issueto != "Branch" &&
                data.issueto != "Franchise" &&
                data.issueto != "Sub-Dealer" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      {data.issueto == "Return To Vendor"
                        ? "Purchase Bill"
                        : "Sales Invoice"}
                      <span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      name="psorder"
                      value={data.psorder}
                      onChange={handleChange}
                      isInvalid={data.psorder === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      {data.issueto == "Return To Vendor"
                        ? pbData.length > 0 &&
                          pbData.map((item, index) => (
                            <option key={index} value={item.purchasebillno}>
                              {item.purchasebillno}
                            </option>
                          ))
                        : data.issueto == "Customer"
                        ? siData.length > 0 &&
                          siData.map((item, index) => (
                            <option key={index} value={item.invoiceno}>
                              {item.invoiceno}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                  </Form.Group>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>GI No.</Form.Label>
                  <Form.Control
                    disabled
                    readOnly
                    type="text"
                    name="gino"
                    value={data.gino}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    GI date <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="gidate"
                    value={data.gidate}
                    onChange={handleChange}
                    isInvalid={data.gidate === "" && showEmptyError}
                  />
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Vehicle Type</Form.Label>
                  <Form.Select
                    name="vehicletype"
                    value={data.vehicletype}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Company Transport">Company Transport</option>
                    <option value="Third Party Transport">
                      Third Party Transport
                    </option>
                    <option value="One Time Vendor">One Time Vendor</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Transporter Name</Form.Label>
                      {data.vehicletype == "Company Transport" && (
                        <Form.Control
                          disabled
                          type="text"
                          name="transportername"
                          value={(data.transportername = "SAANVI")}
                        />
                      )}
                      {data.vehicletype == "Third Party Transport" && (
                        <Form.Select
                          name="transportername"
                          value={data.transportername}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          {vendorData.length > 0 &&
                            vendorData
                              .filter(
                                (ele) => ele.vendorcategory == "Transporter"
                              )
                              .map((item, index) => (
                                <option key={index} value={item.companyname}>
                                  {item.companyname}
                                </option>
                              ))}
                        </Form.Select>
                      )}
                      {data.vehicletype == "One Time Vendor" && (
                        <Form.Control
                          type="text"
                          name="transportername"
                          value={data.transportername}
                          onChange={handleChange}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Vehicle No</Form.Label>
                      <Form.Control
                        type="text"
                        name="vehicleno"
                        value={data.vehicleno}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Issued By</Form.Label>
                      <Form.Control
                        disabled
                        readOnly
                        type="text"
                        name="issuedby"
                        value={(data.issuedby = userName)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Checked By{" "}
                        <span style={{ color: "grey" }}>(Optional)</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="checkedby"
                        value={data.checkedby}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    value={data.remarks}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1}>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>E-Way Bil</Form.Label>
                      <Form.Control
                        type="text"
                        name="ewaybill"
                        value={data.ewaybill}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Upload Invoice/Challan</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Select File"
                        name="documents"
                        value={data.documents}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                {data.issueto == "Customer" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Sales Person</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="salesperson"
                      value={data.salesperson}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <hr />
            <Modal.Footer className="d-flex justify-content-end gap-2 mt-3">
              {data.issueto != "Other Company" &&
                data.issueto != "Branch" &&
                data.issueto != "Sub-Dealer" &&
                data.issueto != "Franchise" && <AvailableStock />}
              {(data.issueto == "Other Company" ||
                data.issueto == "Branch" ||
                data.issueto == "Sub-Dealer" ||
                data.issueto == "Franchise") && (
                <SuggestedItems setSuggestedItems={setSuggestedItems} />
              )}
            </Modal.Footer>
            <br />
            <Table className="table-main-container" responsive>
              {data.issueto != "Other Company" &&
                data.issueto != "Branch" &&
                data.issueto != "Sub-Dealer" &&
                data.issueto != "Franchise" && (
                  <TableHeading
                    data={[
                      "#",
                      "Brand",
                      "Item Name",
                      "Item ID",
                      "UOM",
                      "Order Qty",
                      "Dispatch Qty",
                      "Rate",
                      "Amount",
                    ]}
                  />
                )}
              {(data.issueto == "Other Company" ||
                data.issueto == "Branch" ||
                data.issueto == "Sub-Dealer" ||
                data.issueto == "Franchise") && (
                <TableHeading
                  data={[
                    "#",
                    "Brand",
                    "Item Name",
                    "Item ID",
                    "UOM",
                    "In Hand Qty",
                    "Dispatch Qty",
                    "Rate",
                    "Amount",
                    "",
                  ]}
                />
              )}
              <tbody>
                {data.issueto != "Other Company" &&
                  data.issueto != "Branch" &&
                  data.issueto != "Sub-Dealer" &&
                  data.issueto != "Franchise" &&
                  pbArray.length > 0 &&
                  pbArray.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          disabled
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="qty"
                          className="text-center"
                          disabled
                          value={ele.qty}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="dispatchqty"
                          className="text-center"
                          value={ele.dispatchqty}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                        {errors[index] && (
                          <div className="text-danger">{errors[index]}</div>
                        )}
                      </td>
                      <td>
                        <Form.Control
                          name="rate"
                          className="text-center"
                          disabled
                          value={ele.rate}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="amount"
                          className="text-center"
                          disabled
                          value={(ele.amount = ele.dispatchqty * ele.rate)}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                {(data.issueto == "Other Company" ||
                  data.issueto == "Branch" ||
                  data.issueto == "Sub-Dealer" ||
                  data.issueto == "Franchise") &&
                  suggesteditems.length > 0 &&
                  suggesteditems.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          disabled
                          className="text-center"
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          disabled
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="inhandquantity"
                          className="text-center"
                          disabled
                          value={ele.inhandquantity}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="dispatchqty"
                          className="text-center"
                          value={ele.dispatchqty}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                        {errors[index] && (
                          <div className="text-danger">{errors[index]}</div>
                        )}
                      </td>
                      <td>
                        <Form.Control
                          name="rateperunit"
                          className="text-center"
                          disabled
                          value={ele.rateperunit}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="amount"
                          className="text-center"
                          disabled
                          value={
                            (ele.amount = ele.dispatchqty * ele.rateperunit)
                          }
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        {/*  {showbtn && (
                        <MdDelete
                          className="deleteIcon my-2"
                          style={{ fontSize: "25px" }}
                          onClick={() => handleTableRowDelete(index)}
                        />
                      )}*/}
                      </td>
                    </tr>
                  ))}
                {data.issueto == "Customer" &&
                  siArray.length > 0 &&
                  siArray.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          readOnly
                          //onMouseEnter={handleMouseEnter}
                          //onMouseLeave={handleMouseLeave}
                          onClick={() => {
                            setClickedItem(ele.itemid);
                            setIsModalOpen(true);
                          }}
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <RoomRackDetails
                        show={isModalOpen}
                        onClose={handleModalToggle}
                        itemid={clickedItem}
                      />
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          disabled
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="qty"
                          className="text-center"
                          disabled
                          value={ele.qty}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="dispatchqty"
                          className="text-center"
                          value={ele.dispatchqty}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                        {errors[index] && (
                          <div className="text-danger">{errors[index]}</div>
                        )}
                      </td>
                      <td>
                        <Form.Control
                          name="rate"
                          className="text-center"
                          disabled
                          value={ele.rate}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          name="amount"
                          className="text-center"
                          disabled
                          value={(ele.amount = ele.dispatchqty * ele.rate)}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-between">
              <span></span>

              <Form.Group className="d-flex">
                <Form.Label className="mt-2">Sub Total:</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  readOnly
                  value={data.subtotal}
                />
              </Form.Group>
            </div>
          </Container>
        </Form>
        <Row>
          <Col md={6}>
            <Button
              disabled={
                data.itemarray.length <= 0 ||
                data.itemarray.find((ele) => ele.dispatchqty == "")
              }
              className="commonBtn smallBtn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn mx-3"
              //onClick={handleReject}
            >
              Cancel
            </Button>
            {/* <PreviewGoods
            data={data}
            handleAcceptView={handleAccept}
            handleRejectView={handleReject}
         />*/}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Issue;
