import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Row, Table } from "react-bootstrap";
import { BiSolidFileExport } from "react-icons/bi";
import AddReceipt from "./AddReceipt";
import GlobalPagination from "../../../../constants/GlobalPagination";
import { useDispatch, useSelector } from "react-redux";
import EditReceipt from "./EditReceipt";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import {
  deleteReceipt,
  receiptPagiData,
} from "../../../../redux/actions/receipt.actions";
import { notifyWarning } from "../../../../constants/toastAlert";
import ListTableName from "../../../../constants/ListTableName";
import DeleteConfirmation from "../../../../constants/DeleteConfirmation";
import SearchField from "../../../../constants/SearchField";
import DataSpinner from "../../../../constants/DataSpinner";
import { indianFormatDate } from "../../../../constants/indianFormatDate";
import NoRecordFoud from "../../../../constants/NoRecordFoud";
import axios from "axios";

const ListReceipt = () => {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");
  const firstFinalcialYear = finalYear[0].replace('"', "");
  const secondFinalcialYear = finalYear[1].replace('"', "");

  const finalcialMonthYear = [
    `April ${firstFinalcialYear}`,
    `May ${firstFinalcialYear}`,
    `June ${firstFinalcialYear}`,
    `July ${firstFinalcialYear}`,
    `August ${firstFinalcialYear}`,
    `September ${firstFinalcialYear}`,
    `October ${firstFinalcialYear}`,
    `November ${firstFinalcialYear}`,
    `December ${firstFinalcialYear}`,
    `January ${secondFinalcialYear}`,
    `February ${secondFinalcialYear}`,
    `March ${secondFinalcialYear}`,
  ];

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState("");
  const month = selectedMonth.split(" ");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.receiptData.receiptPagiData) || [];
  const filteredData = !data.data ? [] : data.data;

  //Navigate
  const navigate = useNavigate();

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    month: month[0],
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(receiptPagiData(backendData, setLoading));
  }, [
    dispatch,
    show,
    loadData,
    pageSize,
    currentPage,
    searchKeyword,
    selectedMonth,
  ]);

  function handleExport() {
    if (filteredData.length == 0) {
      return notifyWarning("No Receipt Record to Export");
    } else {
      const Receipt = filteredData.map((item, ind) => {
        const {
          date,
          receiptid,
          prefno,
          receivefrom,
          receivemode,
          receiveto,
          amount,
        } = item;

        return {
          DATE: date,
          "RECEIPT NO.": receiptid,
          "REFERENCE NO./CHEQUE NO.": prefno,
          "RECEIVE FROM": receivefrom,
          "RECEIVE MODE": receivemode,
          "RECEIVE TO": receiveto,
          AMOUNT: amount,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(Receipt);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Receipt");
      XLSX.writeFile(workbook, "Receipt.xlsx");
    }
  }

  const handleDelete = () => {
    dispatch(
      deleteReceipt(
        {
          id: selectedItemId,
          receiptid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  const handleShow = (item) => {
    const { id, receiptid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(receiptid);
  };
  const handleClose = () => {
    setShow(false);
    // dispatch(reloadPage(true));
  };
  const handleEditModal = (item) => {
    setShowEditModal(true);
    setEditItem(item);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditItem(null);
    // handleLoadPage();
    // dispatch(reloadPage(true));
  };
  // const handleLoadPage = () => {
  //   setLoadUseEffect(!loadUseEffect);
  // };

  const handleMonthSelect = (e) => {
    setSelectedMonth(e.target.value);
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Transaction",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);


  // crud functionality to show and hide create, edit, delete icons end


  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={4}>
            <ListTableName name="Receipt" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Month</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={selectedMonth}
                onChange={handleMonthSelect}
              >
                <option value="">All</option>
                {finalcialMonthYear.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>{crud && crud.includes("c") && <AddReceipt />}</Col>
          <Col lg={1}>
            <Button
              style={{ boxShadow: "none" }}
              className="commonBtn mt-2 cancelBtn"
              onClick={handleExport}
            >
              <span>
                <BiSolidFileExport />
              </span>
              Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Receipt No.</th>
              <th>Reference No./ Cheque No.</th>
              <th>Receive From</th>
              <th>Receive Mode</th>
              <th>Receive To</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="8" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  date,
                  receiptid,
                  prefno,
                  receivefrom,
                  receivemode,
                  receiveto,
                  amount,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!date ? "--" : indianFormatDate(date)}</td>
                    <td>{!receiptid ? "--" : receiptid}</td>
                    <td>{!prefno ? "--" : prefno}</td>
                    <td>{!receivefrom ? "--" : receivefrom}</td>
                    <td>{!receivemode ? "--" : receivemode}</td>
                    <td>{receivemode == "Cash" ? receiveto : receiveto}</td>
                    <td>{!amount ? "--" : amount}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <span>
                        {crud && crud.includes("u") && (
                          <EditReceipt
                            showEditModal={showEditModal}
                            handleEditModal={() => handleEditModal(item)}
                            handleCloseEditModal={handleCloseEditModal}
                            editItem={editItem}
                          />
                        )}
                      </span>
                      <span>
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="8" name="Receipt" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListReceipt;
