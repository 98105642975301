import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong, FaFileExport } from "react-icons/fa6";
import { HiViewColumns } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reloadPage } from "../../../../redux/actions/load.action";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const ListTable = () => {
  const pdfRef = useRef(null);

  const navigate = useNavigate();
  const loadData = useSelector((state) => state.loadReducer);
  const dispatch = useDispatch();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
    let userID = localData.userid;
    let branchID = localData.branchid;
    let companyID = localData.companyid;
    let org = localData.org;
    let financialyear= localStorage.financialyear;
    // const financialyear = JSON.parse(financialyearString);

 // for by default date as a month this is connected to bellow date wise filtering functions
 const today = new Date();
 const formatDate = (date) => {
   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
     2,
     "0"
   )}-${String(date.getDate()).padStart(2, "0")}`;
 };
 const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
 const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
 const formattedStartOfMonth = formatDate(monthStart);
 const formattedEndOfMonth = formatDate(monthEnd);


  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [mtData, setMtData] = useState([]);
  const [pmData, setPmData] = useState([]);
  const [prData, setPrData] = useState([]);

  const columnData = [
    { name: "date", label: "Date" },
    { name: "ledger", label: "Particulars", default: true },
    { name: "transactiontype", label: "Transaction Type" },
    { name: "transactionno", label: "Transaction No." },
    { name: "referenceno", label: "Reference No." },
    { name: "debit", label: "Debit" },
    { name: "credit", label: "Credit" },
    { name: "balance", label: "Balance" },
    // { name: "Action", label: "Action", default: true },
  ];

  useEffect(() => {
    setLoading(true);
    
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/report/cashbook`, {

        companyid: companyID,
        org,
        financialyear,
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((res) => {
        setData(res.data.message ? [] : res.data);
        setLoading(false);
        dispatch(reloadPage(false));
        //filterData1();
      })
      .catch((err) => console.log(err));
  }, [fromDate, toDate]);

  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 31);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  // const today = new Date();
  // var dd = String(today.getDate()).padStart(2, "0");
  // var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  // var yyyy = today.getFullYear();
  // var formattedTodayDate = dd + "-" + mm + "-" + yyyy;

  // const formatDate = (date) => {
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(date.getDate()).padStart(2, "0")}`;
  // };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);

  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  useEffect(() => {
    // dispatch(reloadPage(true));
    handleThisMonth();
  }, []);

  const filterData1 = () => {
    const filtered = data.filter((item) => {
      // Check if any column contains the search value
      const containsSearchValue = Object.values(item).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue.toLowerCase().trim())
      );

      return containsSearchValue;
    });

    setFilteredData(filtered);
  };

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });

  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleLeftArrow = () => {
    navigate("/reports");
    return null;
  };

  // useEffect(async () => {
  //   try {
  //     setLoading(true);

  //     const [res1, res2, res3] = await Promise.all([
  //       axios.post("http://localhost:8081/maintenancepayment/get", {
  //         org: org,
  //         userid: userId,
  //         siteid: siteId,
  //       }),
  //       axios.post("http://localhost:8081/paymentmade/getallpaymentmade", {
  //         org: org,
  //         userid: userId,
  //         siteid: siteId,
  //       }),
  //       axios.post("http://localhost:8081/customerpayment/get", {
  //         org: org,
  //         userid: userId,
  //         siteid: siteId,
  //       }),
  //     ]);

  //     setMtData(res1.data.length > 0 ? res1.data : []);
  //     setPmData(res2.data.length > 0 ? res2.data : []);
  //     setPrData(res3.data.length > 0 ? res3.data : []);

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // Handle error state or display error message to user
  //   }
  // }, []);

  const handleViewAction = (item) => {
    if (item.transactiontype == "Employee Advance") {
      navigate(`/employeeManagement/advance`);
    } else if (item.transactiontype == "Salary Payment") {
      navigate(`/employeeManagement/salaryPayment`);
    } else if (item.transactiontype == "Maintenance Payment") {
      const maintenanceData =
        mtData && mtData.find((ele) => ele.paymentno == item.tno);
      navigate(`/equipment/maintenance/viewPayment`, {
        state: { addedData: maintenanceData },
      });
    } else if (item.transactiontype == "Payment Made") {
      const paymentMadeData =
        pmData && pmData.find((ele) => ele.paymentid == item.tno);
      navigate(`/purchase/paymentsMade/addPaymentMade/bPView`, {
        state: { addedData: paymentMadeData },
      });
    } else if (item.transactiontype == "Payment Receive") {
      const paymentreceiveData =
        prData && prData.find((ele) => ele.paymentid == item.tno);
      navigate(`/sales/viewPaymentReceive/${paymentreceiveData.id}`);
    }
  };

  // downnload as a pdf

  // const handleDownload = () => {
  //   if (pdfRef.current) {
  //     const element = pdfRef.current;
  //     const options = {
  //       margin: 10,
  //       filename: "cashbook.pdf",
  //       image: { type: "jpeg", quality: 2.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };

  //     html2pdf(element, options);
  //   }
  // };

  const handleDownload = () => {
    const element = pdfRef.current;
    const options = {
        margin: 10,
        filename: "Cashbook Report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const content = `
    <div style="font-family: Arial, sans-serif;">
    <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
      List of All <span style="color: #ffa000;">Cashbook Report</span>
    </p>
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="background-color: #f0f0f0;">
        <th style="border: 1px solid black; padding: 6px;">Date</th>
        <th style="border: 1px solid black; padding: 6px;">Particulars</th>
        <th style="border: 1px solid black; padding: 6px;">Transaction Type</th>
        <th style="border: 1px solid black; padding: 6px;">Transaction No</th>
        <th style="border: 1px solid black; padding: 6px;">Reference No</th>
        <th style="border: 1px solid black; padding: 6px;">Debit</th>
        <th style="border: 1px solid black; padding: 6px;">Credit</th>
        <th style="border: 1px solid black; padding: 6px;">Balance</th>


        
        </tr>
      </thead>
      <tbody>
      ${data&&data
        .map(
          (item, index) => `
        <tr key=${index}>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.date
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.ledger
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.transactiontype
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.transactionno
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.referenceno
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.debit
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.credit
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.balance
          }</td>
        </tr>
      `
        )
        .join("")}
    </tbody>
    </table>
  </div>
  `;
    //html2pdf(element, options);
    html2pdf().from(content).set(options).save();
};

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />
            </p>
          </Col>
          <Col lg={2} className="undefined">
            <div className="inputFieldIcon">
              <FormControl
                type="text"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="basic-addon2"
                className="mt-2"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span className="iconPosition">
                <BiSearch />
              </span>
            </div>
          </Col>
          {/* <Col lg={2}>
            <Button
              className="commonBtn shadow-none cancelBtn mt-2"
              //onClick={handleExport}
            >
              <span>
                <FaFileExport />
              </span>{" "}
              Export
            </Button>
          </Col> */}
          <Col md={1} className="d-flex gap-3">
            <span>
              {/* <ReactHTMLTableToExcel
                id="exportButton"
                className="commonBtn smallBtn cancelBtn mt-2"
                table="table-to-export"
                filename={`Cashbook-${formatDateToDDMMYYYY(
                  fromDate
                )} - ${formatDateToDDMMYYYY(toDate)}`}
                sheet="trial_report"
                buttonText={
                  <>
                    <BiSolidFileExport /> Export
                  </>
                }
              /> */}


<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

            </span>
            <span>
              <Button
                className="commonBtn smallBtn cancelBtn mt-2"
                onClick={handleDownload}
              >
                Save pdf
              </Button>
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center table-wrapper-container">
          <Col lg={3}>
            <Form.Label className="section-title form-section ptag">
              Cash Book
            </Form.Label>
          </Col>
        </Row>
        <Row className="justify-content-center table-wrapper-container">
          <Col style={{ flex:" 0 0 auto", width: "27.33333333%"}}>
            <Form.Label className="form-section ptag text-align-center ">
              {`From ${fromDate ? fromDate : ""} to ${toDate ? toDate : ""}`}
            </Form.Label>
          </Col>
        </Row>
        <Row className="justify-content-center table-wrapper-container">
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Range</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={selectedRange}
                onChange={handleSelectedRange}
                // disabled={!isCurrentYear}
              >
                <option disabled={!isCurrentYear} value="Today">
                  Today
                </option>
                <option disabled={!isCurrentYear} value="Yesterday">
                  Yesterday
                </option>
                <option disabled={!isCurrentYear} value="This Week">
                  This Week
                </option>
                <option disabled={!isCurrentYear} value="This Month">
                  This Month
                </option>
                <option disabled={!isCurrentYear} value="This Quarter">
                  This Quarter
                </option>
                <option disabled={!isCurrentYear} value="This Year">
                  This Year
                </option>
                {/* <option value="Date Range">Date Range</option> */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formBasicEmail">
              <Form.Label className="mt-3 text-color">From</Form.Label>
              <Form.Control
                disabled={selectedRange != "Date Range"}
                className="mt-2 p-2"
                type="date"
                name="from"
                value={fromDate}
                onChange={handleFromDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              <Form.Control.Feedback type="invalid">
                Select Date
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formBasicEmail">
              <Form.Label className="mt-3 text-color">To</Form.Label>
              <Form.Control
                disabled={selectedRange != "Date Range"}
                className="mt-2 p-2"
                type="date"
                name="to"
                value={toDate}
                onChange={handleToDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              <Form.Control.Feedback type="invalid">
                Select Date
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group className="d-flex gap-2" controlId="formBasicEmail">
              <Form.Label className="mt-3 text-color">Select Column</Form.Label>
              <span>
                <HiViewColumns
                  onClick={handleShow}
                  className="column-select-icon"
                />
              </span>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="table-main-container">
            <Table id="table-to-export" responsive ref={pdfRef}>
              <thead className="tableContainer">
                <tr>
                  {columnData.map(
                    (column) =>
                      columnVisibility[column.name] && (
                        <th key={column.index}>{column.label}</th>
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  <tr>
                    <td>As on {formatDateToDDMMYYYY(fromDate)}</td>
                    <td>Opening Balance</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>0.00</td>
                    <td></td>
                    <td>0.00</td>
                    <td></td>
                  </tr>
                ) : (
                  []
                )}
                {loadData || loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <div
                        className="spinner-border spinner-border-md"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </td>
                  </tr>
                ) : data.length > 0 ? (
                  data.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                      {columnData.map(
                        (column) =>
                          columnVisibility[column.name] && (
                            <React.Fragment key={column.index}>
                              {column.name === "Action" ? (
                                <td key={column.index}>
                                  {/* Eye icon for viewing */}
                                  <span onClick={() => handleViewAction(item)}>
                                    <AiFillEye className="editIcon" />
                                  </span>
                                </td>
                              ) : (
                                <td key={column.index}>
                                  {column.name.toLowerCase() == "date"
                                    ? item[column.name.toLowerCase()]
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("-")
                                    : column.name.toLowerCase() == "debit" &&
                                      item[column.name.toLowerCase()] != ""
                                    ? `${item[column.name.toLowerCase()]} Dr`
                                    : column.name.toLowerCase() == "credit" &&
                                      item[column.name.toLowerCase()] != ""
                                    ? `${item[column.name.toLowerCase()]} Cr`
                                    : item[column.name.toLowerCase()]}
                                </td>
                              )}
                            </React.Fragment>
                          )
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columnData.length}>
                      <div className="datanotfound-center-text">
                        No Record Found!! 🤔
                      </div>
                    </td>
                  </tr>
                )}
                {data.length > 0 ? (
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {data
                        .reduce((acc, item) => acc + Number(item.debit), 0)
                        .toFixed(2)}{" "}
                      Dr
                    </td>
                    <td>
                      {data
                        .reduce((acc, item) => acc + Number(item.credit), 0)
                        .toFixed(2)}{" "}
                      Cr
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  []
                )}
                {data.length > 0 ? (
                  <tr>
                    <td>As on Date {formatDateToDDMMYYYY(toDate)}</td>
                    <td>Closing Balance</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>

                    </td>
                    <td>
                      {(
                        data.reduce((acc, item) => acc + Number(item.balance), 0)
                      ).toFixed(2)}
                      
                    </td>
                    <td></td>
                  </tr>
                ) : (
                  []
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {/*  //---------------Modal-----------------------------// */}
        <Modal
          show={show}
          size="sm"
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
          centered={true}
        >
          <Modal.Header closeButton>
            <h4>Select Column</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {columnData.map((column) => (
                  <div key={column.index} className="mb-3">
                    {column.default ? (
                      <span>{column.label}</span>
                    ) : (
                      <Form.Check
                        type="checkbox"
                        id={column.name}
                        label={column.label}
                        checked={columnVisibility[column.name]}
                        onChange={() => handleCheckboxChange(column.name)}
                      />
                    )}
                  </div>
                ))}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
};

export default ListTable;
