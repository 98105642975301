import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import SearchField from "../../../../constants/SearchField";
import GlobalPagination from "../../../../constants/GlobalPagination";
import DeleteConfirmation from "../../../../constants/DeleteConfirmation";
import {
  branchPagiData,
  branchStatusChange,
  deleteBranch,
} from "../../../../redux/actions/branch.action";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ViewBranch from "./ViewBranch";
import NoRecordFoud from "../../../../constants/NoRecordFoud";
import DataSpinner from "../../../../constants/DataSpinner";
import axios from "axios";
function ListTableBranches() {
  //navigate
  const navigate = useNavigate();

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Tooltip
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.branchData.branchPagiData);
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    companyid: companyid,
    branchid: branchid,
    company: companyname,
    userid: userid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(branchPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

        userid: userid,
        submodule: "Branch",
        companyid: companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);
  // crud functionality to show and hide create, edit, delete icons end

  //edit
  const handleEdit = (id) => {
    navigate(`/admin/branch/editBranch/${id}`);
  };

  //delete logic start
  const handleShow = (item) => {
    const { id, branchid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(branchid);
  };

  const handleDelete = () => {
    dispatch(
      deleteBranch(
        {
          id: selectedItemId,
          branchid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  const handleSwitchChange = (e, id, status) => {
    dispatch(branchStatusChange({ id: id, status: status }));
  };

  const handleClose = () => {
    setShow(false);
  };
  //delete logic end
  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Branches</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() => navigate("/admin/branch/addBranch")}
              >
                Add Branch
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Branch ID</th>
              <th>Branch Name</th>
              <th>Company Name</th>
              <th>Head Office Address</th>
              <th>GSTIN</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="8" />
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  branchid,
                  branchname,
                  description,
                  city,
                  state,
                  country,
                  userid,
                  company,
                  address,
                  gstno,
                  status,
                  id,
                } = item;
                return (
                  <tr key={index}>
                    <td>{branchid}</td>
                    <td>{branchname}</td>
                    <td>{company}</td>
                    <td>{address}</td>
                    <td>{gstno}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip("Active/Inactive")}
                      >
                        <Form.Check
                          type="switch"
                          // id="custom-switch"
                          defaultChecked={status === "active"}
                          // className="custom-switch"
                          onChange={(e) =>
                            handleSwitchChange(
                              e,
                              id,
                              status == "active" ? "inactive" : "active"
                            )
                          }
                        />
                      </OverlayTrigger>
                      <ViewBranch item={item} />
                      {crud && crud.includes("u") && (
                        <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(item.id)}
                        />
                      )}
                      {crud && crud.includes("d") && (
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={handleClose}
                          handleDelete={handleDelete}
                          name="Branch"
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="7" name="Branch" />
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
}

export default ListTableBranches;
