import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import TableHeading from "../../../constants/TableHeading";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import axios from "axios";

const ViewGoods = ({ data1 }) => {
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  // const [data1, setdata1] = useState({});
  const [viewtable, setViewTable] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    axios
      .post("https://erp.venturesathi.co.in/api/v1/receivegoods/getitemroom", {
        grn: data1.grn,
        userid: data1.userid,
        branchid: data1.branchid,
        companyid: data1.companyid,
      })
      .then((res) => {
        setViewTable(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=1000,height=1000");

    const modalContent = containerRef.current.innerHTML;

    printWindow.document.open();
    printWindow.document.write(`
                <html>
                <head>
                <link rel="stylesheet" type="text/css" href="ReceiveGoods.css" media="print">
                <style>
                body {
                    font-family: Arial, sans-serif;
                    font-size:1.2rem
                }
    
                .modalHeader{
                    display:flex;
                    justify-content:space-between;
                    padding-bottom:0.5rem;
                    border-bottom:2px solid grey;
                    width:100%;
                }
    
                .form-title {
                    font-size:2.5rem;
                    color: rgba(118, 74, 240, 0.81);
                }
    
                .active-status {
                    color: green;
                }
    
                .inactive-status {
                    color: red;
                }
    
                .viewpage-title {
                    display: inline-block;
                    width: 160px;
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin-right: 3rem;
                }
                  
                .viewpage-value {
                    font-size: 1rem;
                    font-weight: 500;
                }
    
                .modalBody{
                    display:flex;
                    justify-content:space-between;
                    border-bottom:2px solid grey;
                    padding-bottom:0.8rem;
                }
                .modalColumn{
                    display:flex;
                }
    
                .printTittle{
                    margin-bottom: -0.2rem;
                }
    
                .printTittle:last-child{
                    // margin-bottom: 1rem;
                }
    
                .printTittle>.viewpage-title{
                    display: inline-block;
                    width: 150px;
                    font-size: 1.15rem;
                    font-weight: 500;
                }
    
                .printTittle .viewpage-value{
                    font-size: 1.05rem;
                }
    
                .btn-close{
                    display:none
                }
    
                hr{
                    display: none;
                }
    
                // .tableContainer {
                //     --bs-table-bg: #eaecf0;
                // }
    
                table{
                    width:100%;
                    text-align: center;
                }
                
                thead {
                    background-color: #2E4374;
                    color: #F5F7F8;
                }
    
                th{
                  padding: 0.5rem 0.5rem;
                  border: 0.5px solid #d0d5dd;
                  word-wrap: break-word;
                }
    
                tbody{
                    padding: 1rem;
                    background:#F5F7F8;
                    font-size:1rem;
                    font-weight:normal
                }
    
                tbody tr{
                    border-bottom:3px solid grey;
                }
    
                tbody tr td {
                  padding: 0.5rem 0rem;
                  word-wrap: break-word;
                }
    
                .totalprint{
                    width:100%;
                    display:flex;
                    justify-content:flex-end;
                    margin-top:1rem;
                }
    
                .totalprint label{
                    margin-top:0.8rem;
                    margin-right:1.3rem;
                }
    
                .totalprint input{
                  font-size:1.6rem;
                  text-align:center;
                }
                </style>
                <title>Print</title>
                </head>
                <body>
            `);
    printWindow.document.write(modalContent);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();

    // Print and close the print window
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
      <AiFillEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-between m-2 w-100">
            <div className="form-title m-0 gayab">Summary</div>
            <div
              style={{
                width: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="gayab"
            >
              <span className="viewpage-title gayab">Status:</span> &nbsp;&nbsp;
              <span
                className={
                  data1.status === "Accepted"
                    ? "active-status gayab viewpage-value"
                    : "orange-status gayab viewpage-value"
                }
                style={{ color: data1.status === "Accepted" ? "green" : "red" }}
              >
                {data1.status}
              </span>
            </div>
            <div>
              <Button
                className="commonBtn smallBtn gayab"
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container ref={containerRef}>
            <Row className="modalBody">
              <Col>
                <Row className="modalColumn">
                  <Col className="viewpage-title">GRN No:</Col>{" "}
                  <Col className="viewpage-value">{data1.grn}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Date:</Col>{" "}
                  <Col className="viewpage-value">
                    {data1.date ? indianFormatDate(data1.date) : "--"}
                  </Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Receive From:</Col>{" "}
                  <Col className="viewpage-value">{data1.receivedfrom}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Vehicle Type:</Col>{" "}
                  <Col className="viewpage-value">{data1.vehicletype}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Received By:</Col>{" "}
                  <Col className="viewpage-value">{data1.receivedby}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Remarks:</Col>{" "}
                  <Col className="viewpage-value">{data1.remarks}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Attachment:</Col>{" "}
                  <Col className="viewpage-value">{data1.challan}</Col>
                </Row>
              </Col>
              <Col>
                <Row
                  className={
                    data1.receivedfrom != "Return From Customer"
                      ? "deactivated_icons"
                      : "modalColumn"
                  }
                >
                  <Col className="viewpage-title">Credit Note No:</Col>{" "}
                  <Col className="viewpage-value">{}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">
                    {data1.receivedfrom != "Return From Customer" &&
                    data1.receivedtype == "Invoice"
                      ? "Invoice No"
                      : "Challan No"}
                  </Col>{" "}
                  <Col className="viewpage-value">
                    {" "}
                    {data1.receivedfrom != "Return From Customer" &&
                    data1.receivedtype == "Invoice"
                      ? data1.invoiceno
                      : data1.challanno}
                  </Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">
                    {data1.receivedfrom != "Return From Customer" &&
                    data1.receivedtype == "Invoice"
                      ? "Invoice Date"
                      : "Challan Date"}
                    :
                  </Col>{" "}
                  <Col className="viewpage-value">
                    {data1.receivedfrom != "Return From Customer" &&
                    data1.receivedtype == "Invoice"
                      ? data1.invoicedate
                        ? indianFormatDate(data1.invoicedate)
                        : "--"
                      : data1.challandate
                      ? indianFormatDate(data1.challandate)
                      : "--"}
                  </Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">
                    {data1.receivedfrom == "Vendor"
                      ? "Vendor"
                      : data1.receivedfrom == "Branch"
                      ? "Branch"
                      : data1.receivedfrom == "Company"
                      ? "Company"
                      : "Customer Name"}
                  </Col>{" "}
                  <Col className="viewpage-value">{data1.receive}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Vehicle No:</Col>{" "}
                  <Col className="viewpage-value">{data1.vehicleno}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">Checked By:</Col>{" "}
                  <Col className="viewpage-value">{data1.checkedby}</Col>
                </Row>
                <Row className="modalColumn">
                  <Col className="viewpage-title">E-Way Bill:</Col>{" "}
                  <Col className="viewpage-value">{data1.ewaybill}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Table className="table-main-container" responsive>
              <TableHeading
                data={[
                  "SL No",
                  "Item Name",
                  "Item ID",
                  "Category",
                  "Received Qty",
                  "Defective",
                  "Shortage",
                  "Final Qty",
                  "Warehouse",
                  "Room No",
                  "Rack Details",
                ]}
              />
              {viewtable.length > 0 ? (
                <tbody>
                  {viewtable.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.itemname}</td>
                      <td>{item.itemid}</td>
                      <td>{item.category}</td>
                      <td>{item.qty}</td>
                      <td>{item.defective}</td>
                      <td>{item.shortage}</td>
                      <td>{item.finalqty}</td>
                      <td>{item.warehouse}</td>
                      <td>{item.roomno}</td>
                      <td>{item.rakeno}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="5">
                      <div className="data1notfound-center-text">
                        No Record Found!! 🤔
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            <div className="d-flex justify-content-end totalprint">
              <Form.Group className="d-flex gap-2">
                <Form.Label className="mt-2">Total</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  readOnly
                  value={data1.total}
                />
              </Form.Group>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewGoods;
