import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { BiSolidFileImport } from "react-icons/bi";
import AddContraForm from "./AddContraForm";
import GlobalPagination from "../../../../constants/GlobalPagination";
import EditContraForm from "./EditContraForm";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import SearchField from "../../../../constants/SearchField";
import DataSpinner from "../../../../constants/DataSpinner";
import { indianFormatDate } from "../../../../constants/indianFormatDate";
import DeleteConfirmation from "../../../../constants/DeleteConfirmation";
import NoRecordFoud from "../../../../constants/NoRecordFoud";
import ListTableName from "../../../../constants/ListTableName";
import {
  contraEntryPagiData,
  deleteContraEntry,
} from "../../../../redux/actions/contraEntry.actions";
import { notifyWarning } from "../../../../constants/toastAlert";
import axios from "axios";

const ListTableContraEntry = () => {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");
  const firstFinalcialYear = finalYear[0].replace('"', "");
  const secondFinalcialYear = finalYear[1].replace('"', "");

  const finalcialMonthYear = [
    `April ${firstFinalcialYear}`,
    `May ${firstFinalcialYear}`,
    `June ${firstFinalcialYear}`,
    `July ${firstFinalcialYear}`,
    `August ${firstFinalcialYear}`,
    `September ${firstFinalcialYear}`,
    `October ${firstFinalcialYear}`,
    `November ${firstFinalcialYear}`,
    `December ${firstFinalcialYear}`,
    `January ${secondFinalcialYear}`,
    `February ${secondFinalcialYear}`,
    `March ${secondFinalcialYear}`,
  ];

  //state
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const selectedMonth = month.split(" ");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data =
    useSelector((state) => state.contraEntryData.contraEntryPagiData) || [];
  const filteredData = !data.data ? [] : data.data;

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    month: selectedMonth[0],
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(contraEntryPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, month]);

  function handleExport() {
    if (filteredData.length == 0) {
      return notifyWarning("No Contra Entry Record to Export");
    } else {
      const contraEntry = filteredData.map((item, ind) => {
        const { date, contraid, prefno, contratype, amount, from, to, remark } =
          item;

        return {
          DATE: date,
          "CONTRA ID": contraid,
          "CONTRA TYPE": contratype,
          "REFERENCE NO./CHEQUE NO.": prefno,
          AMOUNT: amount,
          FROM: from,
          TO: to,
          REMARK: remark,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(contraEntry);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ContraEntry");
      XLSX.writeFile(workbook, "Contra Entry.xlsx");
    }
  }

  const handleDelete = () => {
    dispatch(
      deleteContraEntry(
        {
          id: deleteItemId,
          contraid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  const handleShow = (item) => {
    const { id, contraid } = item;
    setShow(true);
    setDeleteItemId(id);
    setSelectedItemNo(contraid);
  };

  const handleClose = () => {
    setShow(false);
    setDeleteItemId(null);
  };

  const handleEditModal = (item) => {
    setShowEditModal(true);
    setEditItem(item);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditItem({
      id: "",
      contratype: "",
      date: "",
      prefno: "",
      amount: "",
      from: "",
      to: "",
      remark: "",
    });
  };


  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);


  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Transaction",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={5}>
            <ListTableName name="Contra Entry" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Month</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="">All</option>
                {finalcialMonthYear.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>{crud && crud.includes("c") && <AddContraForm />}</Col>
          <Col lg={1}>
            <Button className="commonBtn cancelBtn mt-2" onClick={handleExport}>
              <BiSolidFileImport className="mx-1" />
              Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Contra ID</th>
              <th>Contra Type</th>
              <th>Ref. No/Cheque No</th>
              <th>Amount</th>
              <th>From</th>
              <th>To</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="9" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                let {
                  date,
                  contraid,
                  contratype,
                  prefno,
                  id,
                  amount,
                  from,
                  to,
                  remark,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!date ? "--" : indianFormatDate(date)}</td>
                    <td>{!contraid ? "--" : contraid}</td>
                    <td>{!contratype ? "--" : contratype}</td>
                    <td>{!prefno ? "--" : prefno}</td>
                    <td>{!amount ? "--" : amount}</td>
                    <td>
                      {!from
                        ? "--"
                        : contratype == "Bank To Bank" ||
                          contratype == "Cash Withdrawl"
                        ? `${from.split(",")[0]},${from.split(",")[1]}`
                        : from}
                    </td>
                    <td>
                      {!to
                        ? "--"
                        : contratype == "Bank To Bank" ||
                          contratype == "Cash Deposit"
                        ? `${to.split(",")[0]},${to.split(",")[1]}`
                        : to}
                    </td>
                    <td>{remark ? remark : "--"}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <span>
                        {crud && crud.includes("u") && (
                          <EditContraForm
                            showEditModal={showEditModal}
                            handleEditModal={() => handleEditModal(item)}
                            handleCloseEditModal={handleCloseEditModal}
                            editItem={editItem}
                          />
                        )}
                      </span>
                      <span>
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="9" name="Contra Entry" />
            )}
          </tbody>
        </Table>
      </Col>

      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableContraEntry;
