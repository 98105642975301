import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";

const EditTerm = () => {
  const { id } = useParams();
  // Fot today date
  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const formattedToday = formatDate(today);

  const URL = extractAndFormatURLPart("admin");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];

  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let org = localData.org;

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showroomorNot, setShowRoomOrNot] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [data, setData] = useState({
    date: formattedToday,
    termname: "",
    description: "",
    type: "",
    userid: userID,
    org: org,
    companyid: companyID,
    branchid: branchID,
    status: "Active",
  });
   // for react select start
   const [typeSelect, setTypeSelect] = useState([]);
   const typeselectoptions = [
     { value: "Sales Order", label: "Sales Order" },
     { value: "Sales Invoice", label: "Sales Invoice" },
     { value: "Delivery Receipt", label: "Delivery Receipt" },
     { value: "Purchase Order", label: "Purchase Order" },
     { value: "Credit Note", label: "Credit Note" },
   ];
   const handleTypeSelectChange = (data) => {
     setTypeSelect(data);
   };
   // for react select end

  //   Text editor using draft-js
  const [editedAnswerState, setEditedAnswerState] = useState(
    EditorState.createEmpty()
  );
  const contentState = editedAnswerState.getCurrentContent();

  const description = JSON.stringify(convertToRaw(contentState));

  // Get by id API calling
  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/termcondition/getbyid`, { id })

      .then((res) => {
        setData({
          date: formattedToday,
          termname: res.data[0].termname,
          description: res.data[0].description,
          type: res.data[0].type,
          userid: userID,
          org: org,
          companyid: companyID,
          branchid: branchID,
          status: "Active",
        });
       setTypeSelect({ value: res.data[0].type, label: res.data[0].type });

        setEditedAnswerState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(res.data[0].description))
          )
        );
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleLeftArrow = () => {
    navigate("/admin/termcondition");
  };

  const handleUpdate = async () => {
    if (data.termname == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    try {
      const res = await axios.post(

        `https://erp.venturesathi.co.in/api/v1/termcondition/update`,

        { date: data.date, termname: data.termname, description, type:typeSelect.value, id, companyid: companyID }
      );
      if (res.data.message == "Term Condition updated successfully") {
        notifySuccess("Term & Condition Updated");
        handleLeftArrow();
      } else {
        notifyWarning(res.data.message);
      }
      dispatch(reloadPage(true));
      //   setData({
      //     date: formattedToday,
      //     termname: "",
      //     description: "",
      //     userid: userID,
      //     org: org,
      //     companyid: companyID,
      //     branchid: branchID,
      //     status: "Active",
      //   });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, id } = e.target;
    const checked = e.target.checked;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Update <span className="form-title main"> Term & Condition</span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row lg={2} md={1} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Date<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="ID"
                        name="date"
                        value={data.date}
                        onChange={handleChange}
                        isInvalid={data.userid === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Date
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        User ID<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="ID"
                        name="userid"
                        value={data.userid}
                        onChange={handleChange}
                        isInvalid={data.userid === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter User ID
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                
                </Row>

                <Row lg={2} md={1} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Type<span className="star"> *</span>
                      </Form.Label>
                      <Select
                        className="p-2 w-100"
                        options={typeselectoptions}
                        onChange={handleTypeSelectChange}
                        value={typeSelect}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            fontSize: "16px",
                          }),

                          option: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999, // Adjust the z-index value as needed
                          }),
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Date
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Term Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        autoComplete="Termname"
                        type="text"
                        placeholder="Enter Name"
                        name="termname"
                        value={data.termname}
                        onChange={handleChange}
                        isInvalid={data.name === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Term Name
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {/* <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Description <span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={data.description}
                        onChange={handleChange}
                        isInvalid={data.name === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Term Description
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col> */}

                  <Col
                    style={{
                      border: "solid 1px gray",
                      padding: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {/* Draft.js editor */}
                    <WysiwygEditor
                      editorState={editedAnswerState}
                      onEditorStateChange={setEditedAnswerState}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleUpdate}>
            Update
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleLeftArrow}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditTerm;
