import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useRef } from "react";

const StockVoucherView = () => {
  const pdfRef = useRef(null);
  const location = useLocation();
  const realid = location.state.realid;
  const itemid = location.state.itemid;

  const navigate = useNavigate();
  // const realid = useParams();
  const sessionData = JSON.parse(sessionStorage.getItem("userData")) || {};

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userid = localData.userid;
  let branchid = localData.branchid;
  let companyid = localData.companyid;
  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);

  const loadData = useSelector((state) => state.loadReducer);
  const [loading, setLoading] = useState(false);

  const [extractedPart, setExtractedPart] = useState("");
  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split("/"); // Split the URL by "/"
    const itemIndex = urlParts.indexOf("items"); // Find the index of "adminPanel"
    if (itemIndex !== -1 && itemIndex < urlParts.length - 1) {
      // Check if "adminPanel" is in the URL and if there's a part after it
      const desiredPart = urlParts.slice(itemIndex).join("/"); // Get the part after "adminPanel"
      const formattedPart = desiredPart
        .split("/")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" / ");
      const spacedpart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
      setExtractedPart(spacedpart); // Set the extracted part in the component's state
    } else {
      console.log("The URL does not contain 'items'");
    }
  }, []);

  const handleLeftArrow = () => {
    navigate(`/stock-report/stockReportsMonthlySummary/${realid}`);
  };
  const [searchValue, setSearchValue] = useState("");

  const [data, setData] = useState([]);
  // date range
    // for by default date as a month this is connected to bellow date wise filtering functions
    const today = new Date();
    const formatDate = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    };
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [filteredData, setFilteredData] = useState([]);
  //  const [startRangeDate, setStartRangeDate] = useState("");
  const [allDetails, setAllDetails] = useState([]);
  const [selectedRange, setSelectedRange] = useState("This Year");
  // get all items
  const getItemDetails = () => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/item/itembyid`, {

        id: realid,
        companyid,
        org:"₹ΘθϖΠ",
        financialyear:financialyear
      })
      .then((res) => {
        setAllDetails(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    getItemDetails();

    axios
      .post(`https://erp.venturesathi.co.in/api/v1/item/getstockdata`, {

        itemid: realid,
        fy: financialyear,
        companyid,
        org:"₹ΘθϖΠ",
        financialyear:financialyear,
        fromDate,
        toDate
      })
      .then((res) => {
        setData(res.data);
        
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [fromDate, toDate]);

  // Function to calculate closing balance
  const calculateClosingBalance = (receiveData, issueData) => {
    const closingBalance = {
      quantity: receiveData.qty - issueData.qty,
      rate: receiveData.rate - issueData.rate,
      amount: receiveData.amount - issueData.amount,
    };
    return closingBalance;
  };

 

  const {
    mpn,
    unit,
    paccount,
    hsndesc,
    hsncode,
    brand,
    itemname,
    category,
    oamount,
    oqty,
    orate,
  } = allDetails.length > 0 && allDetails[0];

  // debu dropdown date range filter start

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  // const today = new Date();
  // var dd = String(today.getDate()).padStart(2, "0");
  // var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  // var yyyy = today.getFullYear();
  // var formattedTodayDate1 = dd + "-" + mm + "-" + yyyy;

  // const formatDate = (date) => {
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(date.getDate()).padStart(2, "0")}`;
  // };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };



  const filterData1 = () => {
    if (!data || !data.receive || !data.issue) {
      // Data is not available yet
      return;
    }

    if (!fromDate && !toDate) {
      // No filter applied, set filteredData to the entire data
      setFilteredData(data);
      return;
    }

    // for from date
    const d = new Date(fromDate);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const newFromDate = `${day}-${month}-${year}`;

    // for to date
    const d1 = new Date(toDate);
    const day1 = String(d1.getDate()).padStart(2, "0");
    const month1 = String(d1.getMonth() + 1).padStart(2, "0");
    const year1 = d1.getFullYear();
    const newToDate = `${day1}-${month1}-${year1}`;

    const filteredReceive = data.receive.filter(
      (item) =>
        item.date >= newFromDate &&
        item.date <= newToDate
    );

    const filteredIssue = data.issue.filter(
      (item) =>
        item.gidate >=
          newFromDate &&
        item.gidate <= newToDate
    );

    const filteredData = {
      receive: filteredReceive,
      issue: filteredIssue,
    };
    
    setFilteredData(filteredData);
  };

  const parseIndianStyleDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    filterData1();
  }, [fromDate, toDate, data, searchValue]);

  // for date range end

  return (
    <>
      <Row>
        <Col className="colStyle">{extractedPart}</Col>
      </Row>
      <Container
        fluid
        style={{
          // width: "83.75rem",
          borderRadius: "24px",
          // boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.12)",
          background: "#FFF",
          paddingTop: "40px",
        }}
      >
        {/* <Row>
          <Col>
            <div className="form-container main">
              <Form className="d-flex justify-content-between">
                <p>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={handleLeftArrow}
                  />{" "}
                  <span className="form-title main">Item Details</span>
                </p>
               

                <ReactHTMLTableToExcel
                  id="exportButton"
                  className=" smallBtn3 cancelBtn  commonBtn"
                  table="table-to-export"
                  filename={`Stock Report Daily, ${formattedTodayDate1}`}
                  sheet="stock_report"
                  buttonText={
                    <>
                      {" "}
                      <BiSolidFileExport /> Export
                    </>
                  }
                />
              </Form>
            </div>
          </Col>
        </Row> */}

      <Row>
          <Col>
            {" "}
            <p>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />{" "}
              <span className="form-title main">Item Details</span>
            </p>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            {" "}
            {/* <ReactHTMLTableToExcel
              id="exportButton"
              className=" smallBtn3 cancelBtn  commonBtn"
              table="table-to-export"
              filename={`Stock Report Voucher, ${formattedTodayDate1}`}
              sheet="stock_report"
              buttonText={
                <>
                  {" "}
                  <BiSolidFileExport /> Export
                </>
              }
            /> */}

<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>
          </Col>
        </Row>


        <Row>
          <Col>
            <p>
              <span className="viewpage-title">Item No:</span>
              <span className="viewpage-title">{itemid}</span>
            </p>
            <p>
              <span className="viewpage-title">Category:</span>
              <span className="viewpage-title">{category}</span>
            </p>
            <p>
              <span className="viewpage-title">Make/Brand:</span>
              <span className="viewpage-title">{brand}</span>
            </p>
            <p>
              <span className="viewpage-title">Inventory Account:</span>
              <span className="viewpage-title">Stock</span>
            </p>
            <p>
              <span className="viewpage-title">HSN Code:</span>
              <span className="viewpage-title">{hsncode}</span>
            </p>
            <p>
              <span className="viewpage-title">HSN Description:</span>
              <span className="viewpage-title">{hsndesc}</span>
            </p>
            <p>
              <span className="viewpage-title">Cess Valuation Type:</span>
              <span className="viewpage-title">Not Applicable</span>
            </p>
          </Col>
          <Col>
            <p>
              <span className="viewpage-title">Item Name:</span>
              <span className="viewpage-title">{itemname}</span>
            </p>
            <p>
              <span className="viewpage-title">Unit:</span>
              <span className="viewpage-title">{unit}</span>
            </p>
            <p>
              <span className="viewpage-title">MPN:</span>
              <span className="viewpage-title">{mpn}</span>
            </p>
            <p>
              <span className="viewpage-title">Purchase Account:</span>
              <span className="viewpage-title">{paccount}</span>
            </p>
          </Col>
        </Row>
        <br />
        <Container
          fluid
          style={{
            // width: "83.75rem",
            borderRadius: "24px",
            // boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.12)",
            background: "#FFF",
            paddingTop: "10px",
            boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Row>
            <Col>
            <Form.Label className="mt-3 text-color">Stock Voucher</Form.Label>
              {/* <p className="text-color">
                <span className="text-color">Stock Voucher</span>
              </p> */}
            </Col>

            <Col>
              <Form.Group className="d-flex gap-2" controlId="formMonth">
                <Form.Label className="mt-3 text-color">Range</Form.Label>

                <Form.Select
                  className="mt-2 p-2"
                  value={selectedRange}
                  onChange={handleSelectedRange}
                >
                  <option value="Today">Today</option>
                  <option value="Yesterday">Yesterday</option>
                  <option value="This Week">This Week</option>
                  <option value="This Month">This Month</option>
                  <option value="This Quarter">This Quarter</option>
                  <option value="This Year">This Year</option>
                  <option value="Date Range">Date Range</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="d-flex gap-2" controlId="formFromDate">
                <Form.Label className="mt-3 text-color">From</Form.Label>
                <Form.Control
                  disabled={selectedRange != "Date Range"}
                  type="date"
                  className="mt-2"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex gap-2" controlId="formToDate">
                <Form.Label className="mt-3 text-color">To</Form.Label>
                <Form.Control
                  disabled={selectedRange != "Date Range"}
                  type="date"
                  className="mt-2"
                  value={toDate}
                  onChange={handleToDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Form.Group>
            </Col>
            {/* <Col>
      <Button className="filterBtn smallBtn" onClick={() => filterData(fromDate, toDate)}>
          Filter
        </Button>
      </Col> */}

            {/* <Col>
              <Form.Label className="mt-3 text-color">
                Opening Balance
              </Form.Label>
            </Col> */}
            {/* <Col>
              <Form.Group className="d-flex gap-2" controlId="formMonth">
                <Form.Label className="mt-3 text-color">Qty</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  className="mt-2"
                  value={oqty}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex gap-2" controlId="formMonth">
                <Form.Label className="mt-3 text-color">Amount</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  className="mt-2"
                  value={oamount}
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md={12}  className="mt-3 table-main-container">
              <Table id="table-to-export" responsive ref={pdfRef}>
                <thead className="tableContainer">
                  <tr>
                    <th colSpan={2}>Date</th>
                    <th colSpan={2}>Particular</th>
                    <th colSpan={2}>Type</th>
                    <th colSpan={3}>Receive</th>
                    <th colSpan={3}>Issue</th>
                    <th colSpan={3}>Balance</th>
                  </tr>
                  <tr>
                    <th colSpan={2}></th>
                    <th colSpan={2}></th>
                    <th colSpan={2}></th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {loadData || loading ? (
                <tr>
                  <td colSpan="15" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : (!data.receive ||
                    data.receive.length === 0) &&
                    (!data.issue ||
                      data.issue.length === 0) && (
                      <tr>
                        <td colSpan="15">
                          <div className="datanotfound-center-text">
                            No Record Found!! 🤔
                          </div>
                        </td>
                      </tr>
                    )}

                  {data.receive
                    ? data.receive.map((receiveItem, index) => (
                        <tr key={index}>
                          <td colSpan={2}>{receiveItem.date}</td>
                          <td colSpan={2}>{receiveItem.invoiceno}</td>
                          <td colSpan={2}>Purchase</td>
                          {/* Receive data */}
                          <td>{receiveItem.qty}</td>
                          <td>{receiveItem.rate}</td>
                          <td>{receiveItem.amount}</td>
                          {/* Issue data */}
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          {/* Closing balance data */}
                          <td>{receiveItem.bqty}</td>
                          <td>{receiveItem.brate}</td>
                          <td>{receiveItem.bamount}</td>
                        </tr>
                      ))
                    : []}

                  {data.issue
                    ? data.issue.map((issueItem, index) => (
                        <tr key={index}>
                          <td colSpan={2}>{issueItem.gidate}</td>
                          <td colSpan={2}>{issueItem.specify}</td>
                          <td colSpan={2}>Issue</td>
                          {/* Receive data */}
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          {/* Issue data */}

                          <td>{issueItem.qty}</td>
                          <td>{issueItem.rate}</td>
                          <td>{issueItem.amount}</td>
                          {/* Closing balance data */}
                          <td>{issueItem.bqty}</td>
                          <td>{issueItem.brate}</td>
                          <td>{issueItem.bamount}</td>
                        </tr>
                      ))
                    : []}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default StockVoucherView;
