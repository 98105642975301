import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalPagination from "../../../constants/GlobalPagination";
import { useDispatch, useSelector } from "react-redux";
import ViewBank from "./ViewBank";
import { MdEdit } from "react-icons/md";
import ListTableName from "../../../constants/ListTableName";
import SearchField from "../../../constants/SearchField";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { bankPagiData, deleteBank } from "../../../redux/actions/bank.actions";
import axios from "axios";

const ListTableBank = () => {
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.bankData.bankPagiData) || [];
  const filteredData = !data.data ? [] : data.data;

  //Navigate
  const navigate = useNavigate();

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(bankPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleShow = (item) => {
    const { id, bankid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(bankid);
  };

  const handleClose = () => {
    setShow(false);
    // dispatch(reloadPage(true));
  };

  const handleDelete = () => {
    dispatch(
      deleteBank(
        {
          id: selectedItemId,
          bankid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  const handleAddBank = () => {
    navigate(`/banking/addbank`);
  };

  const handleEditBank = (id) => {
    navigate(`/banking/editBank/${id}`);
  };


  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Bank",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end


  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <ListTableName name="Banks" />
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button className="commonBtn mt-2" onClick={handleAddBank}>
                Add Bank
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Bank ID</th>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Bank Branch</th>
              <th>IFSC Code</th>
              <th>Account Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="7" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  bankid,
                  bank,
                  accountnumber,
                  branchname,
                  ifsccode,
                  accounttype,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!bankid ? "--" : bankid}</td>
                    <td>{!bank ? "--" : bank}</td>
                    <td>{!accountnumber ? "--" : accountnumber}</td>
                    <td>{!branchname ? "--" : branchname}</td>
                    <td>{!ifsccode ? "--" : ifsccode}</td>
                    <td>{!accounttype ? "--" : accounttype}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <span>
                        <ViewBank item={item} />
                      </span>
                      <span>
                        {crud && crud.includes("u") && (
                          <MdEdit
                            className="editIcon"
                            onClick={() => handleEditBank(id)}
                          />
                        )}
                      </span>
                      <span>
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="7" name="Bank" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableBank;
