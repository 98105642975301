import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import { customerAddPost, customerIDCreatePost } from "../../../constants/api";
import axios from "axios";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import { FcCheckmark } from "react-icons/fc";
import {
  validateBankAccount,
  validateGST,
  validateIFSC,
  validatePan,
  validatePhone,
  validatePin,
} from "../../../constants/validationOnBlur";
import { customerPagiData } from "../../../redux/actions/customer.actions";
import { useDispatch, useSelector } from "react-redux";
import { tcsData } from "../../../redux/actions/tcs.actions";
import { tdsData } from "../../../redux/actions/tds.actions";

export const addressStyle = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
};

const states = State.getStatesOfCountry("IN");

const AddCustomerInSO = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let companyName = localData.companyname;
  let branchID = localData.branchid;
  let branchName = localData.branch;
  const loadData = useSelector((state) => state.loadReducer);
  const dispatch = useDispatch();
  const tdsMaster = useSelector((state) => state.tdsData.tdsData.data);
  const tcsMaster = useSelector((state) => state.tcsData.tcsData.data);

  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state?.modalData || null;
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [selectedBaState, setSelectedBaState] = useState("");
  const [baCities, setBaCities] = useState([]);
  const [selectedSaState, setSelectedSaState] = useState("");
  const [saCities, setSaCities] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showID, setShowID] = useState(false);
  const [dataCheck, setDataCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerIDdata, setCustomerIDdata] =useState([]);

  const [data, setData] = useState({
    customerid: "",
    fullname: "",
    customeremail: "",
    bdgstno: "",
    bdpan: "",
    paymentterms: "",
    tds: "",
    sellingaccount: "",
    bacountry: "India",
    bastate: "",
    bacity: "",
    baaddress: "",
    bapincode: "",
    sacountry: "India",
    sastate: "",
    sacity: "",
    saaddress: "",
    sapincode: "",
    banka: "",
    bankbrancha: "",
    accountnoa: "",
    ifsca: "",
    bankb: "",
    bankbranchb: "",
    accountnob: "",
    ifscb: "",
    subcategory: "",
    category: "",
    accountid: "",
    branch: branchName,
    branchid: branchID,
    company: companyName,
    companyid: companyID,
    userid: userID,
    month: "",
    financialyear: "",
    phone: "",
    dob: "",
    customertype: type,
    remarks: "",
    bdowner: "",
    bdcompany: "",
    bdworkphone: "",
    bdmobile: "",
    bdemail: "",
    mobile: "",
    gstfrom: "",
    tcs: "",
    tds: "",
    org: "₹ΘθϖΠ",
  });

  useEffect(() => {
    if (selectedBaState) {
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedBaState
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setBaCities(cityNames);
    } else {
      setBaCities([]);
    }

    if (selectedSaState) {
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedSaState
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setSaCities(cityNames);
    } else {
      setSaCities([]);
    }
  }, [selectedBaState, selectedSaState]);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      tcsData(
        {
          keyword: "",
          companyid: companyID,
          userid: userID,
          branchid: branchID,
        },
        setLoading
      )
    );
    dispatch(
      tdsData(
        {
          keyword: "",
          companyid: companyID,
          userid: userID,
          branchid: branchID,
        },
        setLoading
      )
    );
  }, [dispatch]);

  const handleIDModal = () => setShowID(true);
  const handleLeftArrow = () => {
    if (dataCheck) {
      notifySuccess("Customer added successfully");
    }
    setData((prevData) => ({
      ...prevData,
      key: "",
    }));
    navigate("/sales/salesOrder/addSalesOrder");
  };
  const handleConvert = () => {
    setData((prevData) => ({
      ...prevData,
      customertype: "Institutional",
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "bastate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedBaState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          bacity: "",
        }));
      } else {
        setSelectedBaState("");
        setData((prev) => ({
          ...prev,
          bastate: "",
          bacity: "",
        }));
      }
    }

    if (name == "sastate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedSaState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          sacity: "",
        }));
      } else {
        setSelectedSaState("");
        setData((prev) => ({
          ...prev,
          sastate: "",
          sacity: "",
        }));
      }
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedSaState(selectedBaState);
      setData((prev) => ({
        ...prev,
        sacountry: data.bacountry,
        sastate: data.bastate,
        sacity: data.bacity,
        saaddress: data.baaddress,
        sapincode: data.bapincode,
      }));
    } else {
      setSelectedSaState("");
      setData((prev) => ({
        ...prev,
        sacountry: "",
        sastate: "",
        sacity: "",
        saaddress: "",
        sapincode: "",
      }));
    }
  };

  useEffect(() => {
    axios
    .post("https://erp.venturesathi.co.in/api/v1/customer/getallcustomerpage", {
      userid: userID,
      companyid: companyID,
      branchid: branchID,
      keyword: "" || "₹ΘθϖΠ",
    })
    .then((res) => {
      const initialData =
        res.data && res.data.data && res.data.data.length > 0
          ? res.data.data
          : [];

          setCustomerIDdata(initialData);
    })
    .catch((err) => console.log(err));
  }, []);

  const generateCategoryID = () => {
    if (customerIDdata.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = customerIDdata[0].customerid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };
  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      customerid: `CT/SAANVI/${paddedCount}`,
    }));
  }, [customerIDdata]);

  const handleSubmit = async () => {
    //Validation Check
    if (data.customertype == "Retail") {
      if (
        data.fullname == "" ||
        data.phone == "" ||
        //data.dob == "" ||
        data.bacountry === "" ||
        data.bastate === "" ||
        data.bacity === "" ||
        data.baaddress === "" ||
        data.bapincode === "" ||
        data.sacountry === "" ||
        data.sastate === "" ||
        data.sacity === "" ||
        data.saaddress === "" ||
        data.sapincode === ""
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    } else if (data.customertype == "Institutional") {
      if (
        data.bdcompany == "" ||
        data.bdworkphone == "" ||
        data.fullname == "" ||
        data.phone == "" ||
        data.bdgstno == "" ||
        data.bdpan == "" ||
        data.bacountry === "" ||
        data.bastate === "" ||
        data.bacity === "" ||
        data.baaddress === "" ||
        data.bapincode === "" ||
        data.sacountry === "" ||
        data.sastate === "" ||
        data.sacity === "" ||
        data.saaddress === "" ||
        data.sapincode === ""
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }

    //  Add Customer API Calling
    const res = await axios.post(customerAddPost, data);
    if (res.data.message == "Customer added successfully") {
      setDataCheck(true);
      handleIDModal();
    } else {
      notifyWarning(res.data.message);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Customer <span className="form-title main"> Registration</span>
          </h1>
          <hr />
          {/*---------------- Retail Start------------*/}
          <Container className={type == "Institutional" ? "d-none" : ""} fluid>
            <h3>Customer Details</h3> {/*Customer Details*/}
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Customer Full Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="fullname"
                    value={data.fullname}
                    onChange={handleChange}
                    isInvalid={data.fullname === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Phone No. <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Phone No"
                        name="phone"
                        value={data.phone}
                        onChange={handleChange}
                        isInvalid={data.phone === "" && showEmptyError}
                        onBlur={() => validatePhone(data.phone)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Phone No.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        value={data.dob}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Select Date of Birth
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        name="customeremail"
                        value={data.customeremail}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Remarks"
                        name="remarks"
                        value={data.remarks}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>TCS</Form.Label>
                      <Form.Select
                        disabled={data.tds}
                        name="tcs"
                        value={data.tcs}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {tcsMaster?.length > 0 &&
                          tcsMaster.map((tax, ind) => {
                            const optionValue = `${tax.taxname}`;
                            return (
                              <option value={optionValue} key={ind}>
                                {tax.taxname}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>TDS</Form.Label>
                      <Form.Select
                        disabled={data.tcs}
                        name="tds"
                        value={data.tds}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {tdsMaster?.length > 0 &&
                          tdsMaster.map((tax, ind) => {
                            const optionValue = `${tax.taxname}`;
                            return (
                              <option value={optionValue} key={ind}>
                                {tax.taxname}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <h3>Billing Address</h3> {/*Billing Address*/}
                <Row md={3} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="bacountry"
                        value={data.bacountry}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="bastate"
                        value={data.bastate}
                        onChange={handleChange}
                        isInvalid={!data.bastate && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option value={state.name} key={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="bacity"
                        value={data.bacity}
                        onChange={handleChange}
                        isInvalid={!data.bacity && showEmptyError}
                      >
                        <option value="">Select</option>
                        {baCities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Address <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        name="baaddress"
                        value={data.baaddress}
                        onChange={handleChange}
                        isInvalid={!data.baaddress && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Pin <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Pin Code"
                        name="bapincode"
                        value={data.bapincode}
                        onChange={handleChange}
                        onBlur={() => validatePin(data.bapincode)}
                        isInvalid={!data.bapincode && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <h3 className="d-flex justify-content-between">
                  {" "}
                  {/*Shipping Address*/}
                  <span>Shipping Address</span>{" "}
                  <Form.Check
                    inline
                    label="Same as billing address"
                    name="group1"
                    type="checkbox"
                    id="check2"
                    onChange={handleCheckboxChange}
                  />
                </h3>{" "}
                <Row md={3} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="sacountry"
                        value={data.sacountry}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="sastate"
                        value={data.sastate}
                        onChange={handleChange}
                        isInvalid={!data.sastate && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option value={state.name} key={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="sacity"
                        value={data.sacity}
                        onChange={handleChange}
                        isInvalid={!data.sacity && showEmptyError}
                      >
                        <option value="">Select</option>
                        {saCities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Address <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        name="saaddress"
                        value={data.saaddress}
                        onChange={handleChange}
                        isInvalid={!data.saaddress && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Pin <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter PIN Code"
                        name="sapincode"
                        value={data.sapincode}
                        onChange={handleChange}
                        onBlur={() => validatePin(data.sapincode)}
                        isInvalid={!data.sapincode && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <h3>Bank Details (Optional)</h3> {/*bank Details*/}
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        name="banka"
                        value={data.banka}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Branch</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter branch"
                        name="bankbrancha"
                        value={data.bankbrancha}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter IFSC Code"
                        name="ifsca"
                        value={data.ifsca}
                        onChange={handleChange}
                        onBlur={() => validateIFSC(data.ifsca)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Number"
                        name="accountnoa"
                        value={data.accountnoa}
                        onChange={handleChange}
                        onBlur={() => validateBankAccount(data.accountnoa)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <h3>Bussiness Details (Optional)</h3> {/*Bussiness Details*/}
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Owner Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Business Owner name"
                        name="bdowner"
                        value={data.bdowner}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Company Name"
                        name="bdcompany"
                        value={data.bdcompany}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={3} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Work Phone No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Work Phone Number"
                        name="bdworkphone"
                        value={data.bdworkphone}
                        onChange={handleChange}
                        onBlur={() => validatePhone(data.bdworkphone)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        GST No{" "}
                        {data.bdgstno != "" ? (
                          <span className="viewpage-value">
                            From (
                            {
                              (data.gstfrom = indianFormatDate(
                                `${new Date().toISOString().split("T")[0]}`
                              ))
                            }
                            )
                          </span>
                        ) : (
                          (data.gstfrom = "")
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter GST No"
                        name="bdgstno"
                        value={data.bdgstno}
                        onChange={handleChange}
                        onBlur={() => validateGST(data.bdgstno)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>PAN</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter PAN Number"
                        name="bdpan"
                        value={data.bdpan}
                        onChange={handleChange}
                        onBlur={() => validatePan(data.bdpan)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end">
              <Button
                disabled={
                  data.bdowner == "" ||
                  data.bdcompany == "" ||
                  data.bdworkphone == "" ||
                  data.bdgstno == "" ||
                  data.bdpan == ""
                }
                className="commonBtn smallBtn"
                onClick={handleConvert}
              >
                Convert to B2B
              </Button>
            </Row>
          </Container>
          {/*---------------- Retail End------------*/}

          {/*---------------- Instituional Start------------*/}
          <Container className={type == "Retail" ? "d-none" : ""} fluid>
            <h3>Bussiness Details</h3> {/*Bussiness Details*/}
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Owner Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Business Owner name"
                    name="bdowner"
                    value={data.bdowner}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Company Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    name="bdcompany"
                    value={data.bdcompany}
                    onChange={handleChange}
                    isInvalid={data.bdcompany === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Company Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Work Phone No. <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Work Phone Number"
                        name="bdworkphone"
                        value={data.bdworkphone}
                        onChange={handleChange}
                        isInvalid={data.bdworkphone === "" && showEmptyError}
                        onBlur={() => validatePhone(data.bdworkphone)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Work Phone Number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Mobile No</Form.Label>
                      <Form.Control
                        type="text"
                        name="bdmobile"
                        value={data.bdmobile}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                        name="bdemail"
                        value={data.bdemail}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="remarks"
                        value={data.remarks}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <h3>Contact Person</h3> {/*Contact Person*/}
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Full Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="fullname"
                    value={data.fullname}
                    onChange={handleChange}
                    isInvalid={data.fullname === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Work Phone No <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Work Phone Number"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                    isInvalid={data.phone === "" && showEmptyError}
                    onBlur={() => validatePhone(data.phone)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Work Phone Number
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Mobile No</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobile"
                    value={data.mobile}
                    onChange={handleChange}
                    onBlur={() => validatePhone(data.mobile)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="customeremail"
                    value={data.customeremail}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h3>Enter Statutory Details</h3> {/*Enter Statutory Details*/}
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        GST No <span className="star">*</span>{" "}
                        {data.bdgstno != "" ? (
                          <span className="viewpage-value">
                            From (
                            {
                              (data.gstfrom = indianFormatDate(
                                `${new Date().toISOString().split("T")[0]}`
                              ))
                            }
                            )
                          </span>
                        ) : (
                          (data.gstfrom = "")
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="bdgstno"
                        value={data.bdgstno}
                        onChange={handleChange}
                        isInvalid={data.bdgstno === "" && showEmptyError}
                        onBlur={() => validateGST(data.bdgstno)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter GST Number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        PAN No <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="bdpan"
                        value={data.bdpan}
                        onChange={handleChange}
                        isInvalid={data.bdpan === "" && showEmptyError}
                        onBlur={() => validatePan(data.bdpan)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter PAN Number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>TCS</Form.Label>
                      <Form.Select
                        disabled={data.tds}
                        name="tcs"
                        value={data.tcs}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {tcsMaster?.length > 0 &&
                          tcsMaster.map((tax, ind) => {
                            const optionValue = `${tax.taxname}[${tax.ratepercent}]`;
                            return (
                              <option value={optionValue} key={ind}>
                                {tax.taxname}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    {" "}
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>TDS</Form.Label>
                      <Form.Select
                        disabled={data.tcs}
                        name="tds"
                        value={data.tds}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {tdsMaster?.length > 0 &&
                          tdsMaster.map((tax, ind) => {
                            const optionValue = `${tax.taxname}[${tax.ratepercent}]`;
                            return (
                              <option value={optionValue} key={ind}>
                                {tax.taxname}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <h3>Billing Address</h3> {/*Billing Address*/}
                <Row md={3} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="bacountry"
                        value={data.bacountry}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="bastate"
                        value={data.bastate}
                        onChange={handleChange}
                        isInvalid={!data.bastate && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option value={state.name} key={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="bacity"
                        value={data.bacity}
                        onChange={handleChange}
                        isInvalid={!data.bacity && showEmptyError}
                      >
                        <option value="">Select</option>
                        {baCities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Address <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        name="baaddress"
                        value={data.baaddress}
                        onChange={handleChange}
                        isInvalid={!data.baaddress && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Pin <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Pin Code"
                        name="bapincode"
                        value={data.bapincode}
                        onChange={handleChange}
                        onBlur={() => validatePin(data.bapincode)}
                        isInvalid={!data.bapincode && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <h3 className="d-flex justify-content-between">
                  {" "}
                  {/*Shipping Address*/}
                  <span>Shipping Address</span>{" "}
                  <Form.Check
                    inline
                    label="Same as billing address"
                    name="group1"
                    type="checkbox"
                    id="check2"
                    onChange={handleCheckboxChange}
                  />
                </h3>{" "}
                <Row md={3} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="sacountry"
                        value={data.sacountry}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="sastate"
                        value={data.sastate}
                        onChange={handleChange}
                        isInvalid={!data.sastate && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option value={state.name} key={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="sacity"
                        value={data.sacity}
                        onChange={handleChange}
                        isInvalid={!data.sacity && showEmptyError}
                      >
                        <option value="">Select</option>
                        {saCities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Address <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        name="saaddress"
                        value={data.saaddress}
                        onChange={handleChange}
                        isInvalid={!data.saaddress && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Pin <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter PIN Code"
                        name="sapincode"
                        value={data.sapincode}
                        onChange={handleChange}
                        onBlur={() => validatePin(data.sapincode)}
                        isInvalid={!data.sapincode && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <h3>Bank Details (1)</h3> {/*bank Details*/}
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        name="banka"
                        value={data.banka}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Branch</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Branch"
                        name="bankbrancha"
                        value={data.bankbrancha}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter IFSC Code"
                        name="ifsca"
                        value={data.ifsca}
                        onChange={handleChange}
                        onBlur={() => validateIFSC(data.ifsca)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Account Number"
                        name="accountnoa"
                        value={data.accountnoa}
                        onChange={handleChange}
                        onBlur={() => validateBankAccount(data.accountnoa)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <h3>Bank Details (2)</h3> {/*bank Details*/}
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        name="bankb"
                        value={data.bankb}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Branch</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Branch"
                        name="bankbranchb"
                        value={data.bankbranchb}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter IFSC Code"
                        name="ifscb"
                        value={data.ifscb}
                        onChange={handleChange}
                        onBlur={() => validateIFSC(data.ifscb)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Account Number"
                        name="accountnob"
                        value={data.accountnob}
                        onChange={handleChange}
                        onBlur={() => validateBankAccount(data.accountnob)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {/*---------------- Instituional End------------*/}
        </Form>
      </div>
      <Modal.Footer className="d-flex justify-content-end gap-3 mt-2">
        <Button
          className="commonBtn smallBtn cancelBtn"
          onClick={handleLeftArrow}
        >
          Cancel
        </Button>
        <Button className="commonBtn smallBtn" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
      <Modal show={showID} size="sm" onHide={handleLeftArrow} centered>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            <h1 className="fw-bold text-center">
              Customer Registered Successfully
            </h1>
            <div className="w-30 text-center">
              <Image
                src="https://cdn.pixabay.com/photo/2013/07/13/10/48/check-157822_640.png"
                style={{ maxHeight: "100px" }}
              />
            </div>
            <h2 className="text-color p-4 bg-light">
              Customer ID: {data.customerid}
            </h2>
            <Button
              className="commonBtn smallBtn cancelBtn"
              style={{
                color: "#ffffff",
                background: "rgb(5,66,4)",
                background:
                  "linear-gradient(97deg, rgba(5,66,4,1) 30%, rgba(16,157,14,1) 70%)",
              }}
              onClick={handleLeftArrow}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AddCustomerInSO;
