import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { useNavigate } from "react-router-dom";

function AddDayClosure() {
  const navigate = useNavigate()
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];

  let userid = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let org = localData.org;

  const [companyDropData, setCompanyDropData] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [closureDate, setClosureDate] = useState("");

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/master/getcomapanypage`, {

        userid,
        keyword: org,
      })
      .then((res) => {
        setCompanyDropData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSave = () => {
    if (closureDate == "" || selectedCompanyId == "") {
      return notifyError("You have empty fields");
    }

    axios

      .post(`https://erp.venturesathi.co.in/api/v1/master/closuredate`, {

        closuredate: closureDate,
        id: selectedCompanyId,
      })
      .then((res) => {
        if(res.data.message = "Day clousure updated successfully"){
          setClosureDate("")
          setCompanyDropData([])
          navigate('/admin/dayclosure')
          return   notifySuccess(res.data.message)
        }
      
      })
      .catch((err) => console.log(err));
  };
  const handleBack = ()=>{
    navigate('/admin/dayclosure')
  }

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">{/* <span>{URL}</span> */}</Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            {/* <FaArrowLeftLong
              className="left-arrow"
              // onClick={handleLeftArrow}
            /> */}
            Day <span className="form-title main"> Closure</span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row lg={2} md={1} sm={1}>
            
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        company Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setSelectedCompanyId(e.target.value)}
                      >
                        <option>--Select--</option>
                        {companyDropData &&
                          companyDropData.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.companyname}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Enter User ID
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>
                        Date<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        // disabled
                        type="date"
                        // placeholder="ID"
                        // name="date"
                        value={closureDate}
                        onChange={(e) => setClosureDate(e.target.value)}
                        // isInvalid={data.userid === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Date
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            Save
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
             onClick={handleBack}
          >
            Back
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
}

export default AddDayClosure;
