import React, { useEffect, useState } from "react";
import { Col, Modal, Table } from "react-bootstrap";
import TableHeading from "../../../constants/TableHeading";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import DataSpinner from "../../../constants/DataSpinner";

const RoomRackDetails = ({ show, onClose, itemid }) => {
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid } = JSON.parse(localStorage.getItem("userData")).data[0];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(

          "https://erp.venturesathi.co.in/api/v1/receivegoods/getitemroom",

          { companyid: companyid }
        );
        const initialData = res.data && res.data.length > 0 ? res.data : [];

        const filteredData = initialData.filter((ele) => ele.itemid == itemid);
        setData(filteredData);
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loadData,itemid]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size="xl"
        backdrop="static"
        keyboard={false}
        //centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="f1">Romm Rack Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className="table-main-container">
            <Table responsive>
              <TableHeading
                data={[
                  "Item ID",
                  "Item Name",
                  "Category",
                  "Qty",
                  "Warehouse",
                  "Room No",
                  "Rack No",
                  "GRN",
                  "Defective",
                  "Non-Defective",
                ]}
              />
              <tbody>
                {loadData || loading ? (
                  <DataSpinner count="10" />
                ) : (
                  data.length > 0 &&
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.itemid}</td>
                      <td>{item.itemname}</td>
                      <td>{item.category}</td>
                      <td>{item.qty}</td>
                      <td>{item.warehouse}</td>
                      <td>{item.roomno}</td>
                      <td>{item.rakeno}</td>
                      <td>{item.grn}</td>
                      <td>{item.defective}</td>
                      <td>{item.nondefective}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoomRackDetails;
