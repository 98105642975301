import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { branchPagiData } from "../../../../redux/actions/branch.action";
import axios from "axios";

const OfficialDetails = ({ data, handleChange, showEmptyError }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.branchData.branchPagiData);
  const [loading, setLoading] = useState(false);

  const [deparment, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);


  useEffect(()=>{
    // For Department

    axios.post(`https://erp.venturesathi.co.in/api/v1/master/getdepartment`, {userid:userID})

    .then((res)=>{
      setDepartment(res.data)
    })
    .catch((err)=>console.log(err));

    // For Designation

    axios.post(`https://erp.venturesathi.co.in/api/v1/master/getdesignation`, {userid:userID})

    .then((res)=>{
      setDesignation(res.data)
    })
    .catch((err)=>console.log(err))
  },[])


  useEffect(() => {
    setLoading(!loading);
    dispatch(
      branchPagiData(
        {
          keyword: "₹ΘθϖΠ",
          companyid: companyID,
          userid: userID,
          branchid: branchID,
        },
        setLoading
      )
    );
  }, []);
  return (
    <Container fluid>
      <Row className="form-container">
        <Col>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Branch<span className="star"> *</span>
                </Form.Label>
                <Form.Select
                  name="branch"
                  value={data.branch}
                  onChange={handleChange}
                  isInvalid={data.branch === "" && showEmptyError}
                >
                  <option value="">--Select--</option>
                  {branches &&
                    branches.data &&
                    branches.data.map((branch, index) => (
                      <option key={index} value={branch.branchname}>
                        {branch.branchname}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Choose a Branch
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Department<span className="star"> *</span>
                </Form.Label>
                {/* <Form.Control
                  type="text"
                  placeholder="Department Name"
                  name="department"
                  value={data.department}
                  onChange={handleChange}
                  isInvalid={data.department === "" && showEmptyError}
                /> */}

              <Form.Select
                  name="department"
                  value={data.department}
                  onChange={handleChange}
                  isInvalid={data.department === "" && showEmptyError}
                >
                  <option value="">--Select--</option>
                  {deparment &&
                    deparment.map((item, index) => (
                      <option key={index} value={item.departmentname}>
                        {item.departmentname}
                      </option>
                    ))}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Enter a Department Name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Designation<span className="star"> *</span>
                </Form.Label>
                {/* <Form.Control
                  type="text"
                  placeholder="Designation Name"
                  name="designation"
                  value={data.designation}
                  onChange={handleChange}
                  isInvalid={data.designation === "" && showEmptyError}
                /> */}

                  <Form.Select
                  name="designation"
                  value={data.designation}
                  onChange={handleChange}
                  isInvalid={data.designation === "" && showEmptyError}
                >
                  <option value="">--Select--</option>
                  {designation &&
                    designation.map((item, index) => (
                      <option key={index} value={item.designationname}>
                        {item.designationname}
                      </option>
                    ))}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Enter a Designation Name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Row md={2} sm={1} xs={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Joining Date<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select Date Of Birth"
                      name="joiningdate"
                      max={`${new Date().toISOString().split("T")[0]}`}
                      value={data.joiningdate}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.joiningdate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Date Of Joining
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Sales Person<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      name="issale"
                      value={data.issale}
                      onChange={handleChange}
                      isInvalid={data.issale === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select a value
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OfficialDetails;
