import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useRef } from "react";

const MonthlySummaryView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const pdfRef = useRef(null);

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userid = localData.userid;
  let branchid = localData.branchid;
  let companyid = localData.companyid;
  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);
  const loadData = useSelector((state) => state.loadReducer);
  const [loading, setLoading] = useState(false);

  const [extractedPart, setExtractedPart] = useState("");
  useEffect(() => {
    const currentURL = window.location.href;
    const urlParts = currentURL.split("/"); // Split the URL by "/"
    const itemIndex = urlParts.indexOf("items"); // Find the index of "adminPanel"
    if (itemIndex !== -1 && itemIndex < urlParts.length - 1) {
      // Check if "adminPanel" is in the URL and if there's a part after it
      const desiredPart = urlParts.slice(itemIndex).join("/"); // Get the part after "adminPanel"
      const formattedPart = desiredPart
        .split("/")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" / ");
      const spacedpart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
      setExtractedPart(spacedpart); // Set the extracted part in the component's state
    } else {
      // console.log("The URL does not contain 'items'");
    }
  }, []);

  const handleLeftArrow = () => {
    navigate("/stock-report");
  };
  const handleVoucherView = (realid, itemid) => {

    navigate("/stock-report/stockReportsMonthlySummary/stockVoucher", { state: { realid: realid, itemid:itemid } });
    // navigate(`/stock-report/stockReportsMonthlySummary/stockVoucher/${realid}`);
  };

  const [recieveData, setRecieveData] = useState([]);
  const [issueData, setIssueData] = useState([]);
  const [allDetails, setAllDetails] = useState([]);

  // get all items
  const getItemDetails = () => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/item/itembyid`, {

        id,
        companyid,
        org: "₹ΘθϖΠ",
        financialyear: financialyear,
      })
      .then((res) => {
        setAllDetails(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    getItemDetails();
    //  for recieve
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/report/stock/monthreceieve`, {

        itemid: id,
        fy: financialyear,
        companyid,
        org: "₹ΘθϖΠ",
        financialyear: financialyear,
      })
      .then((res) => {
        setRecieveData(res.data.length > 0 ? res.data : []);
        
        setLoading(false);
      })
      .catch((err) => console.log(err));

    //  for issue monthly data

    axios

      .post(`https://erp.venturesathi.co.in/api/v1/report/stock/monthissue`, {

        itemid: id,
        fy: financialyear,
        companyid,
        org: "₹ΘθϖΠ",
        financialyear: financialyear,
      })
      .then((res) => {
        setIssueData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, []);

  const MONTHS = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  const {
    mpn,
    unit,
    paccount,
    hsndescription,
    hsncode,
    brand,
    itemid,
    itemname,
    category,
    oamount,
    oqty,
    orate,
    itemdescription,
    taxability,
    gst,
    billingtype,
    cessvaluationtype,
  } = allDetails.length > 0 && allDetails[0];

  
  // date for today date
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  var formattedTodayDate = dd + "-" + mm + "-" + yyyy;

  return (
    <>
      <Row>
        <Col className="colStyle">{extractedPart}</Col>
      </Row>
      <Container
        fluid
        style={{
          borderRadius: "24px",
          background: "#FFF",
          padding: "30px",
        }}
      >
        <Row>
          <Col>
            {" "}
            <p>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />{" "}
              <span className="form-title main">Item Details</span>
            </p>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col>
            {" "}
            {/* <ReactHTMLTableToExcel
              id="exportButton"
              className=" smallBtn3 cancelBtn  commonBtn"
              table="table-to-export"
              filename={`Stock Report Month, ${formattedTodayDate}`}
              sheet="stock_report"
              buttonText={
                <>
                  {" "}
                  <BiSolidFileExport /> Export
                </>
              }
            /> */}

            <DownloadTableExcel
              filename="Sell table"
              sheet="Sales"
              currentTableRef={pdfRef.current}
            >

              <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

            </DownloadTableExcel>

          </Col>
        </Row>

        <Row>
          <Col>
            <p>
              <span className="viewpage-title">Item No:</span>
              <span className="viewpage-title">{itemid || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Category:</span>
              <span className="viewpage-title">{category || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Make/Brand:</span>
              <span className="viewpage-title">{brand || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Item Description:</span>
              <span className="viewpage-title">{itemdescription || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Taxability Type:</span>
              <span className="viewpage-title">{taxability || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">GST Rate %:</span>
              <span className="viewpage-title">{gst || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Billing Type:</span>
              <span className="viewpage-title">{billingtype || "--"}</span>
            </p>
          </Col>
          <Col>
            <p>
              <span className="viewpage-title">Item Name:</span>
              <span className="viewpage-title">{itemname || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Unit:</span>
              <span className="viewpage-title">{unit || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">MPN:</span>
              <span className="viewpage-title">{mpn || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">HSN Code:</span>
              <span className="viewpage-title">{hsncode || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">HSN Description:</span>
              <span className="viewpage-title">{hsndescription || "--"}</span>
            </p>
            <p>
              <span className="viewpage-title">Cess Valuation Type:</span>
              <span className="viewpage-title">{cessvaluationtype || "--"}</span>
            </p>
          </Col>
        </Row>
        <br />
        <br />
        <Container
          fluid
          style={{
            borderRadius: "24px",
            background: "#FFF",
            paddingTop: "10px",
            boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Row>
            <Col style={{ fontSize: "14px" }}>
              <p>
                <span className="main">Stock Report Monthly Summary</span>
              </p>
            </Col>
            <Col md={12} className="table-main-container">
              <Table id="table-to-export" responsive ref={pdfRef}>
                <thead className="tableContainer">
                  <tr>
                    <th colSpan={2}>Particular</th>
                    <th colSpan={2}>Receive</th>
                    <th colSpan={2}>Issue</th>
                    <th colSpan={2}>Closing Balance</th>
                    <th colSpan={2}>Action</th>
                  </tr>
                  <tr>
                    <th colSpan={2}></th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
        {MONTHS.map((month, index) => {
          const monthData = recieveData.find(
            (data) => data.month.toLowerCase() === month.toLowerCase()
          );
          const issueItem = issueData.find(
            (issueItem) => issueItem.month.toLowerCase() === month.toLowerCase()
          );

          return (
            <tr key={index}>
              <td colSpan={2}>{month}</td>
              {monthData ? (
                <>
                  <td>{monthData.total_qty}</td>
                  <td>{monthData.total_amount || "-"}</td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {issueItem ? (
                <>
                  <td>{issueItem.total_qty || "-"}</td>
                  <td>{issueItem.total_amount || "-"}</td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* Closing balance calculation */}
              {monthData && issueItem ? (
                <>
                  <td>{monthData.total_qty - issueItem.total_qty}</td>
                  <td>{(monthData.total_amount || 0) - issueItem.total_amount}</td>
                </>
              ) : (
                <>
                  <td>-</td>
                  <td>-</td>
                </>
              )}

              {/* Action column */}
              <td colSpan={2}>
                {monthData && (
                  <AiFillEye className="editIcon" onClick={() => handleVoucherView(monthData.realid, monthData.itemid)} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MonthlySummaryView;
