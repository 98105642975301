import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { useNavigate } from "react-router-dom";
import {
  userDeleteUserPost,
  userUpdateStatus,
  userUserpaginationPost,
} from "../../../constants/api";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
// import EditUser from "./EditUser";
import { MdModeEditOutline } from "react-icons/md";
import ViewModal from "./ViewModal";

const ListTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyid = localData.companyid;
  let org = localData.org;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [itemId, setItemid] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  const handleShow = (item) => {
    const { id, termname } = item;
    setShow(true);
    setItemid(id);
    setSelectedItemNo(termname);
  };

  const fetchData = async () => {
    try {

      const res = await axios.post(`https://erp.venturesathi.co.in/api/v1/termcondition/get`, {

        companyid,
      });
      setData(res.data);
      dispatch(reloadPage(false));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loadData, searchKeyword]);

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {

    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid: userID,
        submodule: "Term & Condition",
        companyid: companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 && res.data[0].crud);
      })
      .catch((err) => console.log(err));

  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  const handleAddTermNavigate = () => {
    navigate(`/admin/termcondition/addterm`);
  };
  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  const handleDelete = () => {
    const deleteData = async () => {
      try {
        const res = await axios.post(
          `https://erp.venturesathi.co.in/api/v1/termcondition/delete`,

          {
            id: itemId,
            vendorid: selectedItemNo,
            companyid: companyid,
            userid: userID,
            org: org,
          }
        );
        if (res.data.message == "Term condition deleted successfully") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyError("Error Deleting Term Condition");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    deleteData();
  };

  //search logic
  // const filteredData =
  //   data.length > 0 &&
  //   data.filter((item) =>
  //     searchKeyword
  //       ? Object.values(item).some((value) =>
  //           value
  //             ?.toString()
  //             ?.toLowerCase()
  //             ?.includes(searchKeyword.toLowerCase().trim())
  //         )
  //       : true
  //   );

  const filteredData = data.filter((item) => {
    if (!searchKeyword) return true;
    const lowercasedKeyword = searchKeyword.toLowerCase().trim();
    return (
      item.type.toString().toLowerCase().includes(lowercasedKeyword) ||
      item.termname.toLowerCase().includes(lowercasedKeyword) ||
      item.date.toLowerCase().includes(lowercasedKeyword)
    );
  });

  const handleEdit = (id) => {
    navigate(`/admin/termcondition/editterm/${id}`);
  };

  const handleDisable = (id, status) => {
    const updateStatus = async () => {
      try {
        const res = await axios.post(
          `https://erp.venturesathi.co.in/api/v1/termcondition/update-status`,

          { id, status }
        );
        notifySuccess(res.data.message);
      } catch (error) {
        console.log(error);
      }
      dispatch(reloadPage(true));
    };
    updateStatus();
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Term & Condition</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={handleAddTermNavigate}
              >
                Add Term
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Term name</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr
                  key={index}
                  className={item.status == "In-Active" ? "deactivated" : ""}
                >
                  <td>{item.date}</td>
                  <td>{item.termname}</td>
                  <td>{item.type}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip("Active/Inactive")}
                        >
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            checked={item.status == "Active"}
                            className="custom-switch"
                            onChange={() =>
                              handleDisable(
                                item.id,
                                item.status === "Active"
                                  ? "In-Active"
                                  : "Active"
                              )
                            }
                          />
                        </OverlayTrigger>
                      </div>
                      <div
                        className={
                          item.status == "In-Active" ? "deactivated_icons" : ""
                        }
                      >
                        <ViewModal
                          description={item.description}
                          termname={item.termname}
                        />

                        {crud && crud.includes("u") && (
                          <MdModeEditOutline
                            className="editIcon mx-2"
                            onClick={() => handleEdit(item.id)}
                          />
                        )}

                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={() => setShow(false)}
                            handleDelete={handleDelete}
                            name="Terms & Conditions"
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListTable;
