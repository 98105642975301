import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import DepartmentMaster from "./DepartmentMaster";
import DesignationMaster from "./DesignationMaster";
import {
  userUpdateUserPost,
  userUserpaginationPost,
} from "../../../constants/api";
import axios from "axios";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const EditUser = () => {
  const URL = extractAndFormatURLPart("admin");
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showroomorNot, setShowRoomOrNot] = useState(false);
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [godownData, setGodownData] = useState([]);
  const [showroomData, setShowRoomData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [refCompany, setrefCompany] = useState(true)

  useEffect(()=>{
    axios
      .post(userUserpaginationPost, { userid: userID })
      .then((res) => {
        const filteredData = res.data.data
          ? res.data.data.filter((elem) => {
              return elem.userid == id;
            })
          : [];
        setData(filteredData[0]);
        setrefCompany(!refCompany)
      })
      .catch((err) => console.log(err));

  },[])

  useEffect(() => {
    //dodown/showroom dyanamic data
    axios

      .post("https://erp.venturesathi.co.in/api/v1/master/getwarehousepage", {

        keyword: "₹ΘθϖΠ",
        userid: userID,
        companyid: companyID,
        branchid: branchID,
      })
      .then((res) => {
        setGodownData(
          res.data && res.data.data.length > 0
            ? res.data.data.filter(
                (ele) =>
                  ele.status == "active" &&
                  ele.branch == data.branch &&
                  ele.warehousetype == "Godown"
              )
            : []
        );
        setShowRoomData(
          res.data && res.data.data.length > 0
            ? res.data.data.filter(
                (ele) =>
                  ele.status == "active" &&
                  ele.branch == data.branch &&
                  ele.warehousetype == "Showroom"
              )
            : []
        );
      })
      .catch((err) => console.log(err));
  }, [data.companyname, data.branch]);

  useEffect(()=>{
  //branch dyanamic data
  axios
  .post("https://erp.venturesathi.co.in/api/v1/master/getbranch", {

    userid: userID,
    company: data.companyname,
    companyid: data.companyid,
  })
  .then((res) => {
    setBranchData(
      res.data.length > 0
        ? res.data.filter(
            (ele) =>
              ele.status == "active" && ele.company == data.companyname
          )
        : []
    );
  })
  .catch((err) => console.log(err));

  },[refCompany])

  useEffect(() => {
    //company dynamic data
    axios

      .post("https://erp.venturesathi.co.in/api/v1/master/getcompany", { userid: userID })

      .then((res) => {
        setCompanyData(
          res.data.length > 0
            ? res.data.filter((ele) => ele.status == "active")
            : []
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //Designation dyanamic data
    axios

      .post("https://erp.venturesathi.co.in/api/v1/master/getdesignation", {

        userid: userID,
      })
      .then((res) => {
        setDesignationData(res.data.length > 0 ? res.data : []);
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //Department dyanamic data
    axios

      .post("https://erp.venturesathi.co.in/api/v1/master/getdepartment", {

        userid: userID,
      })
      .then((res) => {
        setDepartmentData(res.data.length > 0 ? res.data : []);
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUpdate = async () => {
    if (
      data.userid == "" ||
      data.name == "" ||
      data.password == "" ||
      data.companyname == "" ||
      data.contactno == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    if (data.userid.length !== 5) {
      return notifyError("User ID must be exact 5 characters");
    }

    const alphaNumericError = isAlphaNumeric(data.userid);
    if (alphaNumericError) {
      return notifyError(alphaNumericError);
    }
    if (data.contactno.length !== 10) {
      return notifyError("Contact Number must be exact 10 numbers");
    }
    try {
      const res = await axios.post(userUpdateUserPost, data);
      if (res.data.message == "User updated successfully") {
        notifySuccess("User Updated");
        handleLeftArrow();
      } else {
        notifyWarning(res.data.message);
      }
      dispatch(reloadPage(true));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function isAlphaNumeric(str) {
    // Regular expression to match alphanumeric characters
    if (/^[a-zA-Z]+$/.test(str) || /^[0-9]+$/.test(str)) {
      return "User ID must be Alphanumeric";
    }
  }

  const handleLeftArrow = () => {
    navigate("/admin/user");
  };
  const handleChange = (e) => {
    const { name, value, id } = e.target;
    const checked = e.target.checked;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name == "companyname") {
      const selectetCompany = companyData.find(
        (itm) => itm.companyname === value
      );
      if (selectetCompany) {
        setData((prevData) => ({
          ...prevData,
          companyid: selectetCompany.companyid,
          branchname: "",
          [name]: value,
        }));
      }
      setrefCompany(!refCompany)
    }

    if (name == "branch") {
      const selectetBranch = branchData.find((itm) => itm.branchname === value);
      if (selectetBranch) {
        setData((prevData) => ({
          ...prevData,
          branchid: selectetBranch.branchid,
          [name]: value,
        }));
      }
    }

    if (name == "designationname") {
      const selectetDesignation = branchData.find(
        (itm) => itm.designationname === value
      );
      if (selectetDesignation) {
        setData((prevData) => ({
          ...prevData,
          designationid: selectetDesignation.designationid,
          [name]: value,
        }));
      }
    }

    if (name == "departmentname") {
      const selectetDepartment = branchData.find(
        (itm) => itm.departmentname === value
      );
      if (selectetDepartment) {
        setData((prevData) => ({
          ...prevData,
          departmentid: selectetDepartment.departmentid,
          [name]: value,
        }));
      }
    }

    if (
      name == "designationname" &&
      (value == "Owner" || value == "Sub-Dealer" || value == "Franchise")
    ) {
      setData((prevData) => ({
        ...prevData,
        departmentname: "",
        departmentid: "",
        branch: "",
        branchid: "",
        warehousename: "",
        warehouseid: "",
        showroomname: "",
        showroomid: "",
        //isShowroom: "",
      }));
    }
    if (name == "isShowroom") {
      setData((prevData) => ({
        ...prevData,
        isShowroom: showroomorNot,
      }));
    }
    if (name == "showroomname") {
      setData((prevData) => ({
        ...prevData,
        warehousename: "",
      }));
    } else if (name == "warehousename") {
      setData((prevData) => ({
        ...prevData,
        showroomname: "",
      }));
    }
   
    setShowEmptyError(false);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />
              Update <span className="form-title main"> User</span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>
                          User ID<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          placeholder="ID"
                          name="userid"
                          value={data.userid}
                          onChange={handleChange}
                          isInvalid={data.userid === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter User ID
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          User Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          autoComplete="username"
                          type="text"
                          placeholder="Enter Name"
                          name="name"
                          value={data.name}
                          onChange={handleChange}
                          isInvalid={data.name === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter User Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={data.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>
                          Password<span className="star"> *</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            autoComplete="password"
                            isInvalid={data.password === "" && showEmptyError}
                            required
                          />
                          <InputGroup.Text
                            //variant="outline-secondary"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? <BsEyeSlash /> : <BsEye />}
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">
                            Enter Password
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicContact">
                        <Form.Label>
                          Contact No <span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="contactno"
                          maxLength={10}
                          value={data.contactno}
                          onChange={handleChange}
                          isInvalid={data.contactno === "" && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicDesignation"
                      >
                        <Form.Label className="d-flex justify-content-between mt-0">
                          <div>Designation</div>
                          <DesignationMaster />
                        </Form.Label>
                        <Form.Select
                          name="designationname"
                          value={data.designationname}
                          onChange={handleChange}
                        >
                          {designationData.length > 0 ? (
                            designationData.map((item, index) => (
                              <option key={index} value={item.designationname}>
                                {item.designationname}
                              </option>
                            ))
                          ) : (
                            <option value="">No Designation available</option>
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicCompany">
                        <Form.Label>
                          Company<span className="star"> *</span>
                        </Form.Label>
                        <Form.Select
                          name="companyname"
                          value={data.companyname}
                          onChange={handleChange}
                          isInvalid={data.companyname === "" && showEmptyError}
                        >
                          <option value="">--Select--</option>
                          {companyData.length > 0 ? (
                            companyData
                              .filter((company) => company.status == "active")
                              .map((item, index) => (
                                <option key={index} value={item.companyname}>
                                  {item.companyname}
                                </option>
                              ))
                          ) : (
                            <option value="">No Companies available</option>
                          )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Enter Company
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      className={
                        data.designationname == "Owner" ||
                        data.designationname == "Sub-Dealer" ||
                        data.designationname == "Franchise"
                          ? "deactivated_icons"
                          : ""
                      }
                    >
                      <Form.Group className="mb-3" controlId="formBasicBranch">
                        <Form.Label>Branch</Form.Label>
                        <Form.Select
                          disabled={data.companyname == ""}
                          name="branch"
                          value={data.branch}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          {branchData.length > 0 ? (
                            branchData
                              .filter((branch) => branch.status == "active")
                              .map((item, index) => (
                                <option key={index} value={item.branchname}>
                                  {item.branchname}
                                </option>
                              ))
                          ) : (
                            <option value="">No Branches available</option>
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row
                    className={
                      data.designationname == "Owner" ||
                      data.designationname == "Sub-Dealer" ||
                      data.designationname == "Franchise"
                        ? "deactivated_icons"
                        : ""
                    }
                  >
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicDepartment"
                      >
                        <Form.Label className="d-flex justify-content-between mt-0">
                          <div>Department</div>
                          <DepartmentMaster />
                        </Form.Label>
                        <Form.Select
                          name="departmentname"
                          value={data.departmentname}
                          onChange={handleChange}
                        >
                          <option value="">--Select--</option>
                          {departmentData.length > 0 ? (
                            departmentData.map((item, index) => (
                              <option key={index} value={item.departmentname}>
                                {item.departmentname}
                              </option>
                            ))
                          ) : (
                            <option value="">No Department available</option>
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      {showroomorNot ? (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicShowroom"
                        >
                          <Form.Label>Showroom</Form.Label>
                          <Form.Select
                            name="showroomname"
                            value={data.showroomname}
                            onChange={handleChange}
                          >
                            <option value="">--Select--</option>
                            <option value="">--Select--</option>
                            {showroomData.length > 0 ? (
                              showroomData.map((item, index) => (
                                <option key={index} value={item.warehousename}>
                                  {item.warehousename}
                                </option>
                              ))
                            ) : (
                              <option value="">No Showroom available</option>
                            )}
                          </Form.Select>
                        </Form.Group>
                      ) : (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicWarehouse"
                        >
                          <Form.Label>Warehouse</Form.Label>
                          <Form.Select
                            name="warehousename"
                            value={data.warehousename}
                            onChange={handleChange}
                          >
                            <option value="">--Select--</option>
                            {godownData.length > 0 ? (
                              godownData.map((item, index) => (
                                <option key={index} value={item.warehousename}>
                                  {item.warehousename}
                                </option>
                              ))
                            ) : (
                              <option value="">No Godown available</option>
                            )}
                          </Form.Select>
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                  <Row
                    className={
                      data.designationname == "Owner" ||
                      data.designationname == "Sub-Dealer" ||
                      data.designationname == "Franchise"
                        ? "deactivated_icons"
                        : ""
                    }
                  >
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicCheck">
                        <div>
                          <Form.Check
                            inline
                            className="form-label"
                            type="radio"
                            label="Warehouse"
                            name="isShowroom"
                            id="warehouse"
                            checked={showroomorNot == false}
                            onChange={() => setShowRoomOrNot(false)}
                          />
                          <Form.Check
                            className="form-label"
                            inline
                            type="radio"
                            label="Showroom"
                            name="isShowroom"
                            id="showroom"
                            checked={showroomorNot == true}
                            onChange={() => setShowRoomOrNot(true)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={handleLeftArrow}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default EditUser;
