import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import FilterField from "../../../constants/FilterField";
import { itemGetItemPost } from "../../../constants/api";
import axios from "axios";
import { initialData } from "../../../constants/resData";
import { useSelector } from "react-redux";

const SuggestedItems = ({ setSuggestedItems }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const loadData = useSelector((state) => state.loadReducer);

  const [data, setData] = useState([]);
  const [brand, setBrand] = useState([{ value: "", text: "" }]);
  const [categories, setCategories] = useState([{ value: "", text: "" }]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    axios
      .post(itemGetItemPost, {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "" || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setData(initialData);
        const uniqueBrands = [...new Set(initialData.map((ele) => ele.brand))];
        setBrand(
          uniqueBrands.map((brand) => {
            return {
              className: "",
              value: brand,
              text: brand,
            };
          })
        );
      })
      .catch((err) => console.log(err));

    //dynamic category generate
    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/category", {

        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || org,
      })

      .then((res) => {
        setCategories(
          res.data &&
            res.data.data.map((cat) => {
              return {
                className: "",
                value: cat.category,
                text: cat.category,
              };
            })
        );
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  const handleRowSelect = (item) => {
    if (
      selectedRows.some((selectedRow) => selectedRow.itemid === item.itemid)
    ) {
      // If the employee is already selected, remove them from selectedRows
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.itemid !== item.itemid)
      );
    } else {
      // If the employee is not selected, add them to selectedRows
      setSelectedRows([...selectedRows, item]);
    }
  };

  const handleAdd = () => {
    setSuggestedItems(selectedRows);
    handleClose();
  };

  const filteredData = !data
    ? []
    : data
        .filter((item) => {
          if (selectedBrand == "") {
            return true;
          } else {
            return item.brand === selectedBrand;
          }
        })
        .filter((item) => {
          if (selectedCategory == "") {
            return true;
          } else {
            return item.category === selectedCategory;
          }
        });

  return (
    <>
      <Button
        className="commonBtn smallBtn cancelBtn mt-2"
        onClick={handleShow}
      >
        Select Item
      </Button>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Suggested <span className="form-title">Items</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={3} md={1}>
              <Col>
                <Form.Label>Brand</Form.Label>
                <FilterField
                  label="Brand"
                  islable={false}
                  emptyValue="Brand Name"
                  selectedFilterValue={selectedBrand}
                  handleSelectedFilter={(e) => setSelectedBrand(e.target.value)}
                  filterWords={brand}
                />
              </Col>
              <Col>
                <Form.Label>Category</Form.Label>
                <FilterField
                  label="Category"
                  islable={false}
                  emptyValue="Category Name"
                  selectedFilterValue={selectedCategory}
                  handleSelectedFilter={(e) =>
                    setSelectedCategory(e.target.value)
                  }
                  filterWords={categories}
                />
              </Col>
              <Col>
                <Form.Label>Items</Form.Label>
                <div className="viewpage-table-container">
                  <Table responsive className="mb-0">
                    <tbody className="table-main-container">
                      {filteredData.length > 0 &&
                        filteredData.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.itemname}</td>
                            <td>
                              <Image
                                src={row.image}
                                fluid
                                thumbnail
                                style={{ width: "100px", height: "50px" }}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.some(
                                  (selectedRow) =>
                                    selectedRow.itemid === row.itemid
                                )}
                                onChange={() => handleRowSelect(row)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <br />
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuggestedItems;
