import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListTable from "./ListTable";
import "../../../styles/Sidebar/Sidebar.css";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
function DayClosure() {

  const navigate = useNavigate()
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];

  let userid = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let org = localData.org;

  const [companyDropData, setCompanyDropData] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [closureDate, setClosureDate] = useState("");

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/master/getcomapanypage`, {

        userid,
        keyword: org,
      })
      .then((res) => {
        setCompanyDropData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSave = () => {
    if (closureDate == "" || selectedCompanyId == "") {
      return notifyError("You have empty fields");
    }

    axios

      .post(`https://erp.venturesathi.co.in/api/v1/master/closuredate`, {

        closuredate: closureDate,
        id: selectedCompanyId,
      })
      .then((res) => {
        if(res.data.message = "Day clousure updated successfully"){
          setClosureDate("")
          setCompanyDropData([])
          return   notifySuccess(res.data.message)
        }
      
      })
      .catch((err) => console.log(err));
  };
  const handleBack = ()=>{
    navigate('/')
  }

  const URL = extractAndFormatURLPart("admin");

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTable />
        </Col>
      </Row>
    </Container>
  );
}

export default DayClosure;
