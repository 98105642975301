import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { notifyError } from "../../../constants/toastAlert";
import sanviLogo from "../../../assets/sanvi_logo.png";
import googlepe from "../../../assets/images/googlepe.png";
import icici from "../../../assets/images/icici.png";
import paymentlogo from "../../../assets/images/paymentlogo.png";
import phonepe from "../../../assets/images/phonepe.png";
import qrcode from "../../../assets/images/qrcode.png";
import upilogo from "../../../assets/images/upilogo.png";
import { useSelector } from "react-redux";
import SwadeshPly from "../../../assets/brands/Swadeshplylogo.png";
import CenturyPly from "../../../assets/brands/Centuryplylogo.png";
import Merino from "../../../assets/brands/merinologo.jpg";
import Hettich from "../../../assets/brands/Hettichlogo.jpg";
import greenply from "../../../assets/brands/Greenplylogo.png";
import Godreg from "../../../assets/brands/Godrejlogo.png";
import Tunes from "../../../assets/brands/tuneslogo.jpg";
import EuroPratik from "../../../assets/brands/Europatriklogo.jpg";
import treelam from "../../../assets/brands/Treelamlogo.jpg";
import AustinPly from "../../../assets/brands/AustinLogo.png";
import VOX from "../../../assets/brands/Voxlogo.jpg";
import RoyaTouche from "../../../assets/brands/RoyaleTouche.png";
import Fevicol from "../../../assets/brands/fevicollogo.png";
import Greenlam from "../../../assets/brands/Greenlamlogo.png";
import WudlayPly from "../../../assets/brands/WudlayPlylogo.jpg";

const ViewQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("sales");
  const contentRef = useRef(null);
  const { companyid, userid, companyname, branchid, name, branch } =
    useSelector((state) => state.authManager.userData.data[0]) || {};

  const [data, setData] = useState({
    id: id,
    status: "",
    quoteno: "",
    customername: "",
    customerid: "",
    baaddress: "",
    bastate: "",
    bacity: "",
    bapincode: "",
    phone: "",
    bdgstno: "",
    quotedate: "",
    saleperson: "",
    project: "",
    createdby: "",
    expiredby: "",
    itemarray: [],
    subtotal: "",
    tax: "",
    tandc: "",
  });

  const fetchData = async () => {
    try {
      const res = await axios.post("https://erp.venturesathi.co.in/api/v1/quote/getbyid", {
        id,
      });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    status,
    quoteno,
    customername,
    customerid,
    baaddress,
    bastate,
    bacity,
    bapincode,
    phone,
    bdgstno,
    quotedate,
    saleperson,
    project,
    createdby,
    expiredby,
    itemarray,
    subtotal,
    tax,
    tandc,
    discountamount,
    adjustment,
    finalamount,
  } = data || {};

  const printStyles = `
  @page {
    size: auto;
    margin: 0;
  }
  body {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .print-content {
    width: 100% !important;
  }
  @media print {
   .payment-icon{
     width:20%;
     height:20%;
   }
   .fontSizeChangeOnPrint{
    font-size:12px;
   }
  }
`;

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <Row>
              <Col>
                <h1>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={() => navigate("/sales/quotation")}
                  />
                  View <span className="form-title">Quotation</span>
                </h1>
              </Col>
              <Col>
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex justify-content-center align-items-center">
                    <h2>Status: </h2>
                    &nbsp;
                    <h2
                      style={{
                        color:
                          status == "Expired"
                            ? "red"
                            : status == "Created"
                            ? "green"
                            : status == "Drafted"
                            ? "blue"
                            : status == "Cancelled"
                            ? "red"
                            : "blue",
                      }}
                    >
                      {status}
                    </h2>
                  </div>
                  <ReactToPrint
                    trigger={() => (
                      <Button className="commonBtn smallBtn cancelBtn px-5">
                        Print
                      </Button>
                    )}
                    content={() => contentRef.current}
                    pageStyle={printStyles}
                  />
                </div>
              </Col>
            </Row>
            <hr />
            <Col ref={contentRef}>
              <div className="print-content">
                <div
                  className="dr-logo-heading  d-flex justify-content-between"
                  style={{ border: "none" }}
                >
                  <div>
                    <img src={sanviLogo} alt="logo" width={100} height={100} />
                    <p style={{ fontSize: "10px" }}>
                      One Stop Solution for Home Interiors
                    </p>
                  </div>
                  <div>
                    <h4>Quotation No: {quoteno ? quoteno : "--"}</h4>
                    <h4>Valid Up to: {expiredby ? expiredby : "--"}</h4>
                    <h4>GSTIN: 21AEYPC5887R1ZH</h4>
                    <h4>PAN: AEYPC5887R</h4>
                  </div>
                  <div>
                    <h4>Phone No: +91 7855074204</h4>
                    <h4>Website: www.saanviavs.com</h4>
                    <h4>Email: info@saanviavs.com</h4>
                    <h4>Mobile App: Saanvi Xperience</h4>
                  </div>
                  <div>
                    <Button
                      className="commonBtn smallBtn w-100 p-2 mb-1"
                      style={{ border: "none" }}
                    >
                      Quotation
                    </Button>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center my-1 px-2 py-1"
                  style={{ border: "1px solid black" }}
                >
                  <h4>Customer Details</h4>
                  <h4>
                    <span>Name:</span>
                    {customername ? customername : "--"}
                  </h4>
                  <h4>
                    <span>Customer ID:</span>
                    {customerid ? customerid : "--"}
                  </h4>
                  <h4>
                    <span>GSTIN:</span>
                    {bdgstno ? bdgstno : "--"}
                  </h4>
                  <h4>
                    <span>Phone:</span>
                    {phone ? phone : "--"}
                  </h4>
                </div>
                <Table responsive className="table-color fs-4">
                  <thead>
                    <tr>
                      <th className="th-color fontSizeChangeOnPrint">SL No</th>
                      <th className="th-color fontSizeChangeOnPrint">
                        Item Name
                      </th>
                      <th className="th-color fontSizeChangeOnPrint">
                        Item ID
                      </th>
                      <th className="th-color fontSizeChangeOnPrint">UOM</th>
                      <th className="th-color fontSizeChangeOnPrint">QTY</th>
                      {data.isshowgst == "" && (
                        <th className="th-color fontSizeChangeOnPrint">
                          Rate(include of tax)
                        </th>
                      )}
                      <th className="th-color fontSizeChangeOnPrint">Rate</th>
                      {data.isshowgst == "Yes" && (
                        <th className="th-color fontSizeChangeOnPrint">GST</th>
                      )}
                      <th className="th-color fontSizeChangeOnPrint">Disc%</th>
                      <th className="th-color fontSizeChangeOnPrint">Amount</th>
                    </tr>
                  </thead> 
                  <tbody style={{ height: "300px" }} className="fs-4">
                    {itemarray.length > 0 ? (
                      itemarray.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td className="fontSizeChangeOnPrint">{ind + 1}</td>
                            <td className="fontSizeChangeOnPrint">
                              {item.itemname}
                            </td>
                            <td className="fontSizeChangeOnPrint">
                              {item.itemid}
                            </td>
                            <td className="fontSizeChangeOnPrint">
                              {item.unit}
                            </td>
                            <td className="fontSizeChangeOnPrint">
                              {item.qty}
                            </td>
                            {data.isshowgst == "" && (
                              <td className="fontSizeChangeOnPrint">
                                {item.rateinclgst}
                              </td>
                            )}
                            <td className="fontSizeChangeOnPrint">
                              {item.rate}
                            </td>
                            {data.isshowgst == "Yes" && (
                              <td className="fontSizeChangeOnPrint">
                                {item.gst}
                              </td>
                            )}
                            <td className="fontSizeChangeOnPrint">
                              {item.discount}
                            </td>
                            <td className="fontSizeChangeOnPrint">
                              {item.amount}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9">
                          <div className="datanotfound-center-text">
                            No Item Found 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tbody>
                    <tr>
                      <td></td>
                      <td className="tddata-right-quote fontSizeChangeOnPrint">
                        Sub Total
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold td-top-border fontSizeChangeOnPrint">
                        {subtotal}
                      </td>
                    </tr>
                    {/*<tr>
                      <td></td>
                      <td className="tddata-right-quote fontSizeChangeOnPrint">
                        Discount
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold td-top-border fontSizeChangeOnPrint">
                        {discountamount ? discountamount : "--"}
                      </td>
                  </tr>*/}
                    {data.isshowgst == "Yes" && (
                      <tr>
                        <td></td>
                        <td className="tddata-right-quote fontSizeChangeOnPrint">
                          Tax
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold fontSizeChangeOnPrint">
                          {tax ? tax : "--"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td className="tddata-right-quote fontSizeChangeOnPrint">
                        Adjustment
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold fontSizeChangeOnPrint">
                        {adjustment ? adjustment : "--"}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="tableContainer">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="tddata-right-quote fontSizeChangeOnPrint">
                        Total
                      </td>
                      <td className="font-bold fontSizeChangeOnPrint">
                        {itemarray.reduce((acc, curr) => {
                          return Number(acc) + Number(curr.qty);
                        }, 0)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold fontSizeChangeOnPrint">
                        {finalamount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <div
                  className="d-flex justify-content-between bill-ship-info page-break"
                  style={{ paddingBottom: "0px" }}
                >
                  <div>
                    <span
                      className="w-100 d-flex justify-content-center"
                      style={{ border: "1px solid black" }}
                    >
                      <h4>Bank Details</h4>
                    </span>
                    <div className="w-100 d-flex justify-content-between">
                      <div>
                        <h4 className="fontSizeChangeOnPrint">
                          Bank:- INDIAN BANK{" "}
                        </h4>
                        <h4 className="fontSizeChangeOnPrint">
                          Account in the name of : SAANVI ENTERPRISES
                        </h4>
                        <h4 className="fontSizeChangeOnPrint">
                          Account Number : 6832928177{" "}
                        </h4>
                        <h4 className="fontSizeChangeOnPrint">
                          IFSC Code : IDIB000R0206{" "}
                        </h4>
                        <h4 className="fontSizeChangeOnPrint">
                          Branch : UDITHNAGAR, ROURKELA, ODISHA{" "}
                        </h4>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img src={qrcode} alt="logo" width={100} height={100} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="w-100 d-flex justify-content-center align-items-center px-1 py-1">
                      <h4 className="font-weight-bold">Term and conditions</h4>
                    </div>
                    <p>
                      “This quotation is valid for a period of 15 Days, beyond
                      which the quotation will become null and void.”
                    </p>
                    <hr />
                    <div>
                      <p>
                        <span className="font-weight-bold">
                          For SAANVI Enterprises
                        </span>{" "}
                        (Authorized Signature)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between bill-ship-info page-break">
                  <div>
                    <p
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#ffa000",
                        textAlign: "center",
                        marginTop: "0px",
                      }}
                    >
                      PRODUCTS WE DEAL IN
                    </p>
                    <p>
                      Plywood | Laminate | Veneer | Decorative | Highlighters |
                      Hardware & Fittings | Flooring | Locks | Modular Kitchen
                      Fittings | Exterior HPL Cladding | Soffit | Furniture
                      Handles | Wallpapers | Home Furnishing & Lighting and much
                      more. 
                    </p>
                    <br />
                    <p
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#ffa000",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      Additional Warranty on Products
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#ffa000",
                        textAlign: "center",
                      }}
                    >
                      ASSOCIATED BRANDS
                    </p>
                    <div>
                      <span className="d-flex justify-content-between">
                        <img
                          src={SwadeshPly}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={CenturyPly}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={Merino}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={Hettich}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={greenply}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                      </span>
                      <span className="d-flex justify-content-between">
                        <img
                          src={Godreg}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={Tunes}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={EuroPratik}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={treelam}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={AustinPly}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                      </span>
                      <span className="d-flex justify-content-between">
                        <img
                          src={VOX}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={RoyaTouche}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={Fevicol}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={Greenlam}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                        <img
                          src={WudlayPly}
                          alt="logo"
                          width={50}
                          height={50}
                          className="mx-3 mb-1"
                        />
                      </span>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#ffa000",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      ADDITIONAL SERVICES WE OFFER
                    </p>
                    <ul>
                      <li style={{ fontSize: "10px" }}>
                        &bull;VR-based product selection
                      </li>
                      <li style={{ fontSize: "10px" }}>&bull;CNC Cutting</li>
                      <li style={{ fontSize: "10px" }}>
                        &bull;Temple Made from Solid Surface
                      </li>
                      <li style={{ fontSize: "10px" }}>
                        &bull;Modular Kitchen
                      </li>
                      <li style={{ fontSize: "10px" }}>
                        &bull;Technical Support for Assembling & Installation
                      </li>
                      <li style={{ fontSize: "10px" }}>
                        &bull;Doorstep Service
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="bill-ship-info page-break"
                  style={{ textAlign: "center" }}
                >
                  <heading>SUBJECT TO ROURKELA JURISDICTION</heading>
                  <p>This is a Computer Generated Invoice </p>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewQuote;