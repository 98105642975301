import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormControl, Table, Button } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import NewJournal from "./NewJournal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ViewJournal from "./ViewJournal";
// import EditJournal from "./EditJournal";
import { MdEdit, MdModeEditOutline } from "react-icons/md";
import GlobalPagination from "../../../constants/GlobalPagination";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import SearchField from "../../../constants/SearchField";
import { manualJournalPagiData } from "../../../redux/actions/manualjournal.actions";
import ViewJournal from "./ViewJournal";

const ListJournal = () => {
  const navigate = useNavigate();
  // const sessionData = JSON.parse(sessionStorage.getItem("userData"));
  // let siteId = sessionData.data[0].siteid;
  // let userId = sessionData.data[0].userid;
  // let org = sessionData.data[0].org;
  // const sessionDataString = sessionStorage.getItem("finalcialYear");
  // const financialYear = sessionDataString.trim().split("-");

  //state part
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadUseEffect, setLoadUseEffect] = useState(false);

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.manualJournalData.manualjournalPagiData) || 0;
  const filteredData = !data.data ? [] : data.data;

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    status: selectedStatus,
    period: selectedPeriod,
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize
  }

  useEffect(() => {
    setLoading(!loading);
    dispatch(manualJournalPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, selectedStatus, selectedPeriod])

  const today = new Date(); // Current date
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay()); // Start of the week
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Start of the month
  const startOfQuarter = new Date(
    today.getFullYear(),
    Math.floor(today.getMonth() / 3) * 3,
    1
  ); // Start of the quarter
  const startOfYear = new Date(today.getFullYear(), 0, 1); // Start of the year

  //edit
  const handleEdit = (id) => {
    navigate(`/accounting/manualJournal/editJournal/${id}`);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const res = await axios.post(
  //         "http://localhost:8081/manualjournal/allmanualjournalpagi",
  //         {
  //           org: org,
  //           page: currentPage,
  //           pageSize: pageSize,
  //           financialyear:sessionDataString
  //         }
  //       );
  //       setData(res.data.data.length > 0 ? res.data.data : []);
  //       setTotalPagesFromAPI({
  //         totalPages: res.data.totalPages ? res.data.totalPages : 0,
  //         totalRecords: res.data.totalItems ? res.data.totalItems : 0,
  //       });
  //       dispatch(reloadPage(false));
  //     } catch (error) {
  //       console.log("error", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [show, loadData, loadUseEffect, pageSize, currentPage]);

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

   // crud functionality to show and hide create, edit, delete icons start
   const [crud, setCrud] = useState(["c", "d", "u"]);

   useEffect(() => {
     axios

       .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

         userid,
         submodule: "Manual Journal",
         companyid,
       })
       .then((res) => {
         setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
       })
       .catch((err) => console.log(err));
   }, [loadData]);
 
   // crud functionality to show and hide create, edit, delete icons end
 

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={4}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Journals</span>
            </p>
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Status</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>
                <option className="active-status" value="Publish">Publish</option>
                <option className="orange-status" value="Draft">Draft</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Period</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
              >
                <option value="">All</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Quarter">This Quarter</option>
                <option value="This Year">This Year</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
        {crud && crud.includes("c") && <Button className="commonBtn mt-2" onClick={() => navigate("/accounting/manualJournal/newJournal")}>
              New Journal
            </Button>}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Journal No.</th>
              <th>Status</th>
              <th>Note</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="6" />
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const { id, date, journalno, status, note, totaldebits } = item;
                return (<tr key={index}>
                  <td>{date ? formatDateToDDMMYYYY(date) : "--"}</td>
                  <td>{journalno ? journalno : "--"}</td>
                  <td>
                    <span className={status === "Publish" ? "publish-status" : "draft-status"}>
                      {status}
                    </span>
                  </td>
                  <td>{note ? note : "--"}</td>
                  <td>{totaldebits ? totaldebits : "--"}</td>
                  <td className="d-flex justify-content-center">
                    {status == "Publish" ? (
                      <div>
                        <ViewJournal data={item} />
                      </div>
                    ) : (
                      <div>
                        <ViewJournal data={item} />
                      { crud && crud.includes("u") && <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(item.id)}
                        />}
                      </div>
                    )}
                  </td>
                </tr>
                );
              })
            ) : (
              <NoRecordFoud count="6" name="Manual Journal" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListJournal;
