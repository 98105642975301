import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { BsPlusCircle } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { warehouseData } from "../../../redux/actions/warehouse.actions";
import axios from "axios";
import { getPoByid } from "../../../constants/api";
import { notifyError, notifyWarning } from "../../../constants/toastAlert";
import TermsMaster from "./TermsMaster";
import { paymentTermData } from "../../../redux/actions/pt.actions";
import { updatePurchaseOrder } from "../../../redux/actions/purchaseOrder.actions";
import Select from "react-select";
import { customStyles, customStylesItem } from "../../../constants/colorClass";

function EditPurchaseOrder() {
  const { id } = useParams();
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const URL = extractAndFormatURLPart("purchase");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const wareHouseMaster =
    useSelector((state) => state.warehouseData.warehouseData.data) || [];
  const ptMaster = useSelector((state) => state.ptData.ptData);
  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);

  const [data, setData] = useState({
    vendorname: "",
    purchaseorderno: "",
    purchasedate: "",
    deliverytype: "",
    specifytype: "",
    paymentterms: "",
    paymentduedate: "",
    vendorgst: "",
    locationgst: "",
    contactperson: "",
    assignedtype: "",
    deliveryinstruction: "",
    deliverylocation: "",
    specifylocation: "",
    expecteddeliverydate: "",
    assignedby: "",
    address: "",
    deliveryaddress: "",
    igst: "",
    cgst: "",
    sgst: "",
    itemarray: {
      arr: [
        {
          itemname: "",
          itemid: "",
          gst: 0,
          qty: 0,
          rate: 0,
          amount: 0,
          gstamount: "",
          unit: "",
          category: "",
          discount: 0,
          discountamount: 0,
          brand: "",
        },
      ],
    },
    subtotal: 0,
    discount: 0,
    discountamount: 0,
    adjustment: 0,
    tcs: "",
    tcsamount: "",
    finalamount: 0,
    tandc: "",
    company: companyname,
    companyid: companyid,
    branch: branch,
    branchid: branchid,
    userid: userid,
    status: "",
    billedstatus: "",
    month: "",
    financialyear: finalcialYear,
    id: id,
    org: org,
  });

  const handleClose = () => setShow(false);

  const handleAddNewItem = () => {
    const itemObj = {
      itemname: "",
      itemid: "",
      gst: 0,
      qty: 0,
      rate: 0,
      amount: 0,
      gstamount: "",
      unit: "",
      category: "",
      discount: 0,
      discountamount: 0,
      brand: "",
    };

    setData((prev) => ({
      ...prev,
      itemarray: { arr: [...data.itemarray.arr, itemObj] },
    }));
  };

  const handleDeleteRow = (index) => {
    let filteredItem = data.itemarray.arr.filter((item, ind) => ind !== index);

    setData((prevData) => ({
      ...prevData,
      itemarray: {
        arr: filteredItem,
      },
    }));
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;

    const updatedItemArray = data.itemarray.arr.map((item, i) =>
      i == ind ? { ...item, [name]: value, itemname: "" } : item
    );

    setData((prev) => ({
      ...prev,
      itemarray: { arr: updatedItemArray },
    }));

    if (name == "itemname") {
      if (value) {
        const findItem = itemMaster.find((item) => item.itemname == value);
        setData((prevState) => {
          const updatedItemArray = prevState.itemarray.arr.map((item, i) =>
            i === ind
              ? {
                  ...findItem,
                  ...item,
                  [name]: value,
                  itemid: findItem.itemid || "",
                  gst: findItem.gst || 0,
                  unit: findItem.unit || "",
                  category: findItem.category || "",
                }
              : item
          );

          return { ...prevState, itemarray: { arr: updatedItemArray } };
        });
      } else {
        setData((prevState) => {
          const updatedItemArray = prevState.itemarray.arr.map((item, i) =>
            i === ind
              ? {
                  ...item,
                  [name]: value,
                  itemid: "",
                  gst: "",
                  unit: "",
                  category: "",
                }
              : item
          );

          return { ...prevState, itemarray: { arr: updatedItemArray } };
        });
      }
    }
    if (name == "category") {
      const updatedItemArray = data.itemarray.arr.map((item, i) =>
        i == ind
          ? {
              ...item,
              [name]: value,
              brand: "",
              itemname: "",
              unit: "",
              gst: 0,
            }
          : item
      );

      setData((prev) => ({
        ...prev,
        itemarray: { arr: updatedItemArray },
      }));
    }

    if (name == "brand") {
      const updatedItemArray = data.itemarray.arr.map((item, i) =>
        i == ind
          ? { ...item, [name]: value, itemname: "", unit: "", gst: 0 }
          : item
      );

      setData((prev) => ({
        ...prev,
        itemarray: { arr: updatedItemArray },
      }));
    }
  };

  const handleQtyChange = (e, ind) => {
    const { name, value } = e.target;

    const updatedItemArr = data.itemarray.arr.map((item, i) => {
      if (i === ind) {
        if (value) {
          const amount = (Number(value) * Number(item.rate || 0)).toFixed(2);
          const discountamount = (
            Number(amount) -
            (Number(amount) * Number(item.discount || 0)) / 100
          ).toFixed(2);
          return { ...item, qty: value, amount, discountamount };
        } else {
          return { ...item, qty: value, amount: 0, discountamount: 0 };
        }
      } else {
        return item;
      }
    });

    setData((prev) => ({ ...prev, itemarray: { arr: updatedItemArr } }));
  };

  const handleRateChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedItemArr = data.itemarray.arr.map((item, i) => {
      if (i === ind) {
        if (value) {
          const amount = (Number(value) * Number(item.qty || 0)).toFixed(2);
          const discountamount = (
            Number(amount) -
            (Number(amount) * Number(item.discount || 0)) / 100
          ).toFixed(2);
          return { ...item, rate: value, amount, discountamount };
        } else {
          return { ...item, rate: value, amount: 0, discountamount: 0 };
        }
      } else {
        return item;
      }
    });

    setData((prev) => ({ ...prev, itemarray: { arr: updatedItemArr } }));
  };

  const handleDiscountChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedItemArr = data.itemarray.arr.map((item, i) => {
      if (i === ind) {
        if (value) {
          const amount = (
            Number(item.rate || 0) * Number(item.qty || 0)
          ).toFixed(2);
          const discountamount = (
            Number(amount) -
            (Number(amount) * Number(value || 0)) / 100
          ).toFixed(2);
          return { ...item, discount: value, discountamount };
        } else {
          return { ...item, discount: value, discountamount: 0 };
        }
      } else {
        return item;
      }
    });

    setData((prev) => ({ ...prev, itemarray: { arr: updatedItemArr } }));
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name == "vendorname") {
      if (value) {
        const findVendor = vendorMaster.find(
          (vendor) => vendor.fullname == value
        );
        setData((prev) => ({
          ...prev,
          vendorname: findVendor.fullname,
          contactperson: findVendor.cpfullname,
          vendorgst: findVendor.gstno || "",
          address: `${findVendor.billingaddress},${findVendor.billingcity},${findVendor.billingstate},${findVendor.billingpincode}`,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          vendorname: "",
          contactperson: "",
          vendorgst: "",
          address: "",
        }));
      }
    } else if (name == "paymentterms") {
      if (value) {
        const findPt = ptMaster.find((pt) => pt.termname == value);
        const dynamicDueDate = new Date(
          data.purchasedate ? data.purchasedate : new Date()
        );
        dynamicDueDate.setDate(dynamicDueDate.getDate() + Number(findPt.days));
        const formattedDate = dynamicDueDate.toISOString().split("T")[0];
        setData((prev) => ({
          ...prev,
          paymentterms: findPt.termname,
          paymentduedate: formattedDate,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          paymentterms: "",
          paymentduedate: "",
        }));
      }
    } else if (name == "specifylocation") {
      if (value) {
        const findWarehouse = wareHouseMaster.find(
          (warehouse) => warehouse.warehousename == value
        );
        const res = await axios.post(
          "https://erp.venturesathi.co.in/api/v1/master/branchbyid",

          { branchid: findWarehouse.branchid }
        );
        const { address, city, state, pin, gstno } = res.data[0] || {};
        const branchGst = gstno || "";
        setData((prev) => ({
          ...prev,
          specifylocation: findWarehouse.warehousename,
          locationgst: branchGst,
          deliveryaddress: `${address},${city},${state},${pin}`,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          specifylocation: "",
          locationgst: "",
          deliveryaddress: "",
        }));
      }
    } else if (name == "discount") {
      if (value) {
        const updatedItemArray = data.itemarray.arr.map((item) => {
          const discountAmount = (Number(item.amount) * Number(value)) / 100;
          const newDiscountAmount =
            Number(item.amount) - Number(discountAmount);
          return { ...item, discountamount: newDiscountAmount };
        });
        setData((prev) => ({
          ...prev,
          itemarray: { arr: updatedItemArray },
        }));
      } else {
        const updatedItemArray = data.itemarray.arr.map((item) => {
          const discountAmount = 0;
          const newDiscountAmount =
            Number(item.amount) - Number(discountAmount);
          return { ...item, discountamount: newDiscountAmount };
        });
        setData((prev) => ({
          ...prev,
          itemarray: { arr: updatedItemArray },
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocationChange = (e) => {
    setData((prev) => ({
      ...prev,
      deliverylocation: e.target.value,
      specifylocation: "",
    }));
  };

  const handleSave = () => {
    if (
      !data.vendorname ||
      !data.purchaseorderno ||
      !data.purchasedate ||
      !data.deliverylocation ||
      !data.deliverytype
    ) {
      setShowEmptyError(true);
      return notifyError("You have Empty fields");
    }

    dispatch(updatePurchaseOrder({ ...data, status: "Approved" }, navigate));
  };

  const handleDraft = () => {
    dispatch(updatePurchaseOrder({ ...data, status: "Draft" }, navigate));
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
    dispatch(
      warehouseData({ companyid, userid, branchid, keyword: "" }, setLoading)
    );
    dispatch(paymentTermData({ companyid, userid, branchid }, setLoading));
  }, [dispatch, loadData]);

  const fetchData = async () => {
    try {
      const res = await axios.post(getPoByid, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  const fetchCategory = async () => {
    try {
      const res = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/item/category",
        {
          userid,
          branchid,
          companyid,
          keyword: "",
        }
      );
      res.data.data ? setCategoryMaster(res.data.data) : setCategoryMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/getitem", {
        companyid,
        userid,
        branchid,
        keyword: "",
      })
      .then((res) => (res.data.data ? setItemMaster(res.data.data) : []))
      .catch((err) => console.log(err));

    fetchCategory();

    fetchData();
  }, []);

  useEffect(() => {
    let subtotal = data.itemarray.arr
      .reduce((acc, curr) => {
        return Number(acc) + Number(curr.discountamount);
      }, 0)
      .toFixed(2);

    let originalsubtotal = data.itemarray.arr
      .reduce((acc, curr) => {
        return Number(acc) + Number(curr.amount);
      }, 0)
      .toFixed(2);

    let discountAmount = (Number(subtotal) - Number(originalsubtotal)).toFixed(
      2
    );

    const totalGstAmount = data.itemarray.arr
      .reduce((total, currentItem) => {
        const gstAmount =
          (Number(currentItem.discountamount) * Number(currentItem.gst)) / 100;
        return total + gstAmount;
      }, 0)
      .toFixed(2);

    let finalAmount = (
      Number(subtotal) +
      Number(totalGstAmount) +
      Number(data.adjustment)
    ).toFixed(2);
    let decimalPart = finalAmount - Math.floor(finalAmount);

    if (decimalPart > 0.5) {
      finalAmount = Math.ceil(finalAmount);
    } else {
      finalAmount = Math.floor(finalAmount);
    }

    setData((prev) => ({
      ...prev,
      subtotal: subtotal,
      cgst: totalGstAmount / 2,
      sgst: totalGstAmount / 2,
      igst: totalGstAmount,
      finalamount: finalAmount,
    }));
  }, [data.itemarray.arr, data.adjustment]);

  useEffect(() => {
    if (data.purchasedate && data.paymentterms) {
      const findPt = ptMaster.find((pt) => pt.termname == data.paymentterms);
      const dynamicDueDate = new Date(data.purchasedate);
      dynamicDueDate.setDate(dynamicDueDate.getDate() + Number(findPt.days));
      const formattedDate = dynamicDueDate.toISOString().split("T")[0];
      setData((prev) => ({
        ...prev,
        paymentduedate: formattedDate,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        paymentduedate: "",
      }));
    }
  }, [data.purchasedate, data.paymentterms]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/purchase/purchaseorder/")}
              />
              Edit <span className="form-title main"> Purchase Order </span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Vendor <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="vendorname"
                          value={data.vendorname}
                          onChange={handleChange}
                          isInvalid={!data.vendorname && showEmptyError}
                        >
                          <option value="">Select</option>
                          {vendorMaster
                            .filter(
                              (vendor) => vendor.vendorcategory == "Supplier"
                            )
                            .map((vendor) => {
                              return (
                                <option value={vendor.fullname} key={vendor.id}>
                                  {vendor.fullname}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Purchase Order No.<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              name="purchaseorderno"
                              value={data.purchaseorderno}
                              onChange={handleChange}
                              // isInvalid={!data.locationname && showEmptyError}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Purchase Date.<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="purchasedate"
                              value={data.purchasedate}
                              onChange={handleChange}
                              isInvalid={!data.purchasedate && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter Purchase Date
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheck"
                          >
                            <Form.Label>
                              Delivery Location <span className="star">*</span>
                            </Form.Label>
                            <div className="mt-2">
                              <Form.Check
                                inline
                                className="form-label"
                                type="radio"
                                label="Godown"
                                value="Godown"
                                checked={data.deliverylocation === "Godown"}
                                onChange={handleLocationChange}
                                // id="warehouse"
                              />
                              <Form.Check
                                className="form-label"
                                inline
                                type="radio"
                                label="Showroom"
                                value="Showroom"
                                checked={data.deliverylocation === "Showroom"}
                                onChange={handleLocationChange}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              {data.deliverylocation}{" "}
                              <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="specifylocation"
                              value={data.specifylocation}
                              onChange={handleChange}
                              isInvalid={
                                !data.specifylocation && showEmptyError
                              }
                            >
                              <option value="">Select</option>
                              {
                                //
                                wareHouseMaster
                                  .filter(
                                    (warehouse) =>
                                      warehouse.warehousetype ==
                                        data.deliverylocation &&
                                      warehouse.status == "active"
                                  )
                                  .map((warehouse) => (
                                    <option value={warehouse.warehousename}>
                                      {warehouse.warehousename}
                                    </option>
                                  ))

                                // wareHouseMaster.map((warehouse)=><option value={warehouse.warehousename} key={warehouse.id}>{warehouse.warehousename}</option>)
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="d-flex justify-content-between">
                              <div>
                                {" "}
                                Payment Terms <span className="star">*</span>
                              </div>

                              <TermsMaster
                                show={show}
                                setShow={setShow}
                                handleClose={handleClose}
                              />
                            </Form.Label>
                            <Form.Select
                              name="paymentterms"
                              value={data.paymentterms}
                              onChange={handleChange}
                              isInvalid={!data.paymentterms && showEmptyError}
                            >
                              <option value="">Select</option>
                              {ptMaster.map((pt) => {
                                return (
                                  <option value={pt.termname} key={pt.id}>
                                    {pt.termname}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Payment Due Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="paymentduedate"
                              value={data.paymentduedate}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}></Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Contact Person</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          name="contactperson"
                          value={data.contactperson}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Delivery Type <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="deliverytype"
                              value={data.deliverytype}
                              onChange={handleChange}
                              isInvalid={!data.deliverytype && showEmptyError}
                            >
                              <option value="">Select</option>
                              <option value="Ex">Ex</option>
                              <option value="For">For</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Assigned By</Form.Label>
                            <Form.Control
                              type="text"
                              name="assignedby"
                              disabled
                              value={data.assignedby}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Delivery Instructions</Form.Label>
                        <Form.Control
                          type="text"
                          name="deliveryinstruction"
                          value={data.deliveryinstruction}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} lg={3}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Expected Delivery Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="expecteddeliverydate"
                          onChange={handleChange}
                          value={data.expecteddeliverydate}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Col md={12} className="table-main-container">
            <Table>
              <thead className="tableContainer">
                <tr>
                  <th style={{ width: "10%" }}>Category</th>
                  <th style={{ width: "10%" }}>Brand</th>
                  <th style={{ width: "20%" }}>Item Name</th>
                  <th>Unit</th>
                  <th>GST %</th>
                  <th>QTY</th>
                  <th>Rate</th>
                  <th>Discount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.itemarray.arr.length > 0 ? (
                  data.itemarray.arr.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>
                          <Select
                            styles={customStyles}
                            value={{
                              value: item.category,
                              label: item.category,
                            }}
                            onChange={(selectedOption) =>
                              handleTableRowChange(
                                {
                                  target: {
                                    name: "category",
                                    value: selectedOption.value,
                                  },
                                },
                                ind
                              )
                            }
                            options={categoryMaster.map((category) => ({
                              value: category.category,
                              label: category.category,
                            }))}
                            isSearchable
                            placeholder="Select"
                          />
                        </td>
                        <td>
                          <Select
                            styles={customStyles}
                            value={{
                              value: item.brand,
                              label: item.brand,
                            }}
                            onChange={(selectedOption) =>
                              handleTableRowChange(
                                {
                                  target: {
                                    name: "brand",
                                    value: selectedOption.value,
                                  },
                                },
                                ind
                              )
                            }
                            options={[
                              ...new Set(
                                itemMaster
                                  .filter((it) => it.category === item.category)
                                  .map((it) => it.brand)
                              ),
                            ].map((brand) => ({ value: brand, label: brand }))}
                            isSearchable
                            placeholder="Select"
                          />
                        </td>
                        <td>
                          <Select
                            styles={customStylesItem}
                            value={{
                              value: item.itemname,
                              label: item.itemname,
                            }}
                            onChange={(selectedOption) =>
                              handleTableRowChange(
                                {
                                  target: {
                                    name: "itemname",
                                    value: selectedOption.value,
                                  },
                                },
                                ind
                              )
                            }
                            options={itemMaster
                              .filter(
                                (itm) =>
                                  itm.brand == item.brand &&
                                  itm.category == item.category
                              )
                              .map((itemm) => ({
                                value: itemm.itemname,
                                label: itemm.itemname,
                              }))}
                            isSearchable
                            placeholder="Select"
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="unit"
                            disabled
                            value={item.unit}
                            title={item.unit}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="gst"
                            disabled
                            value={item.gst}
                            title={item.gst}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="qty"
                            value={item.qty}
                            title={item.qty}
                            onChange={(e) => handleQtyChange(e, ind)}
                            isInvalid={!item.qty && showEmptyError}
                            min={"0"}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="rate"
                            value={item.rate}
                            title={item.rate}
                            onChange={(e) => handleRateChange(e, ind)}
                            isInvalid={!item.rate && showEmptyError}
                            min={"0"}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            name="discount"
                            value={item.discount}
                            title={item.discount}
                            onChange={(e) => handleDiscountChange(e, ind)}
                            // isInvalid={!item.dis && showEmptyError}
                            min={"0"}
                          />
                        </td>
                        <td className="d-flex gap-2">
                          <Form.Control
                            type="number"
                            // name="amount"
                            value={item.discountamount}
                            title={item.discountamount}
                            disabled
                            // onChange={(e) => handleTableRowChange(e, ind)}
                          />

                          <MdDelete
                            onClick={() => handleDeleteRow(ind)}
                            className="fs-1 text-danger"
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="datanotfound-center-text">
                        Add New Item
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          <Col>
            <div
              className="text-warning fs-3 mb-3 cursor-pointer"
              onClick={handleAddNewItem}
            >
              <BsPlusCircle /> Add New
            </div>
          </Col>

          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Row>
                <Col>
                  <Form.Label>Sub Total:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="itemid"
                    disabled
                    value={data.subtotal}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              {data.vendorgst[0] == data.locationgst[0] &&
              data.vendorgst[1] == data.locationgst[1] ? (
                <Row>
                  <Col>
                    <Form.Label>CGST:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="itemid"
                      disabled
                      value={data.cgst}
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              {data.vendorgst[0] == data.locationgst[0] &&
              data.vendorgst[1] == data.locationgst[1] ? (
                <Row>
                  <Col>
                    <Form.Label>SGST:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="itemid"
                      disabled
                      value={data.sgst}
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              {data.vendorgst[0] != data.locationgst[0] ||
              data.vendorgst[1] != data.locationgst[1] ? (
                <Row>
                  <Col>
                    <Form.Label>IGST:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="itemid"
                      disabled
                      value={data.igst}
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Row>
                <Col>
                  <Form.Label>Adjustment:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="adjustment"
                    onChange={handleChange}
                    value={data.adjustment}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Row>
                <Col>
                  <Form.Label>Final Amount:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="itemid"
                    disabled
                    value={data.finalamount}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col>
              <Row>
                <Col></Col>
                <Col>
                  <Button
                    className="cancelBtn commonBtn"
                    // onClick={handleCalculate}
                  >
                    Calculate
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row> */}
          {/* <br /> */}
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Terms & Conditions</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={4}
                  name="tandc"
                  value={data.tandc}
                  // onChange={handleChange}
                  disabled
                  // isInvalid={data.description === "" && showEmptyError}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={handleDraft}
            >
              Save Draft
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/purchase/purchaseorder/")}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
}

export default EditPurchaseOrder;
