import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../constants/SearchField";

const AddItemModal = ({ data, setData }) => {
  const [show, setShow] = useState(false);

  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};

  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredBy, setFilteredBy] = useState({
    category: "",
    brand: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilteredBy((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setShow(false);
    setFilteredBy((prev) => ({ ...prev, brand: "", category: "" }));
    setData((prev) => ({ ...prev, itemarray: [] }));
  };

  useEffect(() => {
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/getitem", {
        companyid,
        userid,
        branchid,
        keyword: "" || org,
      })
      .then((res) =>
        res.data.data ? setItemMaster(res.data.data) : setItemMaster([])
      )
      .catch((err) => console.log(err));

    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/category", {
        companyid,
        userid,
        branchid,
        keyword: "" || org,
      })
      .then((res) =>
        res.data.data ? setCategoryMaster(res.data.data) : setCategoryMaster([])
      )
      .catch((err) => console.log(err));
  }, []);


  const handleRowSelect = (e, item, index) => {
    if (e.target.checked) {
      setData((prev) => ({ ...prev, itemarray: [...data.itemarray, {...item,qty:0,rate:0,discount:0,amount:0,discountamount:0}] }));
    } else {
      const filteredItemArr = data.itemarray.filter(
        (row) => row.id != item.id
      );
      setData((prev) => ({ ...prev, itemarray: filteredItemArr }));
    }
  };

  const filterData = () => {
    // Apply filters to itemMaster based on filteredBy and searchKeyword
    const filteredItems = itemMaster.filter((item) => {
      // Filter by brand (if brand filter is applied)
      if (filteredBy.brand && filteredBy.brand !== item.brand) {
        return false;
      }
  
      // Filter by category (if category filter is applied)
      if (filteredBy.category && filteredBy.category !== item.category) {
        return false;
      }
  
      // Filter by search keyword (if searchKeyword is provided)
      if (
        searchKeyword &&
        !Object.values(item).some((value) =>
          value
            ? value.toString().toLowerCase().includes(searchKeyword.toLowerCase().trim())
            : false
        )
      ) {
        return false;
      }
  
      return true; // Include item in filtered result
    });
  
    return filteredItems;
  };
  
  // Call filterData to get the filtered data
  const filteredData = filterData();

  return (
    <>
      <Row>
        <span
          className="form-label fs-3 text-right"
          style={{ cursor: "pointer" }}
          onClick={() => setShow(true)}
        >
          Add Item <IoIosAddCircle />
        </span>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="form-title">Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="d-flex align-items-center jusify-content-center">
              <Col>
                <Form.Select
                  name="category"
                  value={filteredBy.category}
                  onChange={handleFilterChange}
                  // isInvalid={!data.vendor && showEmptyError}
                >
                  <option value="">Select Category</option>
                  {categoryMaster.map((category) => (
                    <option value={category.category} key={category.id}>
                      {category.category}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <Form.Select
                  name="brand"
                  value={filteredBy.brand}
                  onChange={handleFilterChange}
                  // isInvalid={!data.vendor && showEmptyError}
                >
                  <option value="">Select Brand</option>
                  {itemMaster
                    ?.filter((item) => item.category == filteredBy.category)
                    .map((item) => (
                      <option value={item.brand} key={item.id}>
                        {item.brand}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col>
                <SearchField
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </Col>
            </Row>
            <br />
            <div
               style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding:"5px 7px",
                height: "250px",
                borderRadius: "3px",
                overflow: "auto",
                scrollbarWidth: "none", // For Firefox
                WebkitScrollbarWidth: "none", // For Chrome and Safari
                msOverflowStyle: "none", // For Internet Explorer and Edge
                // Additional styles to hide scrollbar
                scrollbarColor: "transparent transparent", // For Firefox
                WebkitScrollbarTrack: {
                  boxShadow: "inset 0 0 0 100px transparent", // For Chrome and Safari
                },
              }}
            >
              {filteredData.length > 0
                ? filteredData.map((item,index) => (
                    <div
                      key={item.id}
                      className="d-flex align-items-center justify-content-between my-4 p-4"
                      style={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"}}
                    >
                      <h2 style={{ width: "35%" }}>{item.series}</h2>
                      <h2 style={{ width: "35%" }}>{item.itemname}</h2>
                      <img src={!item.image || !item.image.length>0?"":item.image[0]} alt="No Image" width={100} height={80} style={{borderRadius:"5px"}}/>
                      <input
                        type="checkbox"
                        checked={data.itemarray.some(
                          (row) => item.id == row.id
                        )}
                        onChange={(e) => handleRowSelect(e, item, index)}
                      />
                    </div>
                  ))
                : null}
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className=" commonBtn cancelBtn smallBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button className="commonBtn smallBtn" onClick={()=>setShow(false)}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddItemModal;
