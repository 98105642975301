import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { notifyError } from "../../../constants/toastAlert";
import * as XLSX from "xlsx";

const ViewSalesTarget = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [achiveArr, setAchieveArr] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const {
    name,
    campno,
    campname,
    targetfor,
    validfrom,
    validupto,
    targetarray,
    companyid,
    id,
    checkarr,
  } = item || {};

  const handleClose = () => {
    setShow(false);
    setShowTable(false);
  };

  const handleAchieveExport = () => {
    if (achiveArr.length == 0) {
      return notifyError("No Target found");
    } else {
      const items = achiveArr.map((item, ind) => ({
        "SI NO": ind + 1,
        "Item Name": item.item,
        "Category":item.category,
        "Set By": item.setby,
        "Sales Person": item.salesperson,
        "Target":item.target,
        "Total Achievement": item.totalAchieve,
        "Achivement %": item.achivePercent,
      }));

      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Sales Target Achievement"
      );
      XLSX.writeFile(workbook, "Sales-Target-Achievement.xlsx");
    }
  };

  const handleAchievement = async () => {
    setShowTable(true);
    setAchieveArr((prev) =>
      targetarray.map((target) => ({
        ...target,
        totalAchieve: 0,
        achivePercent: 0,
      }))
    );

    try {
      const res = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/salebill/sellachieve",

        {
          fromdate: validfrom,
          todate: validupto,
          commonname: name,
          companyid,
          type: checkarr,
        }
      );
  
      if (res.data.length > 0) {
        //   const mergedArray = arr1.map((obj, index) => {
        //     return { ...obj, place: arr2[index].place };
        // });

        const mappedAchievements = achiveArr.map((achieve, index) => {
          if (achieve.setBy == "Amount") {
            return {
              ...achieve,
              totalAchieve: res.data[index].total_amount,
              achivePercent:
                (Number(res.data[index].total_amount) * 100) /
                Number(achieve.target),
            };
          } else {
            return {
              ...achieve,
              totalAchieve: res.data[index].total_qty,
              achivePercent:
                (Number(res.data[index].total_qty) * 100) /
                Number(achieve.target),
            };
          }
        });

        setAchieveArr((prev) => mappedAchievements);
      } else {
        setAchieveArr((prev) =>
          targetarray.map((target) => ({
            ...target,
            totalAchieve: 0,
            achivePercent: 0,
          }))
        );
      }
    } catch (error) {
      setAchieveArr((prev) =>
        targetarray.map((target) => ({
          ...target,
          totalAchieve: 0,
          achivePercent: 0,
        }))
      );
    }
  };

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" d-flex w-100 justify-content-between gap-10">
            <div className="form-title ">Sales Target</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Container>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Campaign No:</Col>
                  <Col className="viewpage-value">{campno ? campno : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Campaign Name:</Col>
                  <Col className="viewpage-value">
                    {campname ? campname : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Target For:</Col>
                  <Col className="viewpage-value">
                    {targetfor ? targetfor : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Target To:</Col>
                  <Col className="viewpage-value">{name ? name : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Valid From:</Col>
                  <Col className="viewpage-value">
                    {validfrom ? validfrom : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Valid Upto:</Col>
                  <Col className="viewpage-value">
                    {validupto ? validupto : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <br />
            <Col md={12} className="table-main-container">
              <Table responsive>
                <thead className="tableContainer">
                  <tr className="bg-secondary">
                    <th>SI No.</th>
                    {checkarr.includes("Sales Person") ? (
                      <th>Sales Person</th>
                    ) : null}
                    {checkarr.includes("Category") ? <th>Category</th> : null}
                    {checkarr.includes("Item") ? <th>Item</th> : null}
                    <th>Set By</th>
                    <th>Target</th>
                  </tr>
                </thead>
                <tbody>
                  {targetarray.length > 0 ? (
                    targetarray.map((item, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{ind + 1}</td>
                          {checkarr.includes("Sales Person") ? (
                            <td>{item.salesperson}</td>
                          ) : null}
                          {checkarr.includes("Category") ? (
                            <td>{item.category}</td>
                          ) : null}
                          {checkarr.includes("Item") ? (
                            <td>{item.item}</td>
                          ) : null}
                          <td>{item.setby}</td>
                          <td>{item.target}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="datanotfound-center-text">
                          No target Found 🤔
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <br />
            {showTable ? (
              <Col md={12} className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr className="bg-secondary">
                      <th>SI No.</th>
                      {checkarr.includes("Sales Person") ? (
                        <th>Sales Person</th>
                      ) : null}
                      {checkarr.includes("Category") ? <th>Category</th> : null}
                      {checkarr.includes("Item") ? <th>Item</th> : null}
                      <th>Set By</th>
                      <th>Target</th>
                      {showTable ? <th>Total Achievement</th> : null}
                      {showTable ? <th>Achievement %</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {achiveArr?.map((target, ind) => (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        {checkarr.includes("Sales Person") ? (
                          <td>{target.salesperson}</td>
                        ) : null}
                        {checkarr.includes("Category") ? (
                          <td>{target.category}</td>
                        ) : null}
                        {checkarr.includes("Item") ? (
                          <td>{target.item}</td>
                        ) : null}
                        <td>{target.setby}</td>
                        <td>{target.target}</td>
                        {showTable ? <td>{target.totalAchieve}</td> : null}
                        {showTable ? <td>{target.achivePercent}</td> : null}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-start gap-2 mt-2"
          style={{ borderTop: "0px" }}
        >
          <div className="d-flex gap-3">
            {/* <Button className="commonBtn cancelBtn px-5"> Export </Button> */}
            {!showTable ? (
              <Button className="commonBtn  px-5" onClick={handleAchievement}>
                Achievement
              </Button>
            ) : (
              <Button className="commonBtn  px-5" onClick={handleAchieveExport}>
                Export
              </Button>
            )}

            <Button className="commonBtn cancelBtn px-5" onClick={handleClose}>
              Back
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewSalesTarget;
