import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import { MdModeEditOutline } from "react-icons/md";
import {
  deletePurchaseTargetData,
  purchaseTargetData,
} from "../../../redux/actions/purchaseTarget.actions";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import ViewPurchaseTarget from "./ViewPurchaseTarget";
import axios from "axios";

const ListTablePurchaseTarget = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const data = useSelector(
    (state) => state.purchaseTargetData.purchaseTargetData
  );
  const loadData = useSelector((state) => state.loadReducer);

  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const branchid = userData[0].branchid;
  const org = userData[0].org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: searchKeyword || "₹ΘθϖΠ",
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(purchaseTargetData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  //search logic

  const handleShow = (item) => {
    const { id, schemeno } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(schemeno);
  };

  const handleEdit = (id) => {
    navigate(`/purchase/purchaseTarget/editPurchaseTarget/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      deletePurchaseTargetData(
        {
          id: selectedItemId,
          schemeno: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };


  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Purchase Target",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end


  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Purchase Target</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() =>
                  navigate("/purchase/purchaseTarget/addPurchaseTarget")
                }
              >
                Set Target
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Scheme No</th>
              <th>Scheme Name</th>
              <th>Vendor Name</th>
              <th>Created For</th>
              <th>Valid From</th>
              <th>Valid Upto</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  vendorname,
                  schemeno,
                  schemename,
                  createdfor,
                  validfrom,
                  validupto,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!schemeno ? "--" : schemeno}</td>
                    <td>{!schemename ? "--" : schemename}</td>
                    <td>{!vendorname ? "--" : vendorname}</td>
                    <td>{!createdfor ? "--" : createdfor}</td>
                    <td>{!validfrom ? "--" : validfrom}</td>
                    <td>{!validupto ? "--" : validupto}</td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <ViewPurchaseTarget item={item} />
                      <div>
                        {crud && crud.includes("u") && (
                          <MdModeEditOutline
                            className="editIcon mr-2"
                            onClick={() => handleEdit(item.id)}
                          />
                        )}
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            name={"Purchase Target"}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTablePurchaseTarget;
