import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import * as XLSX from "xlsx";
import { notifyError } from "../../../constants/toastAlert";
const ViewPurchaseTarget = ({ item }) => {
  const [show, setShow] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setShowTable(false);
    setachieveMents([]);
  };
  const {
    vendorname,
    schemeno,
    schemename,
    createdfor,
    frequency,
    validfrom,
    validupto,
    targetarray,
    branchid,
    companyid,
    userid,
    org,
    financialyear,
    id,
    targettype,
  } = item || {};

  const [achiveMents, setachieveMents] = useState([]);

  const handleAchievement = async () => {
    let type = targettype.toLowerCase();
    try {
      // Send a POST request to the server to fetch achievement data
      let res = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/purchasebill/purchaseachieve",

        {
          vendor: vendorname,
          fromdate: validfrom,
          todate: validupto,
          companyid: companyid,
          type: type,
        }
      );

      // Check if the response data is empty
      if (res.data.length === 0) {
        // If no data is returned, set achievements to default values
        const mappedAchievements = targetarray.map((achievement) => ({
          ...achievement,
          achievement: 0,
          totalAchieved: 0,
        }));
        setachieveMents(mappedAchievements);
      } else {
        // If data is returned, map achievements based on matching criteria
        const mappedAchievements = targetarray.map((item) => {
          const matchedItem1 = res.data.find(
            (dataItem) => dataItem.category === item.category
          );
          const matchedItem2 = res.data.find(
            (dataItem) => dataItem.itemname === item.item
          );
          if (matchedItem1) {
            // Calculate achievement based on the matched item
            const totalAchieved =
              item.setby === "Qty"
                ? Number(matchedItem1.total_qty)
                : Number(matchedItem1.total_amount);

            return {
              ...item,
              totalAchieved: totalAchieved,
              achievement: (totalAchieved * 100) / Number(item.tier1),
            };
          } else if (matchedItem2) {
            const totalAchieved =
              item.setby === "Qty"
                ? Number(matchedItem1.total_qty)
                : Number(matchedItem1.total_amount);

            return {
              ...item,
              totalAchieved: totalAchieved,
              achievement: (totalAchieved * 100) / Number(item.tier1),
            };
          } else {
            // If no matching item is found, set achievement to default values
            return {
              ...item,
              achievement: 0,
              totalAchieved: 0,
            };
          }
        });
        setachieveMents(mappedAchievements);
      }
    } catch (error) {
      // Handle errors by setting achievements to an empty array
      console.error("Error occurred while fetching achievements:", error);
      setachieveMents([]);
    }
    // Show the table after processing achievements
    setShowTable(true);
  };

  const handleAchieveExport = () => {
    if (achiveMents.length == 0) {
      return notifyError("No Target found");
    } else {
      const items = achiveMents.map((item, ind) => ({
        "SI NO": ind + 1,
        "Item/Category": targettype == "Category" ? item.category : item.item,
        Brand: item.brand,
        "Set By": item.setby,
        "Tier I": item.tier1,
        "Tier II": item.tier2,
        "Tier III": item.tier3,
        "Total Achieved": item.totalAchieved,
        "Achivement %": item.achievement,
      }));

      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Purchae Target Achievement"
      );
      XLSX.writeFile(workbook, "Purchae-Target-Achievement.xlsx");
    }
  };

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" d-flex w-100 justify-content-between gap-10">
            <div className="form-title ">Purchase Target</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Container>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Scheme No:</Col>
                  <Col className="viewpage-value">
                    {schemeno ? schemeno : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Scheme Name:</Col>
                  <Col className="viewpage-value">
                    {schemename ? schemename : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Vendor Name:</Col>
                  <Col className="viewpage-value">
                    {vendorname ? vendorname : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Created For:</Col>
                  <Col className="viewpage-value">
                    {createdfor ? createdfor : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Valid From:</Col>
                  <Col className="viewpage-value">
                    {validfrom ? validfrom : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Valid Upto:</Col>
                  <Col className="viewpage-value">
                    {validupto ? validupto : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Purchase Target On:</Col>
                  <Col className="viewpage-value">
                    {targettype ? targettype : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title"></Col>
                  <Col className="viewpage-value"></Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <br />
            <Col md={12} className="table-main-container">
              <Table responsive>
                <thead className="tableContainer">
                  <tr className="bg-secondary">
                    <th>SI No.</th>
                    <th>{targettype}</th>
                    <th>Brand</th>
                    <th>Set By</th>
                    <th>Tier I</th>
                    <th>Tier II</th>
                    <th>Tier III</th>
                  </tr>
                </thead>
                <tbody>
                  {item.targetarray.length > 0 ? (
                    item.targetarray.map((item, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{ind + 1}</td>

                          {targettype == "Category" ? (
                            <td>{item.category}</td>
                          ) : (
                            <td>{item.item}</td>
                          )}
                          <td>{item.brand}</td>
                          <td>{item.setby}</td>
                          <td>{item.tier1}</td>
                          <td>{item.tier2}</td>
                          <td>{item.tier3}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="datanotfound-center-text">
                          No Item Found 🤔
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <br />
            <br />
            {showTable ? (
              <Col md={12} className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr className="bg-secondary">
                      <th>SI No.</th>
                      <th>{targettype}</th>
                      <th>Brand</th>
                      <th>Set By</th>
                      <th>Tier I</th>
                      <th>Tier II</th>
                      <th>Tier III</th>
                      <th>Total Achievement</th>
                      <th>Achievements %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {achiveMents?.map((achive, ind) => (
                      <tr key={ind}>
                        <td>{ind + 1}</td>

                        {targettype == "Category" ? (
                          <td>{achive.category}</td>
                        ) : (
                          <td>{achive.item}</td>
                        )}
                        <td>{achive.brand}</td>
                        <td>{achive.setby}</td>
                        <td>{achive.tier1}</td>
                        <td>{achive.tier2}</td>
                        <td>{achive.tier3}</td>
                        <td>{achive.totalAchieved}</td>
                        <td>{achive.achievement}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}
          </Container>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-start gap-2 mt-2"
          style={{ borderTop: "0px" }}
        >
          <div className="d-flex gap-3">
            {/* <Button className="commonBtn cancelBtn px-5"> Export </Button> */}
            {!showTable ? (
              <Button className="commonBtn  px-5" onClick={handleAchievement}>
                Achievement
              </Button>
            ) : (
              <Button className="commonBtn  px-5" onClick={handleAchieveExport}>
                Export
              </Button>
            )}

            <Button className="commonBtn cancelBtn px-5" onClick={handleClose}>
              Back
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewPurchaseTarget;
