import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
// import EditUser from "./EditUser";
import { MdModeEditOutline } from "react-icons/md";

const ListTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyid = localData.companyid;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [itemId, setItemid] = useState("");

  const handleShow = (id) => {
    setShow(true);
    setItemid(id);
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

        userid: userID,
        submodule: "Day Closure",
        companyid: companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 && res.data[0].crud);
      })
      .catch((err) => console.log(err));

    // get all companyis to show in table
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/master/getcomapanypage`, { keyword: "₹ΘθϖΠ" })

      .then((res) => {
        setData(res.data.data.length > 0 && res.data.data)
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  const handleAddTermNavigate = () => {
    navigate(`/admin/adddayclosure`);
  };
  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Day Closure details</span>
            </p>
          </Col>
          <Col lg={2}>
            {/* <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            /> */}
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={handleAddTermNavigate}
              >
                Add Day Closure
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
            <th>Company name</th>
              <th>Date</th>
             
              {/* <th>Type</th>
              <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {loadData ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item, index) => (
                <tr
                  key={index}
                  className={item.status == "In-Active" ? "deactivated" : ""}
                >
                    <td>{item.companyname}</td>
                  <td>{item.closuredate}</td>
                
                  {/* <td>{item.type}</td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListTable;
