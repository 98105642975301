import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import { salesInvoicePagiData } from "../../../../redux/actions/salesInvoice.actions";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../../constants/toastAlert";

function AddCreditNoteInv() {
  const location = useLocation();

  const URL = extractAndFormatURLPart("einvoice");
  const navigate = useNavigate();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  const { invoiceno } = location.state || {};
  let financialyear = localStorage.financialyear;
  // const financialyear = financialyearString;
  const [loading, setLoading] = useState(false);
  const loadData = useSelector((state) => state.loadReducer);

  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const {
    companyname: lscompany,
    state: lsstate,
    city: lscity,
    pincode: lspincode,
    gstno: lsgstno,
    pan: lspan,
    hoaddress: lshoaddress,
  } = useSelector((state) => state.authManager.userData.companyaddress) || {};
  const salesInvoiceMaster =
    useSelector((state) => state.salesInvoiceData.salesInvoicePagiData.data) ||
    [];
  // const invoiceno = location.state.invoiceno;
  //   States

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const [data, setData] = useState({
    supplyType: "O",
    subSupplyType: "",
    docNo: "",
    docDate: getTodayDate(), //
    fromGstin: lsgstno || "",
    fromTrdName: name || "",
    fromAddr1: lshoaddress || "",
    fromAddr2: "",
    fromPlace: lscity || "",
    fromPincode: lspincode || "",
    fromStateCode: lsgstno ? `${lsgstno[0]}${lsgstno[1]}` : "",
    toGstin: "",
    toTrdName: "",
    toAddr1: "",
    toAddr2: "",
    toPlace: "",
    toPincode: 0,
    toStateCode: "",
    transactionType: 0,
    dispatchFromGSTIN: lsgstno || "",
    dispatchFromTradeName: companyname || "",
    shipToGSTIN: "",
    shipToTradeName: "",
    totalValue: 0,
    cgstValue: 0,
    sgstValue: 0,
    igstValue: 0,
    cessValue: 0,
    cessNonAdvolValue: 0,
    totInvValue: 0,
    transMode: "",
    transDistance: "",
    transporterName: "",
    transporterId: "",
    transDocNo: "",
    vehicleNo: "",
    vehicleType: "",
    itemList: [],
    companyid,
    financialyear,
  });
  const [showEmptyError, setShowEmptyError] = useState(false);

  useEffect(() => {
    if (invoiceno !== undefined) {
      // Call handleChange function when invoiceno is not undefined
      handleChange({ target: { name: "docNo", value: invoiceno } });
    }
  }, [invoiceno]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name == "docNo") {
      if (value) {
        const findSaleInvoice = salesInvoiceMaster.find(
          (si) => si.invoiceno == value
        );
        // console.log(salesInvoiceMaster, "salesInvoiceMaster")

        const { bdgstno, bdowner, bdcompany } = (findSaleInvoice &&
          findSaleInvoice.commonnamealldata &&
          findSaleInvoice.commonnamealldata.length > 0 &&
          findSaleInvoice.commonnamealldata[0]) || {
          bdgstno: "",
          bdcompany: "",
        };
        const itemList =
        findSaleInvoice &&  findSaleInvoice.itemarray.length > 0
            ? findSaleInvoice.itemarray.map((item, index) => ({
                SlNo: `${index + 1}`,
                IsServc: "N",
                PrdDesc: item.itemname,
                // productDesc: item.itemdescription,

                HsnCd: item.hsncode,
                Barcde: null,
                Qty: +item.qty,
                Unit: item.unit,
                UnitPrice: (+item.rate).toFixed(2),
                TotAmt: +(item.qty * item.rate).toFixed(2),
                Discount: +item.discount,
                AssAmt: +(+item.qty * item.rate - +item.discount).toFixed(2),
                GstRt: +item.gst,
                SgstAmt: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                IgstAmt: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? 0
                    : (Number(item.amount) * Number(item.gst)) / 100
                ).toFixed(2),
                CgstAmt: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                TotItemVal: +(
                  +item.qty * item.rate -
                  +item.discount +
                  (!findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0) +
                  (!findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? 0
                    : (Number(item.amount) * Number(item.gst)) / 100) +
                  (!findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0)
                ).toFixed(2),
              }))
            : [];

        setData((prev) => ({
          ...prev,
          toGstin: bdgstno,
          toTrdName: findSaleInvoice && findSaleInvoice.commonname,
          shipToTradeName: bdcompany,
          shipToGSTIN: bdgstno,
          toAddr1:findSaleInvoice &&  findSaleInvoice.staddress,
          toPlace:findSaleInvoice &&  findSaleInvoice.stcity,
          toPincode: findSaleInvoice && findSaleInvoice.stpin,
          toStateCode: bdgstno ? `${bdgstno[0]}${bdgstno[1]}` : "",
          itemList: itemList,
          cgstValue: Number(findSaleInvoice && findSaleInvoice.cgst),
          sgstValue: Number(findSaleInvoice && findSaleInvoice.sgst),
          igstValue: Number(findSaleInvoice && findSaleInvoice.igst),
          totalValue: Number(findSaleInvoice && findSaleInvoice.subtotal),
          totInvValue: Number(findSaleInvoice && findSaleInvoice.grandtotal),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          toGstin: "",
          toTrdName: "",
          shipToGSTIN: "",
          shipToTradeName: "",
          toAddr1: "",
          toPlace: "",
          toPincode: "",
          toStateCode: "",
          itemList: [],
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          totalValue: 0,
          totInvValue: 0,
        }));
      }
    }
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;

    // Determine if the input is a numeric field
    const isNumericField = [
      "hsnCode",
      "quantity",
      "taxableAmount",
      "sgstRate",
      "cgstRate",
      "igstRate",
      "cessRate",
    ].includes(name);

    // Convert the value to a number if it is a numeric field
    const newValue = isNumericField ? parseFloat(value) : value;

    // Update the state with a new array
    setData((prevState) => {
      // Copy the previous state
      const updatedItemList = [...prevState.itemList];

      // Update the specific field of the item at the given index
      updatedItemList[index] = {
        ...updatedItemList[index],
        [name]: newValue,
      };

      // Return the new state with updated itemList
      return {
        ...prevState,
        itemList: updatedItemList,
      };
    });
  };

  const handleSubmit = () => {
    if (
      // !data.supplyType ||
      // !data.subSupplyType ||
      !data.docNo ||
      !data.docDate
      // !data.transactionType ||
      // !data.transMode ||
      // !data.transDistance ||
      // !data.vehicleType ||
      // !data.vehicleNo ||
      // !data.transporterId ||
      // !data.transDocNo
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    } else {
      axios
        .post(`https://erp.venturesathi.co.in/einvoice/createcrn`, data)
        .then((res) => {
          if (res.data == "GSTR request succeeds") {
            notifySuccess("Credit Note Generated Successfully");
            return navigate("/einvoice");
          } else {
            notifyWarning(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleLeftArrow = () => {
    navigate("/einvoice");
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      salesInvoicePagiData(
        { userid, branchid, companyid, keyword: org },
        setLoading
      )
    );
  }, [dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Add <span className="form-title main"> Credit Note</span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="docNo">
                          <Form.Label>
                            Document Number <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            name="docNo"
                            value={data.docNo}
                            onChange={handleChange}
                            isInvalid={!data.docNo && showEmptyError}
                          >
                            <option value="">Select Document No</option>
                            {salesInvoiceMaster?.map((si) => (
                              <option value={si.invoiceno} key={si.id}>
                                {si.invoiceno}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="docDate">
                          <Form.Label>
                            Document Date <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="docDate"
                            value={`${data.docDate.split("/")[2]}-${
                              data.docDate.split("/")[1]
                            }-${data.docDate.split("/")[0]}`}
                            // onChange={handleChange}
                            disabled
                            isInvalid={!data.docDate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <hr />
                <Row className="my-2">
                  <Col md={6}>
                    <h3>Supplier Details</h3>
                  </Col>
                  <Col md={6}>
                    <h3>Customer Details</h3>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <h4 className="font-bold">From</h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="font-bold">To</h4>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Name</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromTrdName ? data.fromTrdName : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Name</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toTrdName ? data.toTrdName : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">GSTIN</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromGstin ? data.fromGstin : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">GSTIN</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toGstin ? data.toGstin : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <h4 className="font-bold">Dispatch From</h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="font-bold">Ship To</h4>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Address</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromAddr1}</h5>
                        <h5>
                          {lsstate}
                          {lsstate ? "," : ""}
                          {lscity}
                          {lscity ? "," : ""}
                          {lspincode}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Address</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toAddr1}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Location</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromPlace ? data.fromPlace : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Location</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toPlace ? data.toPlace : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Pin Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromPincode ? data.fromPincode : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">Pin Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toPincode ? data.toPincode : "-"}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">State Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.fromStateCode}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <h4 className="view-page-title">State Code</h4>
                      </Col>
                      <Col md={9}>
                        <h5>{data.toStateCode}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />

                <br />
                <hr />

                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group controlId="cgstValue">
                          <Form.Label>CGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="cgstValue"
                            value={data.cgstValue}
                            onChange={handleChange}
                            // placeholder="Enter CGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="sgstValue">
                          <Form.Label>SGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="sgstValue"
                            value={data.sgstValue}
                            onChange={handleChange}
                            // placeholder="Enter SGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="igstValue">
                          <Form.Label>IGST Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="igstValue"
                            value={data.igstValue}
                            onChange={handleChange}
                            // placeholder="Enter IGST Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="totalValue">
                          <Form.Label>Total Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="totalValue"
                            value={data.totalValue}
                            onChange={handleChange}
                            // placeholder="Enter Total Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="totInvValue">
                          <Form.Label>Total Invoice Value</Form.Label>
                          <Form.Control
                            type="number"
                            name="totInvValue"
                            value={data.totInvValue}
                            onChange={handleChange}
                            // placeholder="Enter Total Invoice Value"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <hr></hr>
              <Container fluid>
                {data.itemList.map((item, index) => (
                  <Row key={index}>
                    <Col>
                      <Form.Group controlId={`PrdDesc`}>
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          name={`PrdDesc`}
                          value={item.PrdDesc}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Product Name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`PrdDesc`}>
                        <Form.Label>Product Desc</Form.Label>
                        <Form.Control
                          type="text"
                          name={`PrdDesc`}
                          value={item.PrdDesc}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Product Description"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`HsnCd`}>
                        <Form.Label>HSN Code</Form.Label>
                        <Form.Control
                          type="number"
                          name={`HsnCd`}
                          value={item.HsnCd}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter HSN Code"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`Qty`}>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          name={`Qty`}
                          value={item.Qty}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Quantity"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`Unit`}>
                        <Form.Label>Qty Unit</Form.Label>
                        <Form.Control
                          type="text"
                          name={`Unit`}
                          value={item.Unit}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Qty Unit"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`SgstAmt`}>
                        <Form.Label>SGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`SgstAmt`}
                          value={item.SgstAmt}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter SGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`CgstAmt`}>
                        <Form.Label>CGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`CgstAmt`}
                          value={item.CgstAmt}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter CGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`IgstAmt`}>
                        <Form.Label>IGST Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name={`IgstAmt`}
                          value={item.IgstAmt}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter IGST Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId={`TotItemVal`}>
                        <Form.Label>Total Item value</Form.Label>
                        <Form.Control
                          type="number"
                          name={`TotItemVal`}
                          value={item.TotItemVal}
                          onChange={(e) => handleItemChange(index, e)}
                          // placeholder="Enter Cess Rate"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ))}
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSubmit}>
            Create
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleLeftArrow}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
}

export default AddCreditNoteInv;
