import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { MdModeEditOutline } from "react-icons/md";
import SearchField from "../../../constants/SearchField";
import GlobalPagination from "../../../constants/GlobalPagination";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import {
  deleteStockData,
  statusChangeStock,
  stockData,
} from "../../../redux/actions/stocklocation.actions";
import axios from "axios";
// import ViewModal from '../../../constants/ViewModal';

const ListTableStock = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Tooltip
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, warehouseid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.stockData.stockData);
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    warehouseid: warehouseid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(stockData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleSwitchChange = (e, id, status) => {
    dispatch(statusChangeStock({ id: id, status: status }));
  };

  const handleShow = (item) => {
    const { id, locationid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(locationid);
  };

  const handleEdit = (id) => {
    navigate(`/admin/stockLocation/editStockLocation/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteStockData(
        {
          id: selectedItemId,
          locationid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);


  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Stock Location",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end


  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Stock Location</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() =>
                  navigate("/admin/stockLocation/addStockLocation")
                }
              >
                Add Stock Location
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Location ID</th>
              <th>Room No</th>
              <th>Rack No</th>
              <th>Location Type</th>
              <th>Warehouse</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  locationtype,
                  roomno,
                  rakeno,
                  id,
                  locationid,
                  status,
                  warehouse,
                  remarks,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!locationid ? "--" : locationid}</td>
                    <td>{!roomno ? "--" : roomno}</td>
                    <td>{!rakeno ? "--" : rakeno}</td>
                    <td>{!locationtype ? "--" : locationtype}</td>
                    <td>{!warehouse ? "--" : warehouse}</td>
                    <td>{!remarks ? "--" : remarks}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip("Active/Inactive")}
                      >
                        <Form.Check
                          type="switch"
                          // id="custom-switch"
                          defaultChecked={status === "active"}
                          // className="custom-switch"
                          onChange={(e) =>
                            handleSwitchChange(
                              e,
                              id,
                              status == "active" ? "inactive" : "active"
                            )
                          }
                        />
                      </OverlayTrigger>

                      {/* <ViewModal title={"Stock Location"} item={item}/> */}
                      {crud && crud.includes("u") && (
                        <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(item.id)}
                        />
                      )}

                      {crud && crud.includes("d") && (
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={handleClose}
                          handleDelete={handleDelete}
                          name={"Stock Location"}
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableStock;
