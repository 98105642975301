import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { tdsData } from "../../../redux/actions/tds.actions";
import { tcsData } from "../../../redux/actions/tcs.actions";
import { getPurchaseBillById, getReceiveGood } from "../../../constants/api";
import { notifyError } from "../../../constants/toastAlert";
import axios from "axios";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { purchaseOrderData } from "../../../redux/actions/purchaseOrder.actions";
import { paymentTermData } from "../../../redux/actions/pt.actions";
import { updatePurchaseBills } from "../../../redux/actions/purchaseBills.actions";
import { MdDelete, MdModeEdit } from "react-icons/md";
import AddItemModal from "./AddItemModal";

const EditPurchaseBills = () => {
  const { id } = useParams();
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const URL = extractAndFormatURLPart("purchase");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEditInvoice, setIsEditInvoice] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const wareHouseMaster =
    useSelector((state) => state.warehouseData.warehouseData.data) || [];
  const ptMaster = useSelector((state) => state.ptData.ptData);
  const { data: coaAllData } =
    useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const [itemMaster, setItemMaster] = useState([]);
  const [receiveGoodsMaster, setReceiveGoodsMaster] = useState([]);

  const tdsMaster = useSelector((state) => state.tdsData.tdsData.data) || [];
  const tcsMaster = useSelector((state) => state.tcsData.tcsData.data) || [];
  const purchaseOrderMaster =
    useSelector((state) => state.purchaseOrderData.purchaseOrderData.data) ||
    [];

  const [data, setData] = useState({
    invoiceno: "",
    grn: "",
    purchasebillno: "",
    purchasedate: "",
    billdate: "",
    paymentduedate: "",
    paymentterms: "",
    vehicletype: "",
    vehicleno: "",
    purchaseorder: "",
    itemarray: [],
    documents: "",
    subtotal: 0,
    others: "",
    taxableamount: 0,
    ewaybill: "",
    cgst: 0,
    igst: 0,
    sgst: 0,
    discount: 0,
    discountamount: 0,
    branch: branch,
    branchid: branchid,
    company: companyid,
    companyid: companyid,
    userid: userid,
    month: "",
    financialyear: finalcialYear,
    tcs: "",
    tds: "",
    tsamount: 0,
    fadvance: 0,
    adjustment: 0,
    adjustmentamont: 0,
    grandtotal: 0,
    vendoradvance: 0,

    finalamount: 0,
    uploads: [],
    org: "₹ΘθϖΠ",
    deliveryaddress: "",
    vendor: "",
    status: "",
    notes: "",
    taxtype: "",
    vendorgst: "",
    locationgst: "",
    address: "",
    id: id,
    account: "Purchases of Stores and spares",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name == "tds") {
      setData((prev) => ({ ...prev, tds: "", tsamount: 0 }));

      if (value) {
        let findTds = tdsMaster.find((tds) => tds.taxname == value);
        const tsamount = (
          (Number(data.taxableamount) * Number(findTds.ratepercent)) /
          100
        ).toFixed(2);
        setData((prev) => ({ ...prev, tds: value, tsamount: `-${tsamount}` }));
      } else {
        setData((prev) => ({ ...prev, tds: "", tsamount: 0 }));
      }
    }

    if (name == "tcs") {
      setData((prev) => ({ ...prev, tcs: "", tsamount: 0 }));

      if (value) {
        let findTcs = tcsMaster.find((tcs) => tcs.taxname == value);
        const tsamount = (
          (Number(data.taxableamount) * Number(findTcs.ratepercent)) /
          100
        ).toFixed(2);
        setData((prev) => ({ ...prev, tcs: value, tsamount: `+${tsamount}` }));
      } else {
        setData((prev) => ({ ...prev, tcs: "", tsamount: 0 }));
      }
    }
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedItemArray = data.itemarray.map((item, i) =>
      i == ind ? { ...item, [name]: value } : item
    );

    setData((prev) => ({
      ...prev,
      itemarray: updatedItemArray,
    }));

    if (name == "qty") {
      if (value) {
        const updatedItemArr = data.itemarray.map((item, i) => {
          if (i == ind) {
            const amount = (
              (Number(value) || 0) * (Number(item.rate) || 0)
            ).toFixed(2);
            const discountamount = (
              Number(amount) -
              (Number(amount) * Number(item.discount || 0)) / 100
            ).toFixed(2);
            return {
              ...item,
              [name]: value,
              amount: amount,
              discountamount: discountamount,
            };
          } else {
            return item;
          }
        });

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArr,
        }));
      } else {
        const updatedItemArray = data.itemarray.map((item, i) =>
          i == ind
            ? { ...item, [name]: value, amount: 0, discountamount: 0 }
            : item
        );

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArray,
        }));
      }
    }

    if (name == "rate") {
      if (value) {
        const updatedItemArr = data.itemarray.map((item, i) => {
          if (i == ind) {
            const amount = (
              (Number(value) || 0) * (Number(item.qty) || 0)
            ).toFixed(2);
            const discountamount = (
              Number(amount) -
              (Number(amount) * Number(item.discount || 0)) / 100
            ).toFixed(2);
            return {
              ...item,
              [name]: value,
              amount: amount,
              discountamount: discountamount,
            };
          } else {
            return item;
          }
        });

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArr,
        }));
      } else {
        const updatedItemArray = data.itemarray.map((item, i) =>
          i == ind
            ? { ...item, [name]: value, amount: 0, discountamount: 0 }
            : item
        );

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArray,
        }));
      }
    }
    if (name == "discount") {
      if (value) {
        const updatedItemArray = data.itemarray.map((item, i) =>
          i == ind
            ? {
                ...item,
                [name]: value,
                discountamount: (
                  Number(item.amount) -
                  (Number(item.amount) * Number(value)) / 100
                ).toFixed(2),
              }
            : item
        );

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArray,
        }));
      } else {
        const updatedItemArray = data.itemarray.map((item, i) =>
          i == ind
            ? {
                ...item,
                [name]: value,
                discountamount: (
                  Number(item.amount) -
                  (Number(item.amount) * Number(0)) / 100
                ).toFixed(2),
              }
            : item
        );

        setData((prev) => ({
          ...prev,
          itemarray: updatedItemArray,
        }));
      }
    }
  };

  const handleVendorChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      vendor: "",
      grn: "",
      invoiceno: "",
      purchaseorder: "",
      purchasedate: "",
      paymentduedate: "",
      paymentterms: "",
      ewaybill: "",
      vehicleno: "",
      vehicletype: "",
      itemarray: [],
    }));
    if (value) {
      setData((prev) => ({ ...prev, vendor: value }));
    } else {
      setData((prev) => ({
        ...prev,
        vendor: "",
        grn: "",
        invoiceno: "",
        purchaseorder: "",
        purchasedate: "",
        paymentduedate: "",
        paymentterms: "",
        ewaybill: "",
        vehicleno: "",
        vehicletype: "",
        itemarray: [],
      }));
    }
  };

  const handleGrnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      grn: "",
      invoiceno: "",
      purchaseorder: "",
      purchasedate: "",
      paymentduedate: "",
      paymentterms: "",
      ewaybill: "",
      vehicleno: "",
      vehicletype: "",
      itemarray: [],
    }));
    if (value) {
      const findGood = receiveGoodsMaster.find((good) => good.grn == value);
      const changedItemArr = findGood.itemarray.map((item) => ({
        ...item,
        account: "",
        amount: (Number(item.rate) * Number(item.qty)).toFixed(2),
        discount: 0,
        discountamount: (Number(item.rate) * Number(item.qty) - 0).toFixed(2),
      }));

      setData((prev) => ({
        ...prev,
        grn: value,
        ewaybill: findGood.ewaybill || "",
        vehicletype: findGood.vehicletype || "",
        vehicleno: findGood.vehicleno || "",
        invoiceno: findGood.invoiceno || "",
        itemarray: changedItemArr,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        grn: "",
        invoiceno: "",
        purchaseorder: "",
        purchasedate: "",
        paymentduedate: "",
        paymentterms: "",
        ewaybill: "",
        vehicleno: "",
        vehicletype: "",
        itemarray: [],
      }));
    }
  };

  // const handleInvoiceChange = (e) => {
  //   const { name, value } = e.target;
  //   setData((prev) => ({
  //     ...prev,
  //     invoiceno: "",
  //     purchaseorder: "",
  //     purchasedate: "",
  //     paymentduedate: "",
  //     paymentterms: "",
  //     itemarray: [],
  //   }));
  //   if (value) {
  //     const findGood = receiveGoodsMaster.find(
  //       (good) => good.invoiceno == value
  //     );
  //     const changedItemArr = findGood.itemarray.map((item) => ({
  //       ...item,
  //       account: "",
  //       amount: Number(item.rate) * Number(item.qty),
  //     }));
  //     setData((prev) => ({
  //       ...prev,
  //       itemarray: changedItemArr,
  //       invoiceno: value,
  //     }));
  //   } else {
  //     setData((prev) => ({
  //       ...prev,
  //       invoiceno: "",
  //       purchaseorder: "",
  //       purchasedate: "",
  //       paymentduedate: "",
  //       paymentterms: "",
  //       itemarray: [],
  //     }));
  //   }
  // };

  const handlePurchaseOrderChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      purchaseorder: "",
      purchasedate: "",
      paymentduedate: "",
      paymentterms: "",
    }));
    if (value) {
      setData((prev) => ({ ...prev, purchaseorder: value }));
    } else {
      setData((prev) => ({
        ...prev,
        purchaseorder: "",
        purchasedate: "",
        paymentduedate: "",
        paymentterms: "",
      }));
    }
  };

  const handleTaxChange = (e) => {
    setData((prev) => ({
      ...prev,
      taxtype: e.target.value,
      tcs: "",
      tds: "",
      tsamount: 0,
    }));
  };

  const handleSave = () => {
    if (
      !data.purchasebillno ||
      !data.vendor ||
      // !data.grn ||
      // !data.invoiceno ||
      !data.purchaseorder ||
      !data.billdate ||
      !data.purchasedate ||
      !data.paymentduedate ||
      !data.paymentterms ||
      !data.account
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    dispatch(updatePurchaseBills({ ...data, status: "Unpaid" }, navigate));
  };

  const handleDraft = () => {
    if (
      !data.purchasebillno ||
      !data.vendor ||
      // !data.grn ||
      // !data.invoiceno ||
      !data.purchaseorder ||
      !data.billdate ||
      !data.purchasedate ||
      !data.paymentduedate ||
      !data.paymentterms ||
      !data.account
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    dispatch(updatePurchaseBills({ ...data, status: "Draft" }, navigate));
  };

  const handleFileChange = (event) => {
    if (event.target.name === "documents") {
      const files = event.target.files;
      handleUploadFile(files);
    }
    // handleUploadFile(data.documents);
  };

  const handleUploadFile = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/purchasebill/fileupload",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      response.data.files.length > 0
        ? setData((prev) => ({ ...prev, uploads: response.data.files }))
        : setData((prev) => ({ ...prev, uploads: [] }));

      return response.data.files; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(getPurchaseBillById, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  const fetchReceiveGoods = async () => {
    try {
      const res = await axios.post(getReceiveGood, {
        userid: userid,
        branchid: branchid,
        companyid: companyid,
        keyword: "",
      });

      res.data.data
        ? setReceiveGoodsMaster(res.data.data)
        : setReceiveGoodsMaster([]);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const handleDeleteRow = (index) => {
    let filteredItem = data.itemarray.filter((item, ind) => ind !== index);

    setData((prevData) => ({
      ...prevData,
      itemarray: filteredItem,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      coaPagiData({ userid, branchid, companyid, keyword: "" }, setLoading)
    );
    dispatch(tdsData({ userid, branchid, companyid, keyword: "" }, setLoading));
    dispatch(tcsData({ userid, branchid, companyid, keyword: "" }, setLoading));
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
    dispatch(
      purchaseOrderData(
        { userid, branchid, companyid, keyword: "" },
        setLoading
      )
    );
    dispatch(paymentTermData({ companyid, userid, branchid }, setLoading));

    fetchReceiveGoods();
  }, [dispatch]);

  useEffect(() => {
    const findPurchaseOrder = purchaseOrderMaster.find(
      (po) => po.purchaseorderno == data.purchaseorder
    );
    if (findPurchaseOrder) {
      const date = new Date(findPurchaseOrder.purchasedate);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];
      setData((prev) => ({
        ...prev,
        purchasedate: findPurchaseOrder.purchasedate,
        month: month,
        paymentduedate: findPurchaseOrder.paymentduedate,
        vendorgst: findPurchaseOrder.vendorgst,
        locationgst: findPurchaseOrder.locationgst,
        address: findPurchaseOrder.address,
        deliveryaddress: findPurchaseOrder.deliveryaddress,
        itemarray: findPurchaseOrder.itemarray.arr,
        paymentterms: findPurchaseOrder.paymentterms,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        purchasedate: "",
        month: "",
        paymentduedate: "",
        vendorgst: "",
        locationgst: "",
        address: "",
        deliveryaddress: "",
        itemarray: [],
        paymentterms: "",
      }));
    }
  }, [data.purchaseorder]);

  // useEffect(() => {
  //   const findGood = receiveGoodsMaster.find((good) => good.grn == data.grn);
  //   if (findGood) {
  //     const changedItemArr = findGood.itemarray.map((item) => ({
  //       ...item,
  //       account: "",
  //       amount: Number(item.rate) * Number(item.qty),
  //     }));
  //     setData((prev) => ({
  //       ...prev,
  //       itemarray: changedItemArr,
  //     }));
  //   } else {
  //     setData((prev) => ({ ...prev, itemarray: [] }));
  //   }
  // }, [data.grn]);

  useEffect(() => {
    let subtotal = data.itemarray
      .reduce((acc, curr) => {
        return Number(acc) + Number(curr.discountamount);
      }, 0)
      .toFixed(2);

    let originalsubtotal = data.itemarray
      .reduce((acc, curr) => {
        return Number(acc) + Number(curr.amount);
      }, 0)
      .toFixed(2);

    let discountAmount = (Number(subtotal) - Number(originalsubtotal)).toFixed(
      2
    );

    const totalGstAmount = data.itemarray
      .reduce((total, currentItem) => {
        const gstAmount =
          (Number(currentItem.discountamount) * Number(currentItem.gst)) / 100;
        return total + gstAmount;
      }, 0)
      .toFixed(2);

    let grandtotal = (
      Number(subtotal) +
      Number(totalGstAmount) +
      Number(data.fadvance) +
      Number(data.adjustmentamont) +
      Number(data.tsamount)
    ).toFixed(2);

    let finalAmount = (Number(grandtotal) - Number(data.vendoradvance)).toFixed(
      2
    );
    let decimalPart = finalAmount - Math.floor(finalAmount);

    if (decimalPart > 0.5) {
      finalAmount = Math.ceil(finalAmount);
    } else {
      finalAmount = Math.floor(finalAmount);
    }

    setData((prev) => ({
      ...prev,
      subtotal: subtotal,
      discountamount: discountAmount,
      taxableamount: subtotal,
      cgst: totalGstAmount / 2,
      sgst: totalGstAmount / 2,
      igst: totalGstAmount,
      grandtotal: grandtotal,
      finalamount: finalAmount,
    }));
  }, [
    data.itemarray,
    data.discountamount,
    data.taxableamount,
    data.fadvance,
    data.adjustmentamont,
    data.vendoradvance,
    data.tsamount,
    data.grn,
  ]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/purchase/purchaseBills/")}
            />
            Update <span className="form-title main"> Bill </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row lg={2} md={1} sm={1}>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Purchase Bill No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="purchasebillno"
                            value={data.purchasebillno}
                            onChange={handleChange}
                            // isInvalid={!data.locationname && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Vendor <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="vendor"
                            value={data.vendor}
                            onChange={handleVendorChange}
                            isInvalid={!data.vendor && showEmptyError}
                          >
                            <option value="">Select</option>
                            {vendorMaster
                              .filter(
                                (vendor) => vendor.vendorcategory == "Supplier"
                              )
                              .map((vendor) => {
                                return (
                                  <option
                                    value={vendor.fullname}
                                    key={vendor.id}
                                  >
                                    {vendor.fullname}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>GRN No.</Form.Label>
                          <Form.Select
                            name="grn"
                            value={data.grn}
                            onChange={handleGrnChange}
                            // isInvalid={!data.grn && showEmptyError}
                          >
                            <option value="">Select</option>
                            {receiveGoodsMaster
                              .filter(
                                (good) =>
                                  good.receivedfrom == "Vendor" &&
                                  good.receive == data.vendor
                              )
                              .map((good) => (
                                <option value={good.grn} key={good.id}>
                                  {good.grn}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Invoice&nbsp;
                            <MdModeEdit
                              style={{ cursor: "pointer" }}
                              onClick={() => setIsEditInvoice(!isEditInvoice)}
                            />{" "}
                          </Form.Label>
                          {!isEditInvoice ? (
                            <Form.Control
                              type="text"
                              disabled
                              name="invoiceno"
                              value={data.invoiceno}
                              // onChange={handleChange}
                              // isInvalid={!data.invoiceno && showEmptyError}
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              name="invoiceno"
                              value={data.invoiceno}
                              onChange={handleChange}
                              // isInvalid={!data.invoiceno && showEmptyError}
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Purchase Order <span className="star">*</span>
                          </Form.Label>
                          {data.grn ? (
                            <Form.Select
                              name="purchaseorder"
                              value={data.purchaseorder}
                              onChange={handlePurchaseOrderChange}
                              isInvalid={!data.purchaseorder && showEmptyError}
                            >
                              <option value="">Select</option>
                              {receiveGoodsMaster
                                .filter(
                                  (good) =>
                                    good.receivedfrom == "Vendor" &&
                                    good.receive == data.vendor &&
                                    good.grn == data.grn
                                )
                                .map((good) => (
                                  <option
                                    value={good.purchaseorder}
                                    key={good.id}
                                  >
                                    {good.purchaseorder}
                                  </option>
                                ))}
                            </Form.Select>
                          ) : (
                            <Form.Select
                              name="purchaseorder"
                              value={data.purchaseorder}
                              onChange={handlePurchaseOrderChange}
                              isInvalid={!data.purchaseorder && showEmptyError}
                            >
                              <option value="">Select</option>
                              {purchaseOrderMaster
                                .filter((po) => po.vendorname == data.vendor)
                                .map((po) => (
                                  <option
                                    value={po.purchaseorderno}
                                    key={po.id}
                                  >
                                    {po.purchaseorderno}
                                  </option>
                                ))}
                            </Form.Select>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Purchase Date.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="date"
                            name="purchasedate"
                            value={data.purchasedate}
                            // onChange={handleChange}
                            isInvalid={!data.purchasedate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Bill Date<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="billdate"
                            value={data.billdate}
                            onChange={handleChange}
                            isInvalid={!data.billdate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Due Date.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="date"
                            name="paymentduedate"
                            value={data.paymentduedate}
                            // onChange={handleChange}
                            isInvalid={!data.paymentduedate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Terms <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="paymentterms"
                            value={data.paymentterms}
                            onChange={handleChange}
                            isInvalid={!data.paymentterms && showEmptyError}
                          >
                            <option value="">Select</option>
                            {ptMaster.map((pt) => {
                              return (
                                <option value={pt.termname} key={pt.id}>
                                  {pt.termname}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>E-Way bill.</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="ewaybill"
                            value={data.ewaybill}
                            // onChange={handleChange}
                            // isInvalid={!data.locationname && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>Upload Image</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleFileChange}
                            name="documents"
                            accept="image/*"
                            multiple
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label>Uploaded Image</Form.Label>
                        <div className="uploaded-data">
                          {data.uploads.length > 0 ? (
                            data.uploads.map((item, index) => {
                              return (
                                <>
                                  <span>
                                    <a href={item} target="blank">
                                      <img
                                        src={item}
                                        alt="img"
                                        width={50}
                                        height={50}
                                        className="rounded-3"
                                      />
                                    </a>
                                  </span>
                                </>
                              );
                            })
                          ) : (
                            <div className="uploaded-data">
                              <span>No Image Found</span>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Vehicle Type</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="vehicletype"
                            value={data.vehicletype}
                            // onChange={handleChange}
                            // isInvalid={!data.locationname && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Vehicle No.</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="vehicleno"
                            value={data.vehicleno}
                            // onChange={handleChange}
                            // isInvalid={!data.locationname && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Account <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            name="account"
                            value={data.account}
                            onChange={handleChange}
                            isInvalid={!data.account && showEmptyError}
                            className="form-control "
                          >
                            <option value="">--Select--</option>
                            {coaAllData && coaAllData.length > 0 ? (
                              coaAllData
                                .filter(
                                  (coa) => coa.parentaccount == "Expenses"
                                )
                                .map((coa) => (
                                  <option key={coa.id} value={coa.accountname}>
                                    {coa.accountname}
                                  </option>
                                ))
                            ) : (
                              <option value="">No Account available</option>
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                </Row>
                {data.grn ? null : (
                  <AddItemModal data={data} setData={setData} />
                )}
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Col md={12} className="table-main-container">
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th style={{ width: "10%" }}>Category</th>
                <th style={{ width: "10%" }}>Brand</th>
                <th style={{ width: "20%" }}>Item Name</th>
                <th>Unit</th>
                <th>QTY</th>
                <th>Rate</th>
                <th>GST %</th>
                <th>Discount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.itemarray.length > 0 ? (
                data.itemarray.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <Form.Control
                          type="text"
                          disabled
                          value={item.category}
                          title={item.category}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          disabled
                          value={item.brand}
                          title={item.brand}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          disabled
                          value={item.itemname}
                          title={item.itemname}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          disabled
                          value={item.unit}
                          title={item.unit}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="qty"
                          min={"0"}
                          disabled={data.grn}
                          value={item.qty}
                          title={item.qty}
                          onChange={(e) => handleTableRowChange(e, ind)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={item.rate}
                          title={item.rate}
                          name="rate"
                          min={"0"}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!item.rate && showEmptyError}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={item.gst}
                          title={item.gst}
                          name="gst"
                          min={"0"}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!item.gst && showEmptyError}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={item.discount}
                          title={item.discount}
                          name="discount"
                          min={"0"}
                          onChange={(e) => handleTableRowChange(e, ind)}
                        />
                      </td>
                      <td className="d-flex gap-2">
                        <Form.Control
                          disabled
                          type="number"
                          value={item.discountamount}
                          title={item.discountamount}
                          min={"0"}
                        />
                        {data.grn ? null : (
                          <MdDelete
                            onClick={() => handleDeleteRow(ind)}
                            className="fs-1 text-danger"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9">
                    <div className="datanotfound-center-text">
                      No Item Found 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Sub Total:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="text"
                  name="subtotal"
                  disabled
                  value={data.subtotal}
                  title={data.subtotal}
                  min={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Total Discount:</Form.Label>
              </Col>
              <Col>
                {/* <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    min={"0"}
                    name="discount"
                    onChange={handleChange}
                    value={data.discount}
                  />
                  <InputGroup.Text className="fs-2"> %</InputGroup.Text>
                </InputGroup> */}
              </Col>

              <Col>
                <Form.Control
                  type="text"
                  name="discountamount"
                  disabled
                  value={data.discountamount}
                  title={data.discountamount}
                  min={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          {data.taxableamount ? (
            <Col className="table-main-container">
              <h3>GST Break Down :</h3>
              <Table responsive>
                <thead className="tableContainer">
                  <tr>
                    <th>CGST(%)</th>
                    <th>SGST(%)</th>
                    <th>IGST(%)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.itemarray.map((item, ind) =>
                    data.vendorgst[0] == data.locationgst[0] &&
                    data.vendorgst[1] == data.locationgst[1] ? (
                      <tr key={ind}>
                        <td>
                          {item.discountamount} * {Number(item.gst) / 2}% ={" "}
                          {(Number(item.discountamount) *
                            (Number(item.gst) / 2)) /
                            100}
                        </td>
                        <td>
                          {item.discountamount} * {Number(item.gst) / 2}% ={" "}
                          {(Number(item.discountamount) *
                            (Number(item.gst) / 2)) /
                            100}
                        </td>
                        <td>0</td>
                      </tr>
                    ) : (
                      <tr key={ind}>
                        <td>0</td>
                        <td>0</td>
                        <td>
                          {item.discountamount} * {Number(item.gst)}% ={" "}
                          {(Number(item.discountamount) * Number(item.gst)) /
                            100}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
                {data.vendorgst[0] == data.locationgst[0] &&
                data.vendorgst[1] == data.locationgst[1] ? (
                  <thead>
                    <tr>
                      <th>Total : {data.cgst}</th>
                      <th>{data.sgst}</th>
                      <th>{0}</th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th>Total : {0}</th>
                      <th>{0}</th>
                      <th>{data.igst}</th>
                    </tr>
                  </thead>
                )}
              </Table>
            </Col>
          ) : (
            <Col></Col>
          )}
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Taxable Amount:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="text"
                  name="taxableamount"
                  disabled
                  value={data.taxableamount}
                  title={data.taxableamount}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            {data.vendorgst[0] == data.locationgst[0] &&
            data.vendorgst[1] == data.locationgst[1] ? (
              <Row>
                <Col>
                  <Form.Label>CGST:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="itemid"
                    disabled
                    value={data.cgst}
                    title={data.cgst}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            {data.vendorgst[0] == data.locationgst[0] &&
            data.vendorgst[1] == data.locationgst[1] ? (
              <Row>
                <Col>
                  <Form.Label>SGST:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="itemid"
                    disabled
                    value={data.sgst}
                    title={data.sgst}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            {data.vendorgst[0] != data.locationgst[0] ||
            data.vendorgst[1] != data.locationgst[1] ? (
              <Row>
                <Col>
                  <Form.Label>IGST:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    type="text"
                    name="itemid"
                    disabled
                    value={data.igst}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="formBasicCheck">
                  <Form.Check
                    inline
                    className="form-label"
                    type="radio"
                    label="TCS"
                    value="TCS"
                    checked={data.taxtype == "TCS"}
                    onChange={handleTaxChange}
                    // id="warehouse"
                  />
                  <Form.Check
                    className="form-label"
                    inline
                    type="radio"
                    label="TDS"
                    value="TDS"
                    checked={data.taxtype === "TDS"}
                    onChange={handleTaxChange}
                  />
                </Form.Group>
              </Col>
              {data.taxtype == "TDS" ? (
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex justify-content-between">
                      <div> TDS</div>
                    </Form.Label>
                    <Form.Select
                      name="tds"
                      value={data.tds}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {tdsMaster?.map((tds) => {
                        return (
                          <option key={tds.id} value={tds.taxname}>
                            {tds.taxname}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : (
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex justify-content-between">
                      <div>Manage TCS</div>
                    </Form.Label>
                    <Form.Select
                      name="tcs"
                      value={data.tcs}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {tcsMaster?.map((tcs) => {
                        return (
                          <option key={tcs.id} value={tcs.taxname}>
                            {tcs.taxname}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="itemid"
                  disabled
                  value={data.tsamount}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Freight Advance:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="number"
                  name="fadvance"
                  onChange={handleChange}
                  value={data.fadvance}
                  min={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Adjustment:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="number"
                  name="adjustmentamont"
                  onChange={handleChange}
                  value={data.adjustmentamont}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Grand Total:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="text"
                  name="grandtotal"
                  disabled
                  value={data.grandtotal}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Vendor Advance:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="number"
                  name="vendoradvance"
                  onChange={handleChange}
                  value={data.vendoradvance}
                  min={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Final Amount:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  type="text"
                  name="finalamount"
                  disabled
                  value={data.finalamount}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={2}
                name="notes"
                value={data.notes}
                onChange={handleChange}
                // isInvalid={data.description === "" && showEmptyError}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleDraft}
          >
            Save Draft
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/purchase/purchaseBills/")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditPurchaseBills;
