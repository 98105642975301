import React, { useEffect, useState } from "react";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  addReceiveGood,
  branchPagiDataPost,
  companyPagiDataPost,
  customerGetPost,
  getPurchaseOrderPagiApi,
  getVendorPagiApi,
  grnGenerate,
  issueGoodgetPagePost,
  salesInvoicePagiDataPost,
} from "../../../constants/api";
import { initialData } from "../../../constants/resData";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { BsPlusCircle } from "react-icons/bs";
import QualityCheckModal from "./QualityCheckModal";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import PreviewGoods from "./PreviewGoods";
import { invoiceOrChallanUploadReceive } from "../../../redux/actions/mtin.action";
import { useDispatch, useSelector } from "react-redux";
import SuggestedItems from "../IssueGoods/SuggestedItems";
import { MdDelete } from "react-icons/md";

const Receive = () => {
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let userName = localData.name;
  let org = localData.org;

  const invoicorChallan = useSelector(
    (state) => state.receiveGoodData.invoiceChallanReceive
  );

  const URL = extractAndFormatURLPart("inventory");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReqBody = {
    keyword: "",
    branchid: branchID,
    userid: userID,
    companyid: companyID,
  };

  const [receiveIDData, setReceiveIDData] = useState([]);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [poData, setPOData] = useState([]);
  const [poArray, setPOArray] = useState([]);
  const [giData, setGIData] = useState([]);
  const [giArray, setGIArray] = useState([]);
  const [siData, setSIData] = useState([]);
  const [siArray, setSIArray] = useState([]);
  const [suggesteditems, setSuggestedItems] = useState([]);
  const [tableData, setTableData] = useState(poArray);
  const [data, setData] = useState({
    receivedfrom: "",
    receive: "",
    purchaseorder: "",
    goodissue: "",
    grn: "",
    date: "",
    receivedtype: "",
    invoiceno: "",
    invoicedate: "",
    vehicletype: "",
    vehicleno: "",
    receivedby: "",
    checkedby: "",
    remarks: "",
    ewaybill: "",
    challan: [],
    challanno: "",
    challandate: "",
    itemarray: [],
    total: "",
    branchid: branchID,
    companyid: companyID,
    userid: userID,
    org: org,
    status: "",
    warehouse: "",
    saleinvoice: "",
    month: "",
    financialyear:finalcialYear,
    creditnote: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyRes, branchRes, vendorRes, customerRes] =
          await Promise.all([
            axios.post(companyPagiDataPost, ReqBody),

            axios.post(branchPagiDataPost, ReqBody),

            axios.post(getVendorPagiApi, ReqBody),

            axios.post(customerGetPost, ReqBody),
          ]);
        setCompanyData(initialData(companyRes));
        setBranchData(initialData(branchRes));
        setVendorData(initialData(vendorRes));
        setCustomersData(initialData(customerRes));
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();

    //get all Purchase Orders and item array
    axios
      .post(getPurchaseOrderPagiApi, ReqBody)
      .then((res) => {
        setPOData(
          initialData(res).filter((ele) => ele.vendorname == data.receive)
        );
        let x =
          poData.length > 0
            ? poData.filter(
                (ele) => ele.purchaseorderno == data.purchaseorder
              )[0]
            : [];
        setData((prevData) => ({
          ...prevData,
          warehouse: x ? x.specifylocation : "",
        }));
        setPOArray(
          x.itemarray && x.itemarray.arr.length > 0 ? x.itemarray.arr : []
        );
      })
      .catch((err) => console.log(err));
  }, [data.receivedfrom, data.purchaseorder, data.receive]);

  useEffect(() => {
    //get all Sale  Invoices and item array
    axios
      .post("https://erp.venturesathi.co.in/api/v1/creditnote/get", { ...ReqBody, status: "" })
      .then((res) => {
        setSIData(
          initialData(res).filter((ele) => ele.commonname == data.receive)
        );
        let x =
          siData.length > 0
            ? siData.filter((ele) => ele.salesinvoiceno == data.saleinvoice)[0]
            : [];
        setData((prevData) => ({
          ...prevData,
          creditnote: x.creditnoteno,
        }));

        setSIArray(x.itemarray && x.itemarray.length > 0 ? x.itemarray : []);

        setData((prevData) => ({
          ...prevData,
          itemarray: x.itemarray && x.itemarray.length > 0 ? x.itemarray : [],
        }));

        const newTotalQty = x.itemarray?.reduce((total, row) => {
          return total + (parseInt(row.receivedqty) || 0);
        }, 0);
        setData((prevData) => ({
          ...prevData,
          total: newTotalQty,
        }));
      })
      .catch((err) => console.log(err));
  }, [data.receivedfrom, data.receive, data.saleinvoice]);

  useEffect(() => {
    //get all GI and item array
    axios
      .post(issueGoodgetPagePost, ReqBody)
      .then((res) => {
        setGIData(
          initialData(res)
            .filter(
              (ele) => ele.issueto == "Other Company" || ele.issueto == "Branch"
            )
            .filter((ele) => ele.issuetoname == data.receive)
        );
        let x =
          giData.length > 0
            ? giData.filter((ele) => ele.gino == data.goodissue)[0]
            : [];

        setGIArray(x.itemarray && x.itemarray.length > 0 ? x.itemarray : []);
      })
      .catch((err) => console.log(err));
  }, [data.receivedfrom, data.receive, data.goodissue]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      challan: invoicorChallan || [],
    }));
  }, [invoicorChallan]);

  const handleLeftArrow = () => {
    navigate("/inventory/manageStock");
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));

    if (name === "receivedfrom") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        receive: "",
        purchaseorder: "",
        saleinvoice: "",
        creditnote:"",
        receivedtype: "",
        vehicletype: "",
        itemarray: [],
        total: "",
      }));
      setPOArray([]);
      setSIArray([]);
      setGIArray([]);
    }
    if (name === "receive") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        purchaseorder: "",
        saleinvoice: "",
      }));
    }
    if (name === "purchaseorder") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        itemarray: [],
      }));
      setPOArray([]);
    }
    if (name === "goodissue") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        itemarray: [],
      }));
      setGIArray([]);
    }
    if (name === "saleinvoice") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        itemarray: [],
      }));
      setSIArray([]);
    }
    if (name === "receivedtype") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        challanno: "",
        challandate: "",
        invoiceno: "",
        invoicedate: "",
      }));
    }
    if (name === "vehicletype") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        vehicleno: "",
      }));
    }
    if (name == "date") {
      const monthName = new Date(value).toLocaleDateString("en-US", {
        month: "long",
      });
      setData((prevData) => ({
        ...prevData,
        month: monthName,
      }));
    }
    if (name === "documents") {
      dispatch(invoiceOrChallanUploadReceive(files));
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };

  const handleTableRowChange = (e, rowindex) => {
    const { name, value } = e.target;

    if (name === "qty" && parseInt(value) <= 0) {
      return;
    }

    setPOArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));

      // Calculate the total quantity
      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.qty) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        total: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleTableRowChange2 = (e, rowindex) => {
    const { name, value } = e.target;
    if (name === "receivedqty" && parseInt(value) <= 0) {
      return;
    }
    setSIArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));

      // Calculate the total quantity
      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.receivedqty) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        total: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleTableRowDelete = (rowIndex) => {
    setSIArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData.splice(rowIndex, 1);
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));

      // Calculate the total quantity
      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.qty) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        total: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleTableRowChange3 = (e, rowindex) => {
    const { name, value } = e.target;

    if (name === "qty" && parseInt(value) <= 0) {
      return;
    }

    setGIArray((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));

      // Calculate the total quantity
      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.qty) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        total: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleTableRowChange4 = (e, rowindex) => {
    const { name, value } = e.target;

    if (name === "qty" && parseInt(value) <= 0) {
      return;
    }

    setSuggestedItems((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));

      // Calculate the total quantity
      const newTotalQty = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.qty) || 0);
      }, 0);
      setData((prevData) => ({
        ...prevData,
        total: newTotalQty,
      }));
      return updatedTableData;
    });
  };

  const handleAccept = async () => {
    if (
      data.date == "" ||
      data.receivedfrom == "Vendor" ||
      data.receivedfrom == "Branch" ||
      data.receivedfrom == "Company"
    ) {
      if (data.receive == "" || data.receivedtype == "") {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      } else if (
        data.receive == "" ||
        (data.receivedtype == "Invoice" &&
          (data.invoiceno == "" || data.invoicedate == "")) ||
        (data.receivedtype == "Challan" &&
          (data.challanno == "" || data.challandate == ""))
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    } else if (data.customertype == "Return From Customer") {
      if (data.date == "" || data.receive == "" || data.bdpan == "") {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    const res = await axios.post(addReceiveGood, {
      ...data,
      status: "Accepted",
    });
    if (res.statusText == "OK") {
      notifySuccess(res.data.message);
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };
  const handleReject = async () => {
    if (
      data.date == "" ||
      data.receivedfrom == "Vendor" ||
      data.receivedfrom == "Branch" ||
      data.receivedfrom == "Company"
    ) {
      if (data.receive == "" || data.receivedtype == "") {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      } else if (
        data.receive == "" ||
        (data.receivedtype == "Invoice" &&
          (data.invoiceno == "" || data.invoicedate == "")) ||
        (data.receivedtype == "Challan" &&
          (data.challanno == "" || data.challandate == ""))
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    } else if (data.customertype == "Return From Customer") {
      if (data.date == "" || data.receive == "" || data.bdpan == "") {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    const res = await axios.post(addReceiveGood, {
      ...data,
      status: "Rejected",
    });
    if (res.statusText == "OK") {
      notifySuccess(res.data.message);
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  //dynamic GRN generate
  useEffect(() => {
    axios

      .post("https://erp.venturesathi.co.in/api/v1/receivegoods/getpage", {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setReceiveIDData(initialData);
      })
      .catch((err) => console.log(err));
  }, []);

  const generateCategoryID = () => {
    if (receiveIDData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = receiveIDData[0].grn.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      grn: `GRN/SAANVI/${paddedCount}`,
    }));
  }, [receiveIDData]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Receive <span className="form-title main"> Goods</span>
          </h1>
          <hr />
          <Container fluid>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Received From <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="receivedfrom"
                    value={data.receivedfrom}
                    onChange={handleChange}
                    isInvalid={data.receivedfrom === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Company">Company</option>
                    <option value="Branch">Branch</option>
                    <option value="Return From Customer">
                      Return From Customer
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose Received From
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    {data.receivedfrom == "Vendor"
                      ? "Vendor"
                      : data.receivedfrom == "Company"
                      ? "Company"
                      : data.receivedfrom == "Branch"
                      ? "Branch"
                      : "Customer"}
                    <span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    name="receive"
                    value={data.receive}
                    onChange={handleChange}
                    isInvalid={data.receive === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {data.receivedfrom == "Vendor" ? (
                      vendorData.length > 0 &&
                      vendorData.map((item, index) => (
                        <option key={index} value={item.fullname}>
                          {item.fullname}
                        </option>
                      ))
                    ) : data.receivedfrom == "Company" ? (
                      companyData.length > 0 &&
                      companyData.map((item, index) => (
                        <option key={index} value={item.companyname}>
                          {item.companyname}
                        </option>
                      ))
                    ) : data.receivedfrom == "Branch" ? (
                      branchData.length > 0 &&
                      branchData.map((item, index) => (
                        <option key={index} value={item.branchname}>
                          {item.branchname}
                        </option>
                      ))
                    ) : data.receivedfrom == "Return From Customer" ? (
                      customersData.length > 0 &&
                      customersData.map((item, index) =>
                        item.customertype === "Retail" ? (
                          <option key={index} value={item.fullname}>
                            {item.fullname}
                          </option>
                        ) : (
                          <option key={index} value={item.bdcompany}>
                            {item.bdcompany}
                          </option>
                        )
                      )
                    ) : (
                      <option value="">--Select--</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {data.receivedfrom == "Vendor"
                      ? "Choose Vendor"
                      : data.receivedfrom == "Company"
                      ? "Choose Company"
                      : data.receivedfrom == "Branch"
                      ? "Choose Branch"
                      : data.receivedfrom == "Branch"
                      ? "Choose Customer"
                      : ""}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Purchase Order</Form.Label>
                    <Form.Select
                      name="purchaseorder"
                      value={data.purchaseorder}
                      onChange={handleChange}
                      isInvalid={data.purchaseorder === "" && showEmptyError}
                    >
                      <option value="">Select Purchase Order</option>
                      {poData.length > 0 &&
                        poData.map((item, index) => (
                          <option key={index} value={item.purchaseorderno}>
                            {item.purchaseorderno}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                ) : data.receivedfrom == "Company" ||
                  data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>GI No</Form.Label>
                    <Form.Select
                      name="goodissue"
                      value={data.goodissue}
                      onChange={handleChange}
                      isInvalid={data.goodissue === "" && showEmptyError}
                    >
                      <option value="">Select Purchase Order</option>
                      {giData.length > 0 &&
                        giData.map((item, index) => (
                          <option key={index} value={item.gino}>
                            {item.gino}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>GRN Number</Form.Label>
                    <Form.Control
                      disabled
                      readOnly
                      type="text"
                      name="grn"
                      value={data.grn}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>GRN Number</Form.Label>
                    <Form.Control
                      disabled
                      readOnly
                      type="text"
                      name="grn"
                      value={data.grn}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      GRN date <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={data.date}
                      onChange={handleChange}
                      isInvalid={!data.date && showEmptyError}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      GRN date <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={data.date}
                      onChange={handleChange}
                      isInvalid={!data.date && showEmptyError}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Challan No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter challan Number"
                      name="challanno"
                      value={data.challanno}
                      onChange={handleChange}
                      isInvalid={data.challanno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter challan Number
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Received Type <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="receivedtype"
                      value={data.receivedtype}
                      onChange={handleChange}
                      isInvalid={data.receivedtype === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      <option value="Invoice">Invoice</option>
                      <option value="Challan">Challan</option>
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Challan Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="challandate"
                      value={data.challandate}
                      onChange={handleChange}
                      isInvalid={data.challandate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter challan Date
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      {data.receivedtype == "Invoice"
                        ? "Invoice No"
                        : "Challan No"}
                      <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name={
                        data.receivedtype == "Invoice"
                          ? "invoiceno"
                          : "challanno"
                      }
                      value={
                        data.receivedtype == "Invoice"
                          ? data.invoiceno
                          : data.challanno
                      }
                      onChange={handleChange}
                      isInvalid={
                        (data.receivedtype == "Invoice"
                          ? data.invoiceno
                          : data.challanno) === "" && showEmptyError
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter
                      {data.receivedtype == "Invoice"
                        ? "Invoice No"
                        : "Challan No"}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Vehicle Type</Form.Label>
                    <Form.Select
                      name="vehicletype"
                      value={data.vehicletype}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="EX">EX</option>
                      <option value="FOR">FOR</option>
                    </Form.Select>
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      {data.receivedtype == "Invoice"
                        ? "Invoice Date"
                        : "Challan Date"}
                      <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name={
                        data.receivedtype == "Invoice"
                          ? "invoicedate"
                          : "challandate"
                      }
                      value={
                        data.receivedtype == "Invoice"
                          ? data.invoicedate
                          : data.challandate
                      }
                      onChange={handleChange}
                      isInvalid={data.invoicedate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter
                      {data.receivedtype == "Invoice"
                        ? "Invoice Date"
                        : "Challan Date"}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Vehicle No</Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicleno"
                      value={data.vehicleno}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Vehicle Type</Form.Label>
                    <Form.Select
                      name="vehicletype"
                      value={data.vehicletype}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="EX">EX</option>
                      <option value="FOR">FOR</option>
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Received By</Form.Label>
                    <Form.Control
                      disabled
                      readOnly
                      type="text"
                      name="receivedby"
                      value={(data.receivedby = userName)}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Vehicle No</Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicleno"
                      value={data.vehicleno}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Checked By</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Checked by person name"
                      name="checkedby"
                      value={data.checkedby}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Received By</Form.Label>
                    <Form.Control
                      disabled
                      readOnly
                      type="text"
                      name="receivedby"
                      value={(data.receivedby = userName)}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>E-Way Bil</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E-Way Bil Number"
                      name="ewaybill"
                      value={data.ewaybill}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                {data.receivedfrom == "Vendor" ||
                data.receivedfrom == "Company" ||
                data.receivedfrom == "Branch" ? (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Checked By</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Checked by person name"
                      name="checkedby"
                      value={data.checkedby}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Upload Invoice/Challan</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Select File"
                      name="documents"
                      value={data.documents}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    value={data.remarks}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                {data.receivedfrom &&
                data.receivedfrom != "Return From Customer" ? (
                  <Row md={2} sm={1} xs={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>E-Way Bil</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="E-Way Bil Number"
                          name="ewaybill"
                          value={data.ewaybill}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Upload Invoice/Challan</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select File"
                          name="documents"
                          value={data.documents}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <Row md={2} sm={1} xs={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Sale Invoice</Form.Label>
                        <Form.Select
                          type="text"
                          name="saleinvoice"
                          value={data.saleinvoice}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Sale Invoice</option>
                          {siData.length > 0 &&
                            siData.map((item, index) => (
                              <option key={index} value={item.salesinvoiceno}>
                                {item.salesinvoiceno}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Credit Note</Form.Label>
                        <Form.Control
                          disabled
                          readOnly
                          type="text"
                          name="creditnote"
                          value={data.creditnote}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Modal.Footer className="d-flex justify-content-end gap-2 mt-3">
              {data.receivedfrom == "Vendor" &&
                data.receive != "" &&
                data.purchaseorder == "" && (
                  <SuggestedItems setSuggestedItems={setSuggestedItems} />
                )}
            </Modal.Footer>
            <hr />
            <Table className="table-main-container" responsive>
              <TableHeading
                data={[
                  "SL No",
                  "Brand",
                  "Item Name",
                  "Item ID",
                  "Category",
                  "UOM",
                  "QTY",
                ]}
              />
              <tbody>
                {data.receivedfrom == "Vendor" &&
                  data.purchaseorder != "" &&
                  poArray.length > 0 &&
                  poArray.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="category"
                          className="text-center"
                          disabled
                          value={ele.category}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="qty"
                          className="text-center"
                          value={ele.qty}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                {data.receivedfrom == "Vendor" &&
                  data.receive != "" &&
                  data.purchaseorder == "" &&
                  suggesteditems.length > 0 &&
                  suggesteditems.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="category"
                          className="text-center"
                          disabled
                          value={ele.category}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="qty"
                          className="text-center"
                          value={ele.qty}
                          onChange={(e) => handleTableRowChange4(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                {(data.receivedfrom == "Company" ||
                  data.receivedfrom == "Branch") &&
                  giArray.length > 0 &&
                  giArray.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="category"
                          className="text-center"
                          disabled
                          value={ele.category}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="qty"
                          className="text-center"
                          value={ele.qty}
                          onChange={(e) => handleTableRowChange3(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                {data.receivedfrom == "Return From Customer" &&
                  siArray.length > 0 &&
                  siArray.map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="brand"
                          className="text-center"
                          disabled
                          value={ele.brand}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={ele.itemname}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={ele.itemid}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="category"
                          className="text-center"
                          disabled
                          value={ele.category}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="unit"
                          className="text-center"
                          disabled
                          value={ele.unit}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="receivedqty"
                          className="text-center"
                          disabled
                          value={ele.receivedqty}
                          onChange={(e) => handleTableRowChange2(e, index)}
                        />
                      </td>
                      {/* <td>
                        <MdDelete
                          className="deleteIcon my-2"
                          style={{ fontSize: "25px" }}
                          onClick={() => handleTableRowDelete(index)}
                        />
                  </td>*/}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div
              className={
                data.itemarray.length > 0
                  ? "d-flex justify-content-between"
                  : "d-none"
              }
            >
              <span></span>

              <Form.Group className="d-flex gap-2">
                <Form.Label className="mt-2">Total</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  readOnly
                  value={data.total}
                />
              </Form.Group>
            </div>
          </Container>
        </Form>
        <Row>
          <Col md={6}>
            <Button
              disabled={
                data.itemarray.length <= 0 ||
                data.itemarray.find((ele) => ele.qty == "")
              }
              className="commonBtn smallBtn"
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn mx-3"
              onClick={handleReject}
              disabled={
                data.itemarray.length <= 0 ||
                data.itemarray.find((ele) => ele.qty == "")
              }
            >
              Reject
            </Button>
            {/* <PreviewGoods
              data={data}
              handleAcceptView={handleAccept}
              handleRejectView={handleReject}
           />*/}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Receive;
