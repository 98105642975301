import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../../constants/toastAlert";
import axios from "axios";
import { getProjectById, projectIdAutocount } from "../../../constants/api";
import {
  addProject,
  updateProject,
} from "../../../redux/actions/projects.actions";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { MdOutlineFilePresent } from "react-icons/md";
import { IoDocumentAttach } from "react-icons/io5";
import Select from "react-select";

const EditProjectManagement = () => {
  const { id } = useParams();
  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const [customerMaster, setCustomerMaster] = useState([]);
  const [ArchitectContractorHistory, setArchitectContractorHistory] = useState(
    []
  );
  const [data, setData] = useState({
    type: "",
    projectid: "",
    backendcustomerid:"",
    customerid: "",
    customertype: "",
    customerdetails:"",
    customerfullname: "",
    projectname: "",
    projectstartdate: "",
    projectenddate: "",
    projectlocation: "",
    architect: "",
    contractor: "",
    status: "Planned",
    attachments: [],
    documents: "",
    remarks: "",
    company: "",
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    branch: branch,
    id: id,
    org: "₹ΘθϖΠ",
    architectarray: [],
    contractorarray: [],
  });

  const handleChange = (e) => {
    if (e.value) {
      
      setData((prev) => ({
        ...prev,
        customertype: e.type,
        customerfullname: e.type == "Institutional" ? "" : e.name,
        company: e.type == "Retail" ? "" : e.name,
        customerid: e.value,
        customerdetails:e.label,
        backendcustomerid:e.b_id
      }));
    } else {
      const { name, value } = e.target;
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name == "architect") {
        if (value) {
          const findVendor = vendorMaster.find(
            (vendor) =>
              vendor.vendorcategory == "Architect" && vendor.fullname == value
          );

          setData((prev) => ({
            ...prev,
            architect: findVendor.fullname,
            architectarray: [{ ...findVendor }],
          }));
        } else {
          setData((prev) => ({
            ...prev,
            architect: "",
            architectarray: [],
          }));
        }
      }

      if (name == "contractor") {
        if (value) {
          const findVendor = vendorMaster.find(
            (vendor) =>
              vendor.vendorcategory == "Contractor" && vendor.fullname == value
          );

          setData((prev) => ({
            ...prev,
            contractor: findVendor.fullname,
            contractorarray: [{ ...findVendor }],
          }));
        } else {
          setData((prev) => ({
            ...prev,
            contractor: "",
            contractorarray: [],
          }));
        }
      }
    }

    // if (name == "architect") {
    //   if (value) {
    //     const findVendor = vendorMaster.find(
    //       (vendor) =>
    //         vendor.vendorcategory == "Architect" && vendor.fullname == value
    //     );

    //     setData((prev) => ({
    //       ...prev,
    //       architect: findVendor.fullname,
    //       architectarray: [{ ...findVendor }],
    //     }));
    //   } else {
    //     setData((prev) => ({
    //       ...prev,
    //       architect: "",
    //       architectarray: [],
    //     }));
    //   }
    // }

    // if (name == "contractor") {
    //   if (value) {
    //     const findVendor = vendorMaster.find(
    //       (vendor) =>
    //         vendor.vendorcategory == "Contractor" && vendor.fullname == value
    //     );

    //     setData((prev) => ({
    //       ...prev,
    //       contractor: findVendor.fullname,
    //       contractorarray: [{ ...findVendor }],
    //     }));
    //   } else {
    //     setData((prev) => ({
    //       ...prev,
    //       contractor: "",
    //       contractorarray: [],
    //     }));
    //   }
    // }

    // if (name == "customerid") {
    //   let findCustomer = customerMaster.find(
    //     (customer) => customer.customerid == value
    //   );
    //   if (value) {
    //     if (findCustomer.customertype == "Retail") {
    //       setData((prev) => ({
    //         ...prev,
    //         customertype: findCustomer.customertype,
    //         customerfullname: findCustomer.fullname,
    //         company: "",
    //         customerid: findCustomer.customerid,
    //       }));
    //     } else {
    //       setData((prev) => ({
    //         ...prev,
    //         customertype: findCustomer.customertype,
    //         customerfullname: "",
    //         company: findCustomer.bdcompany,
    //         customerid: findCustomer.customerid,
    //       }));
    //     }
    //   } else {
    //     setData((prev) => ({
    //       ...prev,
    //       customertype: "Institutional",
    //       customerfullname: "",
    //       company: "",
    //       customerid: "",
    //     }));
    //   }
    // }
  };
  const handleFileChange = (event) => {
    if (event.target.name === "documents") {
      const files = event.target.files;
      handleUploadFile(files);
    }
  };

  const handleUploadFile = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/projects/fileupload",

        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      response.data.files.length > 0
        ? setData((prev) => ({ ...prev, attachments: response.data.files }))
        : setData((prev) => ({ ...prev, attachments: [] }));

      return response.data.files; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const handleSave = async () => {
    if (
      !data.projectid ||
      !data.projectname ||
      !data.status ||
      !data.projectenddate ||
      !data.projectstartdate 
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty fields");
    }

    //  let multipledocumentResponse;
    //  try {
    //    multipledocumentResponse = await handleUploadFile(data.attachments);
    //  } catch (error) {
    //    notifyError(error)
    //    console.error("Error uploading file:", error);
    //    // Handle the error, possibly notify the user
    //    return;
    //  }

    dispatch(updateProject(data, navigate));
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(getProjectById, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  const fetchCustomerData = async () => {
    try {
      const res = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/customer/getallcustomerpage",

        {
          keyword: "₹ΘθϖΠ",
          userid: userid,
          branchid: branchid,
          companyid: companyid,
          branch: branch,
          companyname: companyname,
          org: org,
        }
      );
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data.data : [];

        setCustomerMaster(
          initialData?.map((customer) => ({
            value: customer.customerid,
            b_id: customer.id,
            label: `${customer.customerid}-${
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany
            }`,
            type: customer.customertype,
            name:
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany,
          }))
        );
      // dispatch(reloadPage(false));
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchArchitectContractorData = async (projectid) => {
    
    try {

      const res = await axios.post("https://erp.venturesathi.co.in/api/v1/projects/get", {
        userid,
        branchid,
        companyid,
        projectid: projectid,
      });
      res.data.length>0?setArchitectContractorHistory((prev)=>res.data):setArchitectContractorHistory((prev)=>[])
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
    fetchCustomerData();
  }, []);

  useEffect(()=>{
    fetchData();
  },[])

  useEffect(() => {
    fetchArchitectContractorData(id)
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/sales/projectManagement")}
            />
            Update <span className="form-title main">Project Details </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Customer ID <span className="star">*</span>
                          </Form.Label>
                          {/* <Form.Select
                            name="customerid"
                            value={data.customerid}
                            onChange={handleChange}
                            isInvalid={!data.customerid && showEmptyError}
                          >
                            <option value="">Select</option>
                            {customerMaster.map((customer) => (
                              <option
                                value={customer.customerid}
                                key={customer.id}
                              >
                                {customer.customerid}
                              </option>
                            ))}
                          </Form.Select> */}

                          <Select
                            // name="customerid"
                            value={{ value: data.customerid, label: data.customerdetails }}
                            onChange={handleChange}
                            options={customerMaster}
                            isSearchable
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Project ID<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="projectid"
                            value={data.projectid}
                            onChange={handleChange}
                            isInvalid={!data.projectid && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      {data.customertype == "Institutional" ? (
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Company Name<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="Enter Company Name"
                              name="company"
                              value={data.company}
                              // onChange={handleChange}
                              isInvalid={!data.company && showEmptyError}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Customer Name<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="Enter Customer Name"
                              name="customerfullname"
                              value={data.customerfullname}
                              // onChange={handleChange}
                              isInvalid={!data.customerid && showEmptyError}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                      )}
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Project Name<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Project Name"
                            name="projectname"
                            value={data.projectname}
                            onChange={handleChange}
                            isInvalid={!data.customerid && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Project Start Date. <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="projectstartdate"
                            value={data.projectstartdate}
                            onChange={handleChange}
                            isInvalid={!data.projectstartdate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Project End Date. <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="projectenddate"
                            value={data.projectenddate}
                            onChange={handleChange}
                            isInvalid={!data.projectenddate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Project Location</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Location"
                        name="projectlocation"
                        value={data.projectlocation}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Architect 
                          </Form.Label>
                          <Form.Select
                            name="architect"
                            value={data.architect}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            {vendorMaster
                              .filter(
                                (vendor) => vendor.vendorcategory == "Architect"
                              )
                              .map((vendor) => {
                                return (
                                  <option value={vendor.fullname} key={vendor.id}>
                                    {vendor.fullname}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Contractor 
                          </Form.Label>
                          <Form.Select
                            name="contractor"
                            value={data.contractor}
                            onChange={handleChange}
                          >
                            <option value="">Select</option>
                            {vendorMaster
                              .filter(
                                (vendor) =>
                                  vendor.vendorcategory == "Contractor"
                              )
                              .map((vendor) => {
                                return (
                                  <option value={vendor.fullname} key={vendor.id}>
                                    {vendor.fullname}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Remarks.."
                        name="remarks"
                        value={data.remarks}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Status <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="status"
                            value={data.status}
                            onChange={handleChange}
                            isInvalid={!data.status && showEmptyError}
                          >
                            <option value="">Select</option>
                            <option value="Planned">Planned</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Completed">Completed</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>Attach Files</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleFileChange}
                            name="documents"
                            multiple
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Label>Uploaded Attachments</Form.Label>
                    <div className="uploaded-data">
                      {data.attachments.length > 0 ? (
                        data.attachments.map((item, index) => {
                          return (
                            
                              <span key={index}>
                                <a href={item} target="blank">
                                  <IoDocumentAttach />
                                </a>
                              </span>
                            
                          );
                        })
                      ) : (
                        <div className="uploaded-data">
                          <span>No Document Found</span>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <br />
        <Row>
          <Col className="table-main-container ">
            <Table responsive>
              <thead className="tableContainer">
                <tr>
                  <th> # </th>
                  <th> Contractor/Architect </th>
                  <th> Type </th>
                  <th> Start Date </th>
                  <th> End Date </th>
                </tr>
              </thead>
              <tbody>
                {ArchitectContractorHistory.length > 0 ? (
                  ArchitectContractorHistory.map((history, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        <td>{history.name}</td>
                        <td>{history.type}</td>
                        <td>{history.projectstartdate}</td>
                        <td>{history.projectenddate}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">
                      <div className="datanotfound-center-text">
                        No Contractor/Architect Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          <Col></Col>
        </Row>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/sales/projectManagement")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditProjectManagement;
