import React, { useEffect, useState } from "react";
import { Col, FormControl, Row, Table } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import AddAccountLedger from "./AddAccountLedger";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
// import EditAccountLedger from "./EditAccountLedger";
// import ViewAccountLedger from "./ViewAccountLedger";
import SearchField from "../../../constants/SearchField";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { reloadPage } from "../../../redux/actions/load.action";
import ViewAccountLedger from "./ViewAccountLedger";
import EditAccountLedger from "./EditAccountLedger";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import {
  deleteLedger,
  ledgerPagiData,
} from "../../../redux/actions/ledger.actions";

const ListTableAccountLedger = () => {
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.ledgerData.ledgerPagiData) || [];
  const filteredData = !data.data ? [] : data.data;

  // pagination data
  const totalPagesFromAPI = {
    totalRecords: !data.totalItems ? 0 : data.totalItems,
    totalPages: !data.totalPages ? 0 : data.totalPages,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(ledgerPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleEditModal = (item) => {
    setShowEditModal(true);
    setEditItem(item);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditItem(null);
  };

  const handleShow = (item) => {
    const { id, ledgerid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(ledgerid);
  };

  const handleClose = () => {
    setShow(false);
    dispatch(reloadPage(true));
  };

  const handleDelete = () => {
    dispatch(
      deleteLedger(
        {
          id: selectedItemId,
          ledgerid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };


  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);


  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Ledger",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <>
      <Row>
        <Col md={12} className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              <p className="section-title form-section ptag">
                List of All <span className="form-title">Ledgers</span>
              </p>
            </Col>
            <Col lg={2}>
              <SearchField
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
            <Col lg={2}>
              {crud && crud.includes("c") && <AddAccountLedger />}
            </Col>
          </Row>
        </Col>
        <Col md={12} className="table-main-container">
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th>Ledger ID</th>
                <th>ledger</th>
                <th>Account ID</th>
                <th>Account Name</th>
                <th>Account Type</th>
                <th>Ledger Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loadData || loading ? (
                <>
                  <DataSpinner count="7" />
                </>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, index) => {
                  const {
                    id,
                    ledgerid,
                    ledgername,
                    accountid,
                    accountname,
                    accounttype,
                    ledgertype,
                  } = item;
                  return (
                    <tr key={index}>
                      <td>{!ledgerid ? "-- " : ledgerid}</td>
                      <td>{!ledgername ? "-- " : ledgername}</td>
                      <td>{!accountid ? "-- " : accountid}</td>
                      <td>{!accountname ? "-- " : accountname}</td>
                      <td>{!accounttype ? "-- " : accounttype}</td>
                      <td>{!ledgertype ? "-- " : ledgertype}</td>
                      <td className="d-flex justify-content-center gap-2">
                        <span>
                          <ViewAccountLedger item={item} />
                        </span>
                        <span>
                          {crud && crud.includes("u") && (
                            <EditAccountLedger
                              showEditModal={showEditModal}
                              handleEditModal={() => handleEditModal(item)}
                              handleCloseEditModal={handleCloseEditModal}
                              editItem={editItem}
                            />
                          )}
                        </span>
                        <span>
                          {crud && crud.includes("d") && (
                            <DeleteConfirmation
                              show={show}
                              handleShow={() => handleShow(item)}
                              handleClose={handleClose}
                              handleDelete={handleDelete}
                            />
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecordFoud count="7" name="Ledger" />
              )}
            </tbody>
          </Table>
        </Col>
        <GlobalPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPagesFromAPI={totalPagesFromAPI}
          setPageSize={setPageSize}
        />
      </Row>
    </>
  );
};

export default ListTableAccountLedger;
