import React, { useEffect, useState } from "react";
import NewSeries from "./NewSeries";
import { Col, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DataSpinner from "../../../constants/DataSpinner";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import EditSeries from "./EditSeries";

const ListSeries = () => {
  const { companyid, userid, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [itemId, setItemid] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  const handleShow = (item) => {
    const { id, seriesid } = item;
    setShow(true);
    setItemid(id);
    setSelectedItemNo(seriesid);
  };
  const handleClose = () => {
    setShow(false);
    dispatch(reloadPage(true));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {

        const res = await axios.post("https://erp.venturesathi.co.in/api/v1/item/getseries", {

          keyword: searchKeyword || org,
        });
        const initialData = res.data && res.data.length > 0 ? res.data : [];
        setData(initialData);
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loadData, searchKeyword]);

  const handleDelete = () => {
    const deleteData = async () => {
      try {
        const res = await axios.post(

          "https://erp.venturesathi.co.in/api/v1/item/deleteseries",

          {
            id: itemId,
            seriesid: selectedItemNo,
            companyid: companyid,
            userid: userid,
            org: org,
          }
        );
        if (res.data.message == "Series deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError("Series not deleted");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    deleteData();
  };
  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Series</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            <NewSeries />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading
            data={["Series ID", "Brand", "Series", "Rate", "Action"]}
          />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="5" />
            ) : data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.seriesid}</td>
                  <td>{item.brand}</td>
                  <td>{item.series}</td>
                  <td>{item.rate}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <EditSeries id={item.id} />
                      <DeleteConfirmation
                        show={show}
                        handleShow={() => handleShow(item)}
                        handleClose={() => setShow(false)}
                        handleDelete={handleDelete}
                        name="Series"
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="5" name="Series" />
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListSeries;
