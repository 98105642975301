import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../constants/SearchField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FcInfo } from "react-icons/fc";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
const ModalPriceUpdate = ({ setCloseLoading, closeLoading }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const loadData = useSelector((state) => state.loadReducer);

  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);
  const [brandMaster, setBrandMaster] = useState([]);
  const [seriesMaster, setSeriesMaster] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredBy, setFilteredBy] = useState({
    category: "",
    brand: "",
    series: "",
  });

  const [data, setData] = useState({
    itemarray: [],
    price: 0,
    discount: 0,
    mop: "",
  });

  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilteredBy((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === '-' || event.keyCode === 189 || event.which === 189) {
  //     event.preventDefault();
  //   }
  // };

  const handleClose = () => {
    setShow(false);
    setFilteredBy((prev) => ({ ...prev, brand: "", category: "", series: "" }));
    setData((prev) => ({ ...prev, itemarray: [], price: 0, discount: 0 ,mop:""}));
    setCloseLoading(!closeLoading);
  };

  useEffect(() => {
    //for item master
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/getitem", {
        companyid,
        userid,
        branchid,
        keyword: "" || org,
      })
      .then((res) => {
        dispatch(reloadPage(true));
        res.data.data ? setItemMaster(res.data.data) : setItemMaster([]);
        dispatch(reloadPage(false));
      })
      .catch((err) => {
        dispatch(reloadPage(false));
        console.log(err);
      });

    //for category master
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/category", {
        companyid,
        userid,
        branchid,
        keyword: "" || org,
      })
      .then((res) => {
        dispatch(reloadPage(true));
        res.data.data
          ? setCategoryMaster(res.data.data)
          : setCategoryMaster([]);
        dispatch(reloadPage(false));
      })
      .catch((err) => {
        dispatch(reloadPage(false));
        console.log(err);
      });

    //for brand master
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/getbrand", {
        companyid,
        userid,
        branchid,
        keyword: "" || org,
      })
      .then((res) => {
        dispatch(reloadPage(true));
        res.data.data ? setBrandMaster(res.data.data) : setBrandMaster([]);
        dispatch(reloadPage(false));
      })
      .catch((err) => {
        dispatch(reloadPage(true));
        console.log(err);
        dispatch(reloadPage(false));
      });

    //for series master
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/getseries", {
        keyword: "" || org,
      })
      .then((res) => {
        dispatch(reloadPage(true));
        res.data.length ? setSeriesMaster(res.data) : setSeriesMaster([]);
        dispatch(reloadPage(false));
      })
      .catch((err) => {
        dispatch(reloadPage(true));
        console.log(err);
        dispatch(reloadPage(false));
      });
  }, [loadData, closeLoading]);

  const handleRowSelect = (e, item, index) => {
    if (e.target.checked) {
      setData((prev) => ({
        ...prev,
        itemarray: [...data.itemarray, item],
      }));
    } else {
      const filteredItemArr = data.itemarray.filter((row) => row.id != item.id);
      setData((prev) => ({ ...prev, itemarray: filteredItemArr }));
    }
  };

  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setData((prev) => ({ ...prev, itemarray: filteredData }));
    } else {
      setData((prev) => ({ ...prev, itemarray: [] }));
    }
  };

  const filterData = () => {
    // Apply filters to itemMaster based on filteredBy and searchKeyword
    const filteredItems = itemMaster.filter((item) => {
      // Filter by brand (if brand filter is applied)
      if (filteredBy.brand && filteredBy.brand !== item.brand) {
        return false;
      }

      // Filter by category (if category filter is applied)
      if (filteredBy.category && filteredBy.category !== item.category) {
        return false;
      }

      // Filter by series (if series filter is applied)
      if (filteredBy.series && filteredBy.series !== item.series) {
        return false;
      }

      // Filter by search keyword (if searchKeyword is provided)
      if (
        searchKeyword &&
        !Object.values(item).some((value) =>
          value
            ? value
                .toString()
                .toLowerCase()
                .includes(searchKeyword.toLowerCase().trim())
            : false
        )
      ) {
        return false;
      }

      return true; // Include item in filtered result
    });

    return filteredItems;
  };

  // Call filterData to get the filtered data
  const filteredData = filterData();

  const handleSave = async () => {  
    
      try {
        dispatch(reloadPage(true));
        let res = await axios.post(
          "https://erp.venturesathi.co.in/api/v1/item/multipleupdate",

          data
        );
        
        if (res.data.message == "Item updated Successfully") {
          dispatch(reloadPage(false));
          notifySuccess(res.data.message);
          handleClose();
        } else {
          dispatch(reloadPage(false));
          notifyError("Something Went Wrong..");
        }
      } catch (error) {
        dispatch(reloadPage(false));
        console.log(error);
        notifyError(error.response.data.message);
      }
    
  };

  return (
    <>
      <Button className="commonBtn mt-2" onClick={() => setShow(true)}>
        Update Price
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="form-title">Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="d-flex align-items-center jusify-content-center">
              <Col>
                <Form.Select
                  name="category"
                  value={filteredBy.category}
                  onChange={handleFilterChange}
                  // isInvalid={!data.vendor && showEmptyError}
                >
                  <option value="">Select Category</option>
                  {categoryMaster.map((category) => (
                    <option value={category.category} key={category.id}>
                      {category.category}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <Form.Select
                  name="brand"
                  value={filteredBy.brand}
                  onChange={handleFilterChange}
                  // isInvalid={!data.vendor && showEmptyError}
                >
                  <option value="">Select Brand</option>
                  {filteredBy.category
                    ? itemMaster
                        ?.filter((item) => item.category == filteredBy.category)
                        .map((item) => (
                          <option value={item.brand} key={item.id}>
                            {item.brand}
                          </option>
                        ))
                    : brandMaster?.map((brand) => (
                        <option value={brand.brand} key={brand.id}>
                          {brand.brand}
                        </option>
                      ))}
                </Form.Select>
              </Col>
              <Col className="my-2">
                <Form.Select
                  name="series"
                  value={filteredBy.series}
                  onChange={handleFilterChange}
                  // isInvalid={!data.vendor && showEmptyError}
                >
                  <option value="">Select Series</option>
                  {filteredBy.brand
                    ? seriesMaster
                        ?.filter((series) => series.brand == filteredBy.brand)
                        .map((series) => (
                          <option value={series.series} key={series.id}>
                            {series.series}
                          </option>
                        ))
                    : seriesMaster?.map((series) => (
                        <option value={series.series} key={series.id}>
                          {series.series}
                        </option>
                      ))}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col>
                <SearchField
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </Col>
            </Row>
            <br />
            {filteredData.length > 1 ? (
              <Row>
                <Col
                  className="d-flex justify-content-end align-items-center gap-3"
                  style={{ paddingRight: "20px" }}
                >
                  <h3>Select All</h3>
                  <div>
                    <input type="checkbox" onChange={handleAllCheck} />
                  </div>
                </Col>
              </Row>
            ) : null}
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding: "5px 7px",
                height: "250px",
                borderRadius: "3px",
                overflow: "auto",
                scrollbarWidth: "none", // For Firefox
                WebkitScrollbarWidth: "none", // For Chrome and Safari
                msOverflowStyle: "none", // For Internet Explorer and Edge
                // Additional styles to hide scrollbar
                scrollbarColor: "transparent transparent", // For Firefox
                WebkitScrollbarTrack: {
                  boxShadow: "inset 0 0 0 100px transparent", // For Chrome and Safari
                },
              }}
            >
              {filteredData.length > 0
                ? filteredData.map((item, index) => (
                    <div
                      key={item.id}
                      className="d-flex align-items-center justify-content-between my-4 p-4"
                      style={{
                        boxShadow:
                          "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                      }}
                    >
                      <h2 style={{ width: "35%" }}>{item.series}</h2>
                      <h2 style={{ width: "35%" }}>{item.itemname}</h2>
                      <img
                        src={
                          !item.image || !item.image.length > 0
                            ? ""
                            : item.image[0]
                        }
                        alt="No Image"
                        width={100}
                        height={80}
                        style={{ borderRadius: "5px" }}
                      />
                      <input
                        type="checkbox"
                        checked={data.itemarray.some(
                          (row) => item.id == row.id
                        )}
                        onChange={(e) => handleRowSelect(e, item, index)}
                      />
                    </div>
                  ))
                : null}
            </div>
          </Container>
          <div className="my-4">
            <div className="d-flex justify-content-between align-items-center gap-2 my-2">
              <div className="d-flex align-items-center justify-content-start" style={{width:"50%"}}>
                <h3>Increase/Decrease By</h3>
                <OverlayTrigger
                  placement="right"
                  overlay={renderTooltip(
                    `Add ‘-’ symbol before the number to decrease the price and discount value, Ex: -50`
                  )}
                >
                  <span className="question_icon fs-4 text-center">
                    <FcInfo style={{ margin: "auto" }} />
                  </span>
                </OverlayTrigger>
              </div>
              <h3 style={{width:"50%"}}>Update MOP Price</h3>
            </div>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="pin">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={data.price}
                        onChange={handleChange}
                        min={"0"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="pin">
                      <Form.Label>Discount</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          min={"0"}
                          max={"100"}
                          name="discount"
                          onChange={handleChange}
                          // onKeyDown={handleKeyDown}
                          value={data.discount}
                        />
                        <InputGroup.Text className="fs-2"> %</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="pin">
                  <Form.Label>M.O.P</Form.Label>
                  <Form.Control
                    type="number"
                    name="mop"
                    value={data.mop}
                    onChange={handleChange}
                    // min={"0"}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className=" commonBtn cancelBtn smallBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPriceUpdate;
