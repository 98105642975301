import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { BsPlusCircle } from "react-icons/bs";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import axios from "axios";
import {
  customerGetPost,
  employeeGetpagePost,
  getProjectApi,
  itemGetItemPost,
  quoteGetpagePost,
  quoteUpdatePost,
} from "../../../constants/api";
import { initialData } from "../../../constants/resData";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";
import Select from "react-select";

const EditQuote = () => {
  const { id } = useParams();
  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let userName = localData.name;
  let org = localData.org;

  const [data, setData] = useState({
    customerid: "",
    customername: "",
    customerdetails: "",
    bacountry: "",
    bastate: "",
    bacity: "",
    baaddress: "",
    bapincode: "",
    bdgstno: "",
    phone: "",
    saleperson: "",
    quoteno: "",
    quotedate: "",
    project: "",
    projectid: "",
    createdby: userName,
    expiredby: "",
    itemarray: [],
    subtotal: "",
    discount: "",
    discountamount: "",
    tax: "",
    adjustment: "",
    finalamount: "",
    tandc: "",
    branchid: branchID,
    companyid: companyID,
    userid: userID,
    org: org,
    status: "",
  });
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [customerIDArray, setCustmerIDArray] = useState([]);
  const [projectArray, setProjectArray] = useState([]);
  const [showGST, setShowGST] = useState(false);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [employee, setEmployee] = useState([]);

  useEffect(() => {
    axios
      .post(quoteGetpagePost, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "₹ΘθϖΠ",
      })
      .then((res) => {
        setData(initialData(res).filter((ele) => ele.id == id)[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Customer ID Array
    axios
      .post("https://erp.venturesathi.co.in/api/v1/customer/getallcustomer", {
        userid: userID,
      })
      .then((res) => {
        const initialData =
          res.data && res.data && res.data.length > 0 ? res.data : [];
        setCustmerIDArray(
          initialData.map((customer) => ({
            ...customer,
            value: customer.customerid,
            label: `${customer.customerid}-${
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany
            }`,
            name:
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany,
          }))
        );
      })
      .catch((err) => console.log(err));

    // Customer ID Related Projects
    axios
      .post(getProjectApi, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "",
      })
      .then((res) => {
        setProjectArray(
          initialData(res).filter((ele) => ele.customerid == data.customerid)
        );
      })
      .catch((err) => console.log(err));

    // All Items
    axios
      .post(itemGetItemPost, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "",
      })
      .then((res) => {
        setItems(initialData(res));
      })
      .catch((err) => console.log(err));
  }, [data.customerid]);

  useEffect(() => {
    //dynamic brand generate
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/category", {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "₹ΘθϖΠ" || "",
      })
      .then((res) => {
        setCategory(initialData(res));
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //dynamic brand generate
    axios
      .post(employeeGetpagePost, {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "₹ΘθϖΠ" || "",
      })
      .then((res) => {
        setEmployee(
          initialData(res).filter(
            (emp) => emp.issale == "Yes" && emp.status == "Active"
          )
        );
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleLeftArrow = () => {
    navigate("/sales/quotation");
  };
  const handleShowGSTChange = (e) => {
    setShowGST(e.target.checked);
    const { name, value, checked } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
      isshowgst: data.isshowgst ? "" : "Yes",
    }));
  };
  function handleAddNewRow() {
    setData((prevState) => ({
      ...prevState,
      itemarray: [
        ...prevState.itemarray,
        {
          amount: "",
          gst: "",
          image: "",
          itemid: "",
          itemname: "",
          qty: "",
          rate: "",
          unit: "",
        },
      ], // Add a new object at the end of the array
    }));
  }
  const handleChange = (e) => {
    if (e.value) {
      setData((prev) => ({
        ...prev,
        customername: e.name,
        customerid: e.value,
        customerdetails: e.label,
        bacountry: e.bacountry,
        bastate: e.bastate,
        bacity: e.bacity,
        baaddress: e.baaddress,
        bapincode: e.bapincode,
        bdgstno: e.bdgstno,
        phone: e.phone,
      }));
    } else {
      const { name, value } = e.target;
      if (name == "customerid") {
        const selectedCustomer = customerIDArray.find(
          (itm) => itm.customerid === value
        );
        if (selectedCustomer && selectedCustomer.customertype == "Retail") {
          setData((prevData) => ({
            ...prevData,
            customername: selectedCustomer.fullname,
            bacountry: selectedCustomer.bacountry,
            bastate: selectedCustomer.bastate,
            bacity: selectedCustomer.bacity,
            baaddress: selectedCustomer.baaddress,
            bapincode: selectedCustomer.bapincode,
            bdgstno: selectedCustomer.bdgstno,
            phone: selectedCustomer.phone,
            [name]: value,
          }));
        } else if (
          selectedCustomer &&
          selectedCustomer.customertype == "Institutional"
        ) {
          setData((prevData) => ({
            ...prevData,
            customername: selectedCustomer.bdcompany,
            bacountry: selectedCustomer.bacountry,
            bastate: selectedCustomer.bastate,
            bacity: selectedCustomer.bacity,
            baaddress: selectedCustomer.baaddress,
            bapincode: selectedCustomer.bapincode,
            bdgstno: selectedCustomer.bdgstno,
            phone: selectedCustomer.phone,
            [name]: value,
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            customername: "",
            bacountry: "",
            bastate: "",
            bacity: "",
            baaddress: "",
            bapincode: "",
            bdgstno: "",
            phone: "",
            [name]: value,
          }));
        }
      }

      if (name == "project") {
        const selectedProject = projectArray.find(
          (itm) => itm.projectname === value
        );
        if (selectedProject) {
          setData((prevData) => ({
            ...prevData,
            projectid: selectedProject.projectid,
            [name]: value,
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            projectid: "",
            [name]: value,
          }));
        }
      }

      if (name == "discount") {
        setData((prevData) => ({
          ...prevData,
          discountamount: (data.subtotal * Number(value)) / 100,
          [name]: value,
        }));
      }

      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setShowEmptyError(false);
  };
  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;

    if (name == "brand") {
      if (value) {
        const findItem = items.find((item) => item.brand == value);
        setData((prevState) => {
          const updatedItemArray = prevState.itemarray.map((item, i) =>
            i === ind
              ? {
                  ...item,
                  [name]: value,
                  itemname: findItem.itemname || "",
                  itemid: findItem.itemid || "",
                  gst: findItem.gst || "",
                  unit: findItem.unit || "",
                  rate: findItem.rateperunit || "",
                  image: findItem.image[0] || "",
                }
              : item
          );

          return { ...prevState, itemarray: updatedItemArray };
        });
      } else {
        setData((prevState) => {
          const updatedItemArray = prevState.itemarray.arr.map((item, i) =>
            i === ind
              ? {
                  ...item,
                  [name]: value,
                  itemname: "",
                  itemid: "",
                  gst: "",
                  unit: "",
                  category: "",
                }
              : item
          );

          return { ...prevState, itemarray: updatedItemArray };
        });
      }
    }
    const updatedItemArr = data.itemarray.map((item, i) =>
      i == ind ? { ...item, [name]: value } : item
    );

    setData((prev) => ({
      ...prev,
      itemarray: updatedItemArr,
      subtotal: updatedItemArr.reduce((total, row) => {
        return total + (Number(row.qty) * Number(row.rate) || 0).toFixed(2);
      }, 0),
      tax: updatedItemArr.reduce((total, row) => {
        return (
          total +
          ((Number(row.qty) * Number(row.rate) || 0) *
            parseInt(row.gst.replace(/\D/g, ""))) /
            100
        ).toFixed(2);
      }, 0),

      discountamount:
        (updatedItemArr.reduce((total, row) => {
          return total + (Number(row.qty) * Number(row.rate) || 0);
        }, 0) *
          Number(data.discount)) /
        100,
    }));
  };

  const handleDraft = async () => {
    if (
      data.customerid == "" ||
      data.quotedate == "" ||
      //data.project == "" ||
      data.saleperson == "" ||
      data.expiredby == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    const res = await axios.post(quoteUpdatePost, {
      ...data,
      status: "Drafted",
    });
    if (res.statusText == "OK") {
      notifySuccess("Quotation updated as Draft.");
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  const handleSubmit = async () => {
    if (
      data.customerid == "" ||
      data.quotedate == "" ||
      //data.project == "" ||
      data.saleperson == "" ||
      data.expiredby == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    const res = await axios.post(quoteUpdatePost, {
      ...data,
      status: "Created",
    });
    if (res.statusText == "OK") {
      notifySuccess(`Quotation ${data.quoteno} updation Successful.`);
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  return (
    <>
      <Container fluid>
        {" "}
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />
              Update <span className="form-title main"> Quotation</span>
            </h1>
            <Container fluid>
              <Row lg={4} md={2} sm={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Customer ID <span className="star">*</span>
                    </Form.Label>
                    {/* <Form.Select
                      name="customerid"
                      value={data.customerid}
                      onChange={handleChange}
                      isInvalid={data.customerid === "" && showEmptyError}
                    >
                      <option value="">Select Customer ID</option>

                      {customerIDArray.length > 0 &&
                        customerIDArray.map((ele, index) => (
                          <option key={index} value={ele.customerid}>
                            {ele.customerid} &nbsp; -
                            {ele.customertype == "Retail"
                              ? ele.fullname
                              : ele.bdcompany}
                          </option>
                        ))}
                    </Form.Select> */}
                    <Select
                      // name="customerid"
                      value={{
                        value: data.customerid,
                        label: data.customerdetails,
                      }}
                      onChange={handleChange}
                      options={customerIDArray}
                      isSearchable
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Customer ID
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="customername"
                      value={data.customername}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Quotation No</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="quoteno"
                      value={data.quoteno}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Quote Date <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="quotedate"
                      value={data.quotedate}
                      onChange={handleChange}
                      isInvalid={data.quotedate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Quotation Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row lg={4} md={2} sm={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Project</Form.Label>
                    <Form.Select
                      name="project"
                      value={data.project}
                      onChange={handleChange}
                      //isInvalid={data.project === "" && showEmptyError}
                    >
                      <option value="">Select Project</option>
                      {projectArray.length > 0 &&
                        projectArray.map((ele, index) => (
                          <option key={index} value={ele.projectname}>
                            {ele.projectname}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Project
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Sales Person <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="saleperson"
                      value={data.saleperson}
                      onChange={handleChange}
                      isInvalid={data.saleperson === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {employee.length > 0 &&
                        employee.map((ele, index) => (
                          <option key={index} value={ele.fullname}>
                            {ele.fullname}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Sales Person
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="createdby"
                      value={data.createdby}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Expiry Date <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="expiredby"
                      value={data.expiredby}
                      onChange={handleChange}
                      isInvalid={data.expiredby === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Expiry Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
          <hr />
          <Form.Check
            inline
            label="Show GST"
            name="gstcheck"
            type="checkbox"
            checked={data.isshowgst}
            onChange={handleShowGSTChange}
          />
          <Table responsive className="table-main-container">
            <TableHeading
              data={
                showGST
                  ? [
                      "#",
                      "Category",
                      "Brand",
                      "Item Name",
                      "Item ID",
                      "Unit",
                      "Qty",

                      "Rate",
                      "GST",
                      "Discount in %",
                      "Amount",
                    ]
                  : [
                      "#",
                      "Category",
                      "Brand",
                      "Item Name",
                      "Item ID",
                      "Unit",
                      "Qty",
                      "Rate (incl. of gst)",
                      "Rate",
                      "Discount in %",
                      "Amount",
                    ]
              }
            />
            {data.itemarray.length > 0 ? (
              <tbody>
                {data.itemarray.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        className="text-center"
                        disabled
                        readOnly
                        value={index + 1}
                      />
                    </td>
                    {/*  <td>
                    <Image
                      src={row.image}
                      fluid
                      thumbnail
                      style={{ width: "100px", height: "50px" }}
                    />
                  </td> */}
                    <td>
                      <Form.Select
                        name="category"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.category}
                        isInvalid={row.category === "" && showEmptyError}
                      >
                        <option value="">--Select--</option>
                        {category.length > 0 &&
                          category.map((ele, index) => (
                            <option key={index} value={ele.category}>
                              {ele.category}
                            </option>
                          ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="brand"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.brand}
                        isInvalid={row.brand === "" && showEmptyError}
                      >
                        <option value="">--Select--</option>
                        {items.length > 0 &&
                          items
                            .filter((item) => item.category === row.category)
                            .reduce((uniqueBrands, item) => {
                              if (!uniqueBrands.includes(item.brand)) {
                                uniqueBrands.push(item.brand);
                              }
                              return uniqueBrands;
                            }, [])
                            .map((brand, index) => (
                              <option key={index} value={brand}>
                                {brand}
                              </option>
                            ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="itemname"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.itemname}
                        isInvalid={row.itemname === "" && showEmptyError}
                      >
                        <option value="">--Select--</option>
                        {items.length > 0 &&
                          items
                            .filter((item) => item.brand == row.brand)
                            .map((ele, index) => (
                              <option key={index} value={ele.itemname}>
                                {ele.itemname}
                              </option>
                            ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        disabled
                        className="text-center"
                        name="itemid"
                        placeholder="Item ID"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.itemid}
                      />
                    </td>
                    <td>
                      {" "}
                      <Form.Control
                        disabled
                        className="text-center"
                        name="unit"
                        placeholder="unit"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.unit}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        className="text-center"
                        name="qty"
                        placeholder="qty"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.qty}
                      />
                    </td>
                    {!showGST && (
                      <td>
                        <Form.Control
                          disabled
                          type="number"
                          className="text-center"
                          name="rateinclgst"
                          placeholder="rateinclgst"
                          onChange={(e) => handleTableRowChange(e, index)}
                          value={row.rateinclgst}
                        />
                      </td>
                    )}
                    <td>
                      <Form.Control
                        disabled
                        type="number"
                        className="text-center"
                        name="rate"
                        placeholder="rate"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.rate}
                      />
                    </td>
                    {showGST && (
                      <td>
                        <Form.Control
                          type="text"
                          className="text-center"
                          name="gst"
                          placeholder="gst"
                          onChange={(e) => handleTableRowChange(e, index)}
                          value={row.gst}
                        />
                      </td>
                    )}
                    <td>
                      <Form.Control
                        type="number"
                        className="text-center"
                        name="discount"
                        placeholder="discount"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.discount}
                      />
                    </td>
                    <td>
                      <Form.Control
                        disabled
                        type="number"
                        className="text-center"
                        name="amount"
                        placeholder="amount"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={(row.amount =
                          row.qty * row.rate -
                          (row.qty * row.rate * row.discount) / 100).toFixed(2)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <NoRecordFoud count="9" name="Items" />
            )}
          </Table>
          <div className="d-flex justify-content-between">
            <span className="form-title table-row" onClick={handleAddNewRow}>
              <BsPlusCircle /> Add New Line
            </span>
            <span></span>
          </div>
          <Row>
            <Col></Col>
            <Col style={{ textAlign: "right" }}>
              <Row>
                <Col>
                  <Form.Label className="mt-2">Sub Total:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    className="mt-2"
                    disabled
                    type="number"
                    value={data.subtotal}
                  />
                </Col>
              </Row>

              {showGST && (
                <Row>
                  <Col>
                    <Form.Label className="mt-2">Tax:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      className="mt-2"
                      disabled
                      type="number"
                      value={
                        (data.tax = data.itemarray
                          ?.reduce((acc, curr) => {
                            return acc + (curr.amount * curr.gst) / 100;
                          }, 0)
                          .toFixed(2))
                      }
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Label className="mt-2">Adjustment:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    className="mt-2"
                    type="number"
                    name="adjustment"
                    value={data.adjustment}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="mt-2">Final Amount:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    className="mt-2"
                    disabled
                    type="number"
                    value={(data.finalamount =
                      Number(data.subtotal) +
                      Number(data.tax) +
                      Number(data.adjustment)).toFixed(2)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Terms and Conditions</Form.Label>
                <textarea
                  className="form-control"
                  rows="4"
                  name="tandc"
                  value={data.tandc}
                  onChange={handleChange}
                  required
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-5">
            <Button
              disabled={data.itemarray.length <= 0}
              className="commonBtn smallBtn"
              onClick={handleSubmit}
            >
              Update
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={handleDraft}
            >
              Draft
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={handleLeftArrow}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default EditQuote;
