import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { suggestedItemIdAutoCount } from "../../../constants/api";
import { notifyError } from "../../../constants/toastAlert";
import { FaArrowLeftLong } from "react-icons/fa6";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  addSuggestedItem,
  suggestedItemData,
} from "../../../redux/actions/suggesteditems.actions";
import SearchField from "../../../constants/SearchField";
import { MdRowing } from "react-icons/md";
const AddSuggestedItem = () => {
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const URL = extractAndFormatURLPart("inventory");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};

  const SGIDData =
    useSelector((state) => state.suggestedItemData.suggestedItemData.data) ||
    [];
  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [data, setData] = useState({
    groupid: "",
    groupname: "",
    createdby: name,
    category: "",
    brand: "",
    primaryitem: "",
    itemarray: [],
    branchid: branchid,
    companyid: companyid,
    userid: userid,
    org: org,
    itemname: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePrimaryItem = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value, itemarray: [] }));
  };

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
      brand: "",
      primaryitem: "",
      itemarray: [],
    }));
  };

  const handleChangeBrand = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
      primaryitem: "",
      itemarray: [],
    }));
  };

  const handleRowSelect = (e, row, index) => {
    if (e.target.checked) {
      setData((prev) => ({ ...prev, itemarray: [...data.itemarray, row] }));
    } else {
      const filteredItemArr = data.itemarray.filter(
        (item) => item.id != row.id
      );
      setData((prev) => ({ ...prev, itemarray: filteredItemArr }));
    }
  };

  const handleDeleteRow = (row) => {
    const filteredItemArr = data.itemarray.filter((item) => item.id != row.id);
    setData((prev) => ({ ...prev, itemarray: filteredItemArr }));
  };

  const handleSave = () => {
    if (
      !data.groupid ||
      !data.groupname ||
      !data.createdby ||
      !data.primaryitem ||
      !data.brand ||
      !data.category
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    dispatch(addSuggestedItem(data, navigate));
  };

  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: "" || org,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(suggestedItemData(backendData, setLoading));
  }, [dispatch]);

  const generateCategoryID = () => {
    if (SGIDData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = SGIDData[0].groupid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      groupid: `GRP/SAANVI/${paddedCount}`,
    }));
  }, []);

  const fetchItem = async () => {
    try {
      const res = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/item/getitem",
        {
          userid,
          branchid,
          companyid,
          keyword: "" || org,
        }
      );
      res.data.data ? setItemMaster(res.data.data) : setItemMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategory = async () => {
    try {
      const res = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/item/category",
        {
          userid,
          branchid,
          companyid,
          keyword: "" | org,
        }
      );
      res.data.data ? setCategoryMaster(res.data.data) : setCategoryMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  const filterData = () => {
    return itemMaster.filter((item) =>
      searchKeyword
        ? Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchKeyword.toLowerCase().trim())
          )
        : true
    );
  };

  const filteredData = filterData();

  useEffect(() => {
    fetchItem();
    // fetchBrand();
    fetchCategory();
  }, []);

  useEffect(() => {
    const newitemName = data.itemarray.map((item) => item.itemname).join("\n");
    setData((prev) => ({ ...prev, itemname: newitemName }));
  }, [data.itemarray]);
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/inventory/suggestedItem/")}
            />
            Add <span className="form-title main"> Suggested Item </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Group No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="groupid"
                            value={data.groupid}
                            isInvalid={!data.groupid && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Group Name<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="groupname"
                            value={data.groupname}
                            isInvalid={!data.groupname && showEmptyError}
                            onChange={handleChange}
                            placeholder="Enter Group Name"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Created By<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="createdby"
                        value={data.createdby}
                        isInvalid={!data.createdby && showEmptyError}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Category <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            name="category"
                            value={data.category}
                            onChange={handleChangeCategory}
                            isInvalid={!data.category && showEmptyError}
                            className="form-control "
                          >
                            <option value=""> Select </option>
                            {categoryMaster?.map((category, i) => (
                              <option key={i} value={category.category}>
                                {category.category}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Brand <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            name="brand"
                            value={data.brand}
                            onChange={handleChangeBrand}
                            isInvalid={!data.brand && showEmptyError}
                            className="form-control "
                          >
                            <option value=""> Select </option>
                            {[
                              ...new Set(
                                itemMaster
                                  .filter(
                                    (item) => item.category === data.category
                                  )
                                  .map((item) => item.brand)
                              ),
                            ].map((brand, i) => (
                              <option key={i} value={brand}>
                                {brand}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Primary Item<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        name="primaryitem"
                        value={data.primaryitem}
                        onChange={handleChangePrimaryItem}
                        isInvalid={!data.primaryitem && showEmptyError}
                        className="form-control "
                      >
                        <option value=""> Select </option>
                        {data.brand && data.category
                          ? itemMaster
                              .filter(
                                (item) =>
                                  item.category == data.category &&
                                  data.brand == item.brand
                              )
                              .map((item) => (
                                <option key={item.id}>{item.itemname}</option>
                              ))
                          : data.brand
                          ? itemMaster
                              .filter((item) => data.brand == item.brand)
                              .map((item) => (
                                <option key={item.id}>{item.itemname}</option>
                              ))
                          : data.category
                          ? itemMaster
                              .filter((item) => data.category == item.category)
                              .map((item) => (
                                <option key={item.id}>{item.itemname}</option>
                              ))
                          : null}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {data.primaryitem ? (
                    <Col>
                      <Form.Label className="d-flex w-100 align-items-center justify-content-between">
                        <div className="w-100">
                          Associated Items<span className="star"> *</span>
                        </div>
                        <div className="w-100">
                          <SearchField
                            searchKeyword={searchKeyword}
                            setSearchKeyword={setSearchKeyword}
                          />
                        </div>
                      </Form.Label>
                      <div className="viewpage-table-container">
                        <Table responsive className="mb-0">
                          <tbody className="table-main-container">
                            {filteredData.length > 0 &&
                              filteredData
                                .filter(
                                  (item) => item.itemname !== data.primaryitem
                                )
                                .map((row, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{row.itemname}</td>
                                    <td>
                                      <Image
                                        src={
                                          !row.image || !row.image.length > 0
                                            ? ""
                                            : row.image[0]
                                        }
                                        alt="No Image"
                                        fluid
                                        thumbnail
                                        style={{
                                          width: "160px",
                                          height: "80px",
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={data.itemarray.some(
                                          (item) => item.id == row.id
                                        )}
                                        onChange={(e) =>
                                          handleRowSelect(e, row, index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <br />
        <h3 className="fw-2">
          Total {data.itemarray.length} item associated with the primary item
        </h3>
        <br />
        {data.itemarray.length > 0 ? (
          <Col md={12} className="table-main-container">
            <Table responsive>
              <thead className="tableContainer">
                <tr>
                  <th>SI No.</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {data.itemarray.map((item, ind) => (
                  <tr key={item.id}>
                    <td>{ind + 1}</td>
                    <td>{item.itemid ? item.itemid : "--"}</td>
                    <td>{item.itemname ? item.itemname : "--"}</td>
                    <td>
                      <img
                        src={
                          !item.image || !item.image.length > 0
                            ? ""
                            : item.image[0]
                        }
                        alt="No Image"
                        width={40}
                        height={40}
                        className="rounded-3"
                      />
                      &nbsp; &nbsp; &nbsp;&nbsp;
                      <span
                        className="text-danger fs-1 cursor-pointer"
                        onClick={() => handleDeleteRow(item)}
                      >
                        <AiFillCloseCircle />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ) : null}
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/inventory/suggestedItem")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default AddSuggestedItem;
