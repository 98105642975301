import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong, FaFileExport } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { HiViewColumns } from "react-icons/hi2";
import { coacoabysitepost } from "../../../../constants/api";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const ListTable = () => {
  const pdfRef = useRef(null);

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
    let userID = localData.userid;
    let branchID = localData.branchid;
    let companyID = localData.companyid;
    let org = localData.org;
    let financialyear= localStorage.financialyear;
    // const financialyear = JSON.parse(financialyearString);

 // for by default date as a month this is connected to bellow date wise filtering functions
 const today = new Date();
 const formatDate = (date) => {
   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
     2,
     "0"
   )}-${String(date.getDate()).padStart(2, "0")}`;
 };
 const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
 const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
 const formattedStartOfMonth = formatDate(monthStart);
 const formattedEndOfMonth = formatDate(monthEnd);

  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  // const [selectedRange, setSelectedRange] = useState("This Month");
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [account, setAccount] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [actType, setActType] = useState([]);
  const [parentTypeDropDown, setParentTytpeDropDown] = useState("");
  const [accoutTypeDropDown, setAccoutTypeDropDown] = useState("");
  const columnData = [
    { name: "parentaccount", label: "Parent Account" },
    { name: "accounttype", label: "Account Type", default: true },
    { name: "accountname", label: "Account" },
    { name: "total_debit", label: "Debit" },
    { name: "total_credit", label: "Credit" },
    { name: "Action", label: "Action", default: true },
  ];

  const handleSelectedCategory = (e) => {
    // setSearchValue(e.target.value);
  };

  // for parentr type filter fir clear value
  const handleParentTypeChange = (e) => {
    // setAccoutTypeDropDown("");
    // setSearchValue(e.target.value);
    setParentTytpeDropDown(e.target.value);
  };
  // accoutn type dropdown for clear value
  const handleAccoutTypeChange = (e) => {
    // setParentTytpeDropDown("");
    // setSearchValue(e.target.value);
    setAccoutTypeDropDown(e.target.value);
  };

  const [selectedColumns, setSelectedColumns] = useState(
    columnData.map((name) => ({ name, selected: true }))
  );
  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.name === columnName
          ? { ...column, selected: !column.selected }
          : column
      )
    );
  };
  // useSatate
  const loadData = useSelector((state) => state.loadReducer);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [accTypeDropDown, setAccTypeDropDown] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // to get api for outer profit and loss
    setLoading(true);
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/report/pandlouter`, {

        companyid: companyID,
        org,
        financialyear,
        fromDate,
        toDate,
      })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
        setLoading(false);
        filterData1();
      })
      .catch((err) => console.log(err));

    // axios
    //   .post(`http://localhost:8081/report/trialouter`, {
    //     siteid: siteId,
    //     org,
    //     financialyear,
    //     fromDate,
    //     toDate,
    //   })
    //   .then((res) => {
    
    //     setData(res.data.message ? [] : res.data);
    //   })
    //   .catch((err) => console.log(err));

    // for dropdown Account type
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/report/actypedrop`, {

        userid: userID,
        companyid: companyID,
        financialyear,
        org,
      })
      .then((res) => {
       
        setAccTypeDropDown(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, [fromDate, toDate, loadData]);
  

  const handleApply = (e) => {
    e.preventDefault();
    // alert("apply button is working")
    // axios
    //   .post(`http://localhost:8081/report/trialouter`, {
    //     siteid: siteId,
    //     org,
    //     financialyear,
    //     fromDate,
    //     toDate
    //   })
    //   .then((res) => {

    //     setData(res.data);
    //     filterData1();
    //   })
    //   .catch((err) => console.log(err));

   
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleLeftArrow = () => {
    navigate("/reports");
  };
  const ledgerName = data.length > 0 ? data[0].ledger : "";

  // check box for visible start

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });

  // Remove the useEffect block related to localStorage

  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {}, [columnVisibility]);

  const handleSubmit = () => {
    handleClose();
  };

  // debu dropdown date range filter start

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };
  // const today = new Date();
  // const formatDate = (date) => {
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(date.getDate()).padStart(2, "0")}`;
  // };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;
   

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end

  // financila year should be in that same year to choose from date range start
  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 32);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);

    
  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };
  // financila year should be in that same year to choose from date range end

  // inithially show monthly data
  useEffect(() => {
    filterData1();
    handleThisMonth();
  }, []);

  const filterData1 = () => {
    const filtered = data.filter((item) => {
      // Check if any column contains the search value
      const containsSearchValue = Object.values(item).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue.toLowerCase().trim())
      );

      // Check if the selected category matches
      const matchesSelectedCategory =
        !selectedCategory || item.parentaccount === selectedCategory;

      // Check if the parent type matches
      const matchesParentType =
        !parentTypeDropDown || item.parentaccount === parentTypeDropDown;

      // Check if the account type matches
      const matchesAccountType =
        !accoutTypeDropDown || item.accounttype === accoutTypeDropDown;

      return (
        containsSearchValue &&
        matchesSelectedCategory &&
        matchesParentType &&
        matchesAccountType
      );
    });

    setFilteredData(filtered);
  };

  const parseIndianStyleDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    filterData1();
    //   const fltr = filterData();
    // setFilteredData(fltr);
  }, [
    fromDate,
    toDate,
    data,
    searchValue,
    parentTypeDropDown,
    accoutTypeDropDown,
  ]);

  // for date range end

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  
  // handle view another component

  const handleViewAction = (accountname) => {

    navigate(`/reports/profitAndLoss/accountTransactions`, {
      state: { accountname },
    });
  };

    // downnload as a pdf

    // const handleDownload = () => {
    //   if (pdfRef.current) {
    //     const element = pdfRef.current;
    //     const options = {
    //       margin: 10,
    //       filename: "P&L.pdf",
    //       image: { type: "jpeg", quality: 2.98 },
    //       html2canvas: { scale: 2 },
    //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //     };
  
    //     html2pdf(element, options);
    //   }
    // };


    const handleDownload = () => {
      const element = pdfRef.current;
      const options = {
          margin: 10,
          filename: "P&L.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const content = `
      <div style="font-family: Arial, sans-serif;">
      <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
        List of All <span style="color: #ffa000;">Profit & Loss</span>
      </p>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr style="background-color: #f0f0f0;">
          <th style="border: 1px solid black; padding: 6px;">Parent Account</th>
          <th style="border: 1px solid black; padding: 6px;">Account Type</th>
          <th style="border: 1px solid black; padding: 6px;">Account</th>
          <th style="border: 1px solid black; padding: 6px;">Account ID</th>
          <th style="border: 1px solid black; padding: 6px;">Balance</th>
          
          </tr>
        </thead>
        <tbody>
        ${filteredData&&filteredData
          .map(
            (item, index) => `
          <tr key=${index}>
            <td style="border: 1px solid #ddd; padding: 6px;">${
             item.parentaccount
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.accounttype
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.accountname
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.accountid
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
            item.total_balance
            }</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
      </table>
    </div>
    `;
      //html2pdf(element, options);
      html2pdf().from(content).set(options).save();
  };
  
  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={7}>
              <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={handleLeftArrow}
                />
              </p>
            </Col>
            <Col lg={3}>
              <div className="inputFieldIcon">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="basic-addon2"
                  className="mt-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="iconPosition">
                  <BiSearch />
                </span>
              </div>
            </Col>
            <Col md={1} className="d-flex gap-3">
              <span>
                {/* <ReactHTMLTableToExcel
                  id="exportButton"
                  className="commonBtn smallBtn cancelBtn mt-2"
                  table="table-to-export"
                  filename={`P&L-${formatDateToDDMMYYYY(
                    fromDate
                  )} - ${formatDateToDDMMYYYY(toDate)}`}
                  sheet="trial_report"
                  buttonText={
                    <>
                      <BiSolidFileExport /> Export
                    </>
                  }
                /> */}

<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

              </span>
              <span>
                <Button className="commonBtn smallBtn cancelBtn mt-2" onClick={handleDownload}>Save pdf</Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={3}>
          <Form.Label className="section-title form-section ptag">
            Profit and Loss
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col style={{ flex:" 0 0 auto", width: "27.33333333%"}}>
          <Form.Label className="form-section ptag text-align-center ">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Group className="d-flex gap-2" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
              // disabled={!isCurrentYear}
            >
              <option disabled={!isCurrentYear} value="Today">
                Today
              </option>
              <option disabled={!isCurrentYear} value="Yesterday">
                Yesterday
              </option>
              <option disabled={!isCurrentYear} value="This Week">
                This Week
              </option>
              <option disabled={!isCurrentYear} value="This Month">
                This Month
              </option>
              <option disabled={!isCurrentYear} value="This Quarter">
                This Quarter
              </option>
              <option disabled={!isCurrentYear} value="This Year">
                This Year
              </option>
              {/* <option value="Date Range">Date Range</option> */}
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={3}>
          <Form.Group className="d-flex gap-2 p-1" controlId="formMonth">
            <div className="d-flex gap-2 mt-2 p-2" style={{fontSize:"14px"}}>
              <span style={{ fontWeight: "bold" }}>Parent</span>{" "}
              <span style={{ fontWeight: "bold" }}>Account</span>
            </div>
            {/*<Form.Label className="mt-3"></Form.Label>*/}
            <Form.Select
              className="mt-2 p-2"
              value={parentTypeDropDown}
              onChange={handleParentTypeChange}
            >
              <option value="">All</option>
              {/* <option value="Assets">Assets</option>
              <option value="Equity and Liability">Equity and Liability</option> */}
              <option value="Income">Income</option>
              <option value="Expenses">Expenses</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="d-flex gap-2 p-1" controlId="formMonth">
            <div className="d-flex gap-2 mt-2 p-2" style={{fontSize:"14px"}}>
              <span style={{ fontWeight: "bold" }}>Account</span>{" "}
              <span style={{ fontWeight: "bold" }}>Type</span>
            </div>
            {/*<Form.Label className="mt-3"></Form.Label>*/}
            <Form.Select
              className="mt-2 p-2"
              value={accoutTypeDropDown}
              onChange={handleAccoutTypeChange}
            >
              <option value="">All</option>
              {accTypeDropDown.length > 0
                ? accTypeDropDown.map((item, index) => (
                    <option key={index} value={item.accounttype}>
                      {item.accounttype}
                    </option>
                  ))
                : []}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="table-main-container" ref={pdfRef}>
          <Table id="table-to-export" responsive>
            <thead className="tableContainer">
              <tr>
                <th>Parent Account</th>
                <th>Account Type</th>
                <th>Account</th>
                <th>Account ID</th>
                <th>Balance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loadData || loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.parentaccount}</td>
                    <td>{item.accounttype}</td>
                    <td>{item.accountname}</td>
                    <td>{item.accountid}</td>
                    <td>{item.total_balance}</td>
                    <td>
                      {/* Eye icon for viewing */}
                      <span onClick={() => handleViewAction(item.accountname)}>
                        <AiFillEye className="editIcon" />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ListTable;
