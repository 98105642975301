import axios from "axios";
import { notifyError } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { FETCH_SALESTARGET_DASHBOARD_DATA } from "../actionTypes/salesTarget.actiontypes";


export const salesTargetDashboardData = (backendData, setLoading) => async (dispatch) => {
    
    
    try {

      const res = await axios.post("https://erp.venturesathi.co.in/api/v1/salebill/saledashboard", backendData);

    
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_SALESTARGET_DASHBOARD_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching sales target dashboard data ${error}`);
      notifyError(error.message);
    }

  };