import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";

const DispatchDate = ({ id, gino, invoiceno, vehicleno, vehicletype }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    dispatchdate: "",
    vehicletype: "",
    vehicleno: "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setData({
      dispatchdate: "",
      vehicletype: "",
      vehicleno: "",
    });
    dispatch(reloadPage(true));
  };

  const handleSave = async () => {
    if (data.dispatchdate <= 0) {
      return notifyError("You have empty field");
    }
    const res = await axios.post(
      "https://erp.venturesathi.co.in/api/v1/issuegood/dispatchgood",
      {
        id: id,
        gino: gino,
        psorder: invoiceno,
        ...data,
      }
    );
    if (res.status == 200) {
      notifySuccess("Disptach Date updated");
      handleClose();
    } else {
      notifyError(res.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };

  return (
    <>
      <MdEdit className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="md"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>
              Dispatch <span className="form-title main"> Date</span>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group controlId="formBasicId">
              <Form.Label>GI No.</Form.Label>
              <Form.Control type="text" name="gino" value={gino} disabled />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="formBasicId">
              <Form.Label>Invoice No.</Form.Label>
              <Form.Control
                type="text"
                name="invoiceno"
                value={invoiceno}
                disabled
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="formBasicId">
              <Form.Label>
                Dispatch Date <span className="star">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="dispatchdate"
                value={data.dispatchdate}
                onChange={handleChange}
                isInvalid={!data.dispatchdate && showEmptyError}
              />
              <Form.Control.Feedback type="invalid">
                Enter Dispatch Date
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicId">
                <Form.Label>Vehicle Type</Form.Label>
                <Form.Control
                  disabled={vehicletype}
                  type="text"
                  name="vehicletype"
                  value={vehicletype ? vehicletype : data.vehicletype}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formBasicId">
                <Form.Label>Vehicle No</Form.Label>
                <Form.Control
                  disabled={vehicleno}
                  type="text"
                  name="vehicleno"
                  value={vehicleno ? vehicleno : data.vehicleno}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DispatchDate;
