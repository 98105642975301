import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import { MdModeEditOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { purchaseBillsData } from "../../../redux/actions/purchaseBills.actions";
import { getStatusColorClass } from "../../../constants/colorClass";
import axios from "axios";

const ListTablePurchaseBills = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const data = useSelector(
    (state) => state.purchaseBillsData.purchaseBillsData
  );
  const loadData = useSelector((state) => state.loadReducer);

  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const branchid = userData[0].branchid;
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: searchKeyword || "₹ΘθϖΠ",
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(purchaseBillsData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  //search logic

  const handleShow = (id) => {
    setShow(true);
    setSelectedItemId(id);
  };

  const handleEdit = (id) => {
    navigate(`/purchase/purchaseBills/editPurchaseBills/${id}`);
  };
  const handleView = (id) => {
    navigate(`/purchase/purchaseBills/viewPurchaseBills/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

        userid,
        submodule: "Purchase Bills",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end
  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Purchase Bills</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() =>
                  navigate("/purchase/purchaseBills/addPurchaseBills")
                }
              >
                Add Bill
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Purchase Bill No</th>
              <th>GRN No.</th>
              <th>Invoice No.</th>
              <th>Purchase Date</th>
              <th>Vendor Name</th>
              <th>Due Date</th>
              <th>Amount</th>
              <th>Due Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  status,
                  vendor,
                  paymentduedate,
                  invoiceno,
                  grn,
                  purchasebillno,
                  purchasedate,
                  finalamount,
                  dueamount,
                } = item;
                const colorClass = getStatusColorClass(status);
                return (
                  <tr key={index}>
                    <td>{!purchasebillno ? "--" : purchasebillno}</td>
                    <td>{!grn ? "--" : grn}</td>
                    <td>{!invoiceno ? "--" : invoiceno}</td>
                    <td>{!purchasedate ? "--" : purchasedate}</td>
                    <td>{!vendor ? "--" : vendor}</td>
                    <td>{!paymentduedate ? "--" : paymentduedate}</td>
                    <td>{!finalamount ? "--" : finalamount}</td>
                    <td>{!dueamount ? "--" : dueamount}</td>
                    <td className={`${colorClass}`}>
                      {!status ? "--" : status}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <MdOutlineRemoveRedEye
                          className="editIcon"
                          onClick={() => handleView(item.id)}
                        />

                        {status == "Draft" ? (
                          <div>
                            {crud && crud.includes("u") && (
                              <MdModeEditOutline
                                className="editIcon mr-2"
                                onClick={() => handleEdit(item.id)}
                              />
                            )}
                          </div>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTablePurchaseBills;
